import { Accordion, AccordionProps } from '@mantine/core';

// eslint-disable-next-line @typescript-eslint/no-empty-interface,@typescript-eslint/ban-ts-comment
// @ts-ignore
export interface FbAccordionProps extends AccordionProps {
  multiple?: boolean;
  value?: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (props: any) => void;
}

export const FBAccordion: typeof Accordion = Accordion;

export function FbAccordion(props: FbAccordionProps) {
  return <Accordion {...props} />;
}
