import { DisclosureUiConfig, Framework } from '@fb-ui/shared-types';

export const ESRSS19UI: DisclosureUiConfig = {
  meta: {
    templateId: 'S1-9',
    framework: Framework.ESRS,
    version: '0.0.1',
  },
  data: {
    'esrs:DiversityIndicatorsTable##esrs:NumberOfEmployeesHeadCountAtTopManagementLevel$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId: 'esrs:DiversityIndicatorsTable',
              measureId: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel',
            },
          ],
        },
      },
    'esrs:DiversityIndicatorsTable##esrs:NumberOfEmployeesHeadCountAtTopManagementLevel':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },

    'esrs:DiversityIndicatorsTable##esrs:PercentageOfEmployeesAtTopManagementLevel':
      {
        formula: {
          operation: 'PERCENT',
          options: {
            multipleSelectors: {
              hasMultipleSelectors: true,
            },
          },
          operands: [
            {
              tableId: 'esrs:DiversityIndicatorsTable',
              measureId: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel',
              dimensionIds: [
                {
                  dimensionId: 'esrs:GenderMember',
                  domainMemberIds: [],
                },
              ],
            },
            {
              cellId: {
                tableId: 'esrs:DiversityIndicatorsTable',
                measureId:
                  'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel',
                dimensionIds: [
                  {
                    dimensionId: 'esrs:GenderMember',
                    domainMemberIds: ['aggragate:TotalTableEmployees'],
                  },
                ],
              },
            },
          ],
        },
      },
    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },
    'esrs:NumberOfEmployeesHeadCountUnder30YearsOld': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },
    'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },
    'esrs:NumberOfEmployeesHeadCountOver50YearsOld': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },
    'esrs:PercentageOfEmployeesBetween30And50YearsOld': {
      formula: {
        operation: 'PERCENT',
        operands: [
          {
            measureId: 'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld',
          },
          {
            operation: 'SUM',
            operands: [
              { measureId: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOld' },
              {
                measureId:
                  'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld',
              },
              { measureId: 'esrs:NumberOfEmployeesHeadCountOver50YearsOld' },
            ],
          },
        ],
      },
    },
    'esrs:PercentageOfEmployeesUnder30YearsOld': {
      formula: {
        operation: 'PERCENT',
        operands: [
          { measureId: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOld' },
          {
            operation: 'SUM',
            operands: [
              { measureId: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOld' },
              {
                measureId:
                  'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld',
              },
              { measureId: 'esrs:NumberOfEmployeesHeadCountOver50YearsOld' },
            ],
          },
        ],
      },
    },
    'esrs:PercentageOfEmployeesAtTopManagementLevel': {
      formula: {
        operation: 'PERCENT',
        operands: [
          { measureId: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel' },
          {
            operation: 'SUM',
            operands: [
              { measureId: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOld' },
              {
                measureId:
                  'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld',
              },
              { measureId: 'esrs:NumberOfEmployeesHeadCountOver50YearsOld' },
            ],
          },
        ],
      },
    },
    'esrs:PercentageOfEmployeesOver50YearsOld': {
      formula: {
        operation: 'PERCENT',
        operands: [
          { measureId: 'esrs:NumberOfEmployeesHeadCountOver50YearsOld' },
          {
            operation: 'SUM',
            operands: [
              { measureId: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOld' },
              {
                measureId:
                  'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOld',
              },
              { measureId: 'esrs:NumberOfEmployeesHeadCountOver50YearsOld' },
            ],
          },
        ],
      },
    },
  },
};
