import { FbTabsPanel } from '@fb/ui-kit';
import { BsDot } from 'react-icons/bs';

import Button from '@esg/shared/components/Button/Button';

import { disclosureData } from './DisclosureTab.mock';

const DisclosureTab = () => {
  return (
    <FbTabsPanel
      value="disclosures"
      pr="sm"
      className="pr-0 notification-tab-content"
      data-cy={`tab-content-1`}
    >
      {disclosureData.map((elem, index) => {
        return (
          <div
            className={` notification-card flex items-start pt-6 hover:bg-background-100 rounded-lg pr-6
            ${elem.status == 'read' ? 'pl-10' : ''}`}
            key={index}
          >
            {elem.status == 'unread' && (
              <BsDot className="w-12 h-12 flex-none text-accent" />
            )}
            {elem.icon && (
              <span className="notification-icon w-12 h-12 flex-none rounded-full font-semibold tracking-wider bg-supportive2-light flex items-center justify-center">
                {elem.icon}
              </span>
            )}
            <div className="notification-card-body grow pl-4 border-b border-gray-100 pb-6">
              {elem.notificationInfo && (
                <p className="text-linkRegular">{elem.notificationInfo}</p>
              )}
              {elem.date && (
                <span className="time text-gray-500 text-dateIndication mt-3 block">
                  {elem.date}
                </span>
              )}
              {elem.message && (
                <span className="bg-background-200 block p-4 rounded-lg mt-4">
                  {elem.message}
                </span>
              )}
              {(elem.buttonAccept || elem.buttonReject) && (
                <div className="notification-button mt-5">
                  {elem.buttonAccept && (
                    <Button variant="primary">{elem.buttonAccept}</Button>
                  )}
                  {elem.buttonReject && (
                    <Button variant="secondary" className="ml-2">
                      {elem.buttonReject}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </FbTabsPanel>
  );
};

export default DisclosureTab;
