import { IAudit } from '@fb-ui/shared-types';
import { gql } from 'graphql-tag';

export interface IGetAuditLogsDto {
  getAuditLogs: IAudit[];
}

export const GET_AUDIT_LOGS_QUERY = gql`
  query GetAuditLogs(
    $companyId: String
    $resourceId: String
    $resourceName: String
    $size: Float!
    $startIndex: Float!
    $userId: String
  ) {
    getAuditLogs(
      resourceName: $resourceName
      size: $size
      startIndex: $startIndex
      resourceId: $resourceId
      companyId: $companyId
      userId: $userId
    ) {
      id
      action
      message
      date
      userId
      metadata {
        key
        value
      }
    }
  }
`;
