import { DisclosureUiConfig, Framework } from '@fb-ui/shared-types';

export const ESRSS16UI: DisclosureUiConfig = {
  meta: {
    templateId: 'S1-6',
    framework: Framework.ESRS,
    version: '0.0.1',
  },
  data: {
    'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesByGenderTable##esrs:NumberOfEmployeesHeadcount$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId:
                'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesByGenderTable',
              measureId: 'esrs:NumberOfEmployeesHeadcount',
            },
          ],
        },
      },

    'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesByGenderTable##esrs:AverageNumberOfEmployeesHeadCount$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId:
                'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesByGenderTable',
              measureId: 'esrs:AverageNumberOfEmployeesHeadCount',
            },
          ],
        },
      },

    'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesInCountriesWith50OrMoreEmployeesTable##esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployees$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId:
                'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesInCountriesWith50OrMoreEmployeesTable',
              measureId:
                'esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployees',
            },
          ],
        },
      },

    'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesInCountriesWith50OrMoreEmployeesTable##esrs:AverageNumberOfEmployeesInCountriesWith50OrMoreEmployees$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId:
                'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesInCountriesWith50OrMoreEmployeesTable',
              measureId:
                'esrs:AverageNumberOfEmployeesInCountriesWith50OrMoreEmployees',
            },
          ],
        },
      },

    'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndGenderTable##esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalent$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId:
                'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndGenderTable',
              measureId: 'esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalent',
            },
          ],
        },
      },

    'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndGenderTable##esrs:AverageNumberOfEmployeesHeadCountOrFulltimeEquivalent$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId:
                'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndGenderTable',
              measureId:
                'esrs:AverageNumberOfEmployeesHeadCountOrFulltimeEquivalent',
            },
          ],
        },
      },

    'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndRegionTable##esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalent$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId:
                'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndRegionTable',
              measureId: 'esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalent',
            },
          ],
        },
      },

    'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndRegionTable##esrs:AverageNumberOfEmployeesHeadCountOrFulltimeEquivalent$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId:
                'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndRegionTable',
              measureId:
                'esrs:AverageNumberOfEmployeesHeadCountOrFulltimeEquivalent',
            },
          ],
        },
      },

    'esrs:NumberOfEmployeesHeadcount': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },

    'esrs:AverageNumberOfEmployeesHeadcount': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },

    'esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployees': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },

    'esrs:AverageNumberOfEmployeesInCountriesWith50OrMoreEmployees': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },

    'esrs:NumberOfEmployeesHeadcountOrFulltimeEquivalent': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },

    'esrs:AverageNumberOfEmployeesHeadcountOrFulltimeEquivalent': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },

    'esrs:NumberOfEmployeesTurnover': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },
    'esrs:PercentageOfOwnEmployeeTurnover': {
      formula: {
        operation: 'PERCENT',
        operands: [
          {
            measureId: 'esrs:NumberOfOwnEmployeeTurnover',
          },
          {
            measureId: 'esrs:NumberOfEmployeesHeadcount',
          },
        ],
      },
    },
  },
};
