import { IMaterialityTopicBase } from '@fb-ui/shared-types';

export const MATERIALITY_TOPICS: IMaterialityTopicBase[] = [
  {
    id: 'env',
    children: [
      {
        id: 'env.climate-change',
        children: [
          { id: 'env.climate-change.adaptation' },
          { id: 'env.climate-change.mitigation' },
          { id: 'env.climate-change.energy' },
        ],
      },
      {
        id: 'env.pollution',
        children: [
          { id: 'env.pollution.air' },
          { id: 'env.pollution.water' },
          { id: 'env.pollution.soil' },
          { id: 'env.pollution.living-organisms-food-resources' },
          { id: 'env.pollution.substances-concern' },
          { id: 'env.pollution.substances-high-concern' },
        ],
      },
      {
        id: 'env.water-marine-resources',
        children: [
          { id: 'env.water-marine-resources.water-withdrawals' },
          { id: 'env.water-marine-resources.water-consumption' },
          { id: 'env.water-marine-resources.water-use' },
          { id: 'env.water-marine-resources.water-discharges' },
          { id: 'env.water-marine-resources.habitat-degradation' },
        ],
      },
      {
        id: 'env.biodiversity-ecosystems',
        children: [
          {
            id: 'env.biodiversity-ecosystems.direct-impact',
            children: [
              {
                id: 'env.biodiversity-ecosystems.direct-impact.climate-change',
              },
              {
                id: 'env.biodiversity-ecosystems.direct-impact.land-use-change',
              },
              {
                id: 'env.biodiversity-ecosystems.direct-impact.direct-exploitation',
              },
              {
                id: 'env.biodiversity-ecosystems.direct-impact.invasive-species',
              },
              { id: 'env.biodiversity-ecosystems.direct-impact.pollution' },
              { id: 'env.biodiversity-ecosystems.direct-impact.others' },
            ],
          },
          {
            id: 'env.biodiversity-ecosystems.state-species',
            children: [
              {
                id: 'env.biodiversity-ecosystems.state-species.population-size',
              },
              {
                id: 'env.biodiversity-ecosystems.state-species.extinction-risk',
              },
            ],
          },
          {
            id: 'env.biodiversity-ecosystems.extent-condition-ecosystems',
            children: [
              {
                id: 'env.biodiversity-ecosystems.extent-condition-ecosystems.land-degradation',
              },
              {
                id: 'env.biodiversity-ecosystems.extent-condition-ecosystems.desertification',
              },
              {
                id: 'env.biodiversity-ecosystems.extent-condition-ecosystems.soil-sealing',
              },
            ],
          },
          { id: 'env.biodiversity-ecosystems.ecosystem-services' },
        ],
      },
      {
        id: 'env.circular-economy',
        children: [
          { id: 'env.circular-economy.resources-inflows' },
          { id: 'env.circular-economy.resource-outflows' },
          { id: 'env.circular-economy.waste' },
        ],
      },
    ],
  },
  {
    id: 'social',
    children: [
      {
        id: 'social.own-workforce',
        children: [
          {
            id: 'social.own-workforce.working-conditions',
            children: [
              {
                id: 'social.own-workforce.working-conditions.secure-employment',
              },
              { id: 'social.own-workforce.working-conditions.working-time' },
              { id: 'social.own-workforce.working-conditions.adequate-wages' },
              { id: 'social.own-workforce.working-conditions.social-dialogue' },
              {
                id: 'social.own-workforce.working-conditions.freedom-association',
              },
              {
                id: 'social.own-workforce.working-conditions.collective-bargaining',
              },
              {
                id: 'social.own-workforce.working-conditions.work-life-balance',
              },
              { id: 'social.own-workforce.working-conditions.health-safety' },
            ],
          },
          {
            id: 'social.own-workforce.equal-treatment',
            children: [
              { id: 'social.own-workforce.equal-treatment.gender-equality' },
              { id: 'social.own-workforce.equal-treatment.training-skills' },
              {
                id: 'social.own-workforce.equal-treatment.disabilities-inclusion',
              },
              {
                id: 'social.own-workforce.equal-treatment.violence-harassment',
              },
              { id: 'social.own-workforce.equal-treatment.diversity' },
            ],
          },
          {
            id: 'social.own-workforce.other-rights',
            children: [
              { id: 'social.own-workforce.other-rights.child-labour' },
              { id: 'social.own-workforce.other-rights.forced-labour' },
              { id: 'social.own-workforce.other-rights.adequate-housing' },
              { id: 'social.own-workforce.other-rights.privacy' },
            ],
          },
        ],
      },
      {
        id: 'social.workers-value-chain',
        children: [
          {
            id: 'social.workers-value-chain.working-conditions',
            children: [
              {
                id: 'social.workers-value-chain.working-conditions.secure-employment',
              },
              {
                id: 'social.workers-value-chain.working-conditions.working-time',
              },
              {
                id: 'social.workers-value-chain.working-conditions.adequate-wages',
              },
              {
                id: 'social.workers-value-chain.working-conditions.social-dialogue',
              },
              {
                id: 'social.workers-value-chain.working-conditions.freedom-association',
              },
              {
                id: 'social.workers-value-chain.working-conditions.collective-bargaining',
              },
              {
                id: 'social.workers-value-chain.working-conditions.work-life-balance',
              },
              {
                id: 'social.workers-value-chain.working-conditions.health-safety',
              },
            ],
          },
          {
            id: 'social.workers-value-chain.equal-treatment',
            children: [
              {
                id: 'social.workers-value-chain.equal-treatment.gender-equality',
              },
              {
                id: 'social.workers-value-chain.equal-treatment.training-skills',
              },
              {
                id: 'social.workers-value-chain.equal-treatment.disabilities-inclusion',
              },
              {
                id: 'social.workers-value-chain.equal-treatment.violence-harassment',
              },
              { id: 'social.workers-value-chain.equal-treatment.diversity' },
            ],
          },
          {
            id: 'social.workers-value-chain.other-rights',
            children: [
              { id: 'social.workers-value-chain.other-rights.child-labour' },
              { id: 'social.workers-value-chain.other-rights.forced-labour' },
              {
                id: 'social.workers-value-chain.other-rights.adequate-housing',
              },
              {
                id: 'social.workers-value-chain.other-rights.water-sanitation',
              },
              { id: 'social.workers-value-chain.other-rights.privacy' },
            ],
          },
        ],
      },
      {
        id: 'social.affected-communities',
        children: [
          {
            id: 'social.affected-communities.economic-social-rights',
            children: [
              {
                id: 'social.affected-communities.economic-social-rights.adequate-housing',
              },
              {
                id: 'social.affected-communities.economic-social-rights.adequate-food',
              },
              {
                id: 'social.affected-communities.economic-social-rights.water-sanitation',
              },
              {
                id: 'social.affected-communities.economic-social-rights.land-impacts',
              },
              {
                id: 'social.affected-communities.economic-social-rights.security-impacts',
              },
            ],
          },
          {
            id: 'social.affected-communities.civil-political-rights',
            children: [
              {
                id: 'social.affected-communities.civil-political-rights.freedom-expression',
              },
              {
                id: 'social.affected-communities.civil-political-rights.freedom-assembly',
              },
              {
                id: 'social.affected-communities.civil-political-rights.human-rights-defenders',
              },
            ],
          },
          {
            id: 'social.affected-communities.indigenous-rights',
            children: [
              {
                id: 'social.affected-communities.indigenous-rights.informed-consent',
              },
              {
                id: 'social.affected-communities.indigenous-rights.self-determination',
              },
              {
                id: 'social.affected-communities.indigenous-rights.cultural-rights',
              },
            ],
          },
        ],
      },
      {
        id: 'social.consumers-end-users',
        children: [
          {
            id: 'social.consumers-end-users.information-impacts',
            children: [
              { id: 'social.consumers-end-users.information-impacts.privacy' },
              {
                id: 'social.consumers-end-users.information-impacts.freedom-expression',
              },
              {
                id: 'social.consumers-end-users.information-impacts.access-info',
              },
            ],
          },
          {
            id: 'social.consumers-end-users.personal-safety',
            children: [
              {
                id: 'social.consumers-end-users.personal-safety.health-safety',
              },
              {
                id: 'social.consumers-end-users.personal-safety.security-person',
              },
              {
                id: 'social.consumers-end-users.personal-safety.protection-children',
              },
            ],
          },
          {
            id: 'social.consumers-end-users.social-inclusion',
            children: [
              {
                id: 'social.consumers-end-users.social-inclusion.non-discrimination',
              },
              {
                id: 'social.consumers-end-users.social-inclusion.access-products-services',
              },
              {
                id: 'social.consumers-end-users.social-inclusion.responsible-marketing',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'governance',
    children: [
      {
        id: 'governance.business-conduct',
        children: [
          { id: 'governance.business-conduct.corporate-culture' },
          { id: 'governance.business-conduct.whistle-blowers' },
          { id: 'governance.business-conduct.animal-welfare' },
          { id: 'governance.business-conduct.political-engagement' },
          { id: 'governance.business-conduct.suppliers-relations' },
          {
            id: 'governance.business-conduct.corruption-bribery',
            children: [
              {
                id: 'governance.business-conduct.corruption-bribery.prevention-detection',
              },
              {
                id: 'governance.business-conduct.corruption-bribery.incidents',
              },
            ],
          },
        ],
      },
    ],
  },
];

export const GENERAL_MATERIALITY_TOPICS: IMaterialityTopicBase[] = [
  {
    id: 'general',
    children: [
      { id: 'general.business-process' },
      { id: 'general.governance' },
      { id: 'general.strategy-and-business-model' },
      { id: 'general.impact-risks-and-opportunities' },
      { id: 'general.disclosures' },
    ],
  },
];
