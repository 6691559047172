import { CollectionDataType } from '../NotificationsMenu.types';

export const collectionData: CollectionDataType[] = [
  {
    icon: 'AB',
    notificationInfo: 'Entity name added a proof file to GRI disclosure 302-1',
    date: '1 hour ago',
    fileName: 'First_uploaded_proof_V102.ext',
    fileSize: '1.2 MB',
    status: 'unread',
  },
  {
    icon: 'GM',
    notificationInfo: 'Fujitsu Belgium shared GRI disclosure 401-1 as a draft ',
    date: '08:38',
    message:
      'I’ve started working on a first draft, take a look and tell me what you think.',
    status: 'unread',
  },
  {
    icon: 'EF',
    notificationInfo:
      'Entity name is late with providing data for GRI disclosure 401-1 ',
    date: 'Yesterday 12:00',
    buttonAccept: 'Message',
    status: 'unread',
  },
];
