import { Aside, FbHocAppShell, MediaQuery, Navbar } from '@fb/ui-kit';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { createContext } from 'react';

import AppAside from '../AppAside/AppAside';
import AppHeader from '../AppHeader/AppHeader';
import AppNavBar from '../AppNavBar/AppNavBar';

export const asideContext = createContext<AsideContextType | null>(null);
type AsideContextType = {
  activity: boolean;
  // eslint-disable-next-line no-unused-vars
  setActivity: (activity: boolean) => void;
};

function AppShellLayout({ children }: { children: ReactNode }) {
  const [activity, setActivity] = useState<boolean>(false);
  const contextValue: AsideContextType = {
    activity,
    setActivity,
  };
  const [opened, setOpened] = useState(false);
  const router = useRouter();
  useEffect(() => {
    setOpened(false);
  }, [router.asPath]);

  return (
    <FbHocAppShell
      customPadding={`${activity ? `1rem` : ``}`}
      aside={
        <MediaQuery
          largerThan="xl"
          styles={{ width: 'calc(100% - 101.875rem)' }}
        >
          <Aside
            className="h-full top-0 bg-selected"
            hiddenBreakpoint="lg"
            hidden={!activity}
            width={
              activity
                ? {
                    base: `100%`,
                    lg: `calc(100% - 18.125rem)`,
                  }
                : { base: `0%`, lg: 290 }
            }
          >
            {<AppAside openFunc={setActivity} isOpen={activity} />}
          </Aside>
        </MediaQuery>
      }
      navBar={
        <Navbar
          width={{ base: 290 }}
          p="xs"
          hiddenBreakpoint="lg"
          hidden={!opened}
        >
          <AppNavBar />
        </Navbar>
      }
      appContent={
        <asideContext.Provider value={contextValue}>
          {children}
        </asideContext.Provider>
      }
      header={<AppHeader setOpened={setOpened} opened={opened} />}
      navHiddenBreakpoint="lg"
    />
  );
}

export default AppShellLayout;
