import { BiEnvelope } from 'react-icons/bi';

import { MessagesButtonProps } from './MessagesButton.types';

const MessagesButton = ({ onClick }: MessagesButtonProps) => (
  <button
    className="relative pr-1 pt-1"
    data-testid="messages-btn"
    data-cy="messages-btn"
    onClick={onClick}
  >
    <BiEnvelope size={24} />

    <span className="bg-error w-3 h-3 flex rounded-full absolute right-0.5 top-0.5" />
  </button>
);

export default MessagesButton;
