import { DisclosureDataType } from '../NotificationsMenu.types';

export const disclosureData: DisclosureDataType[] = [
  {
    icon: 'PB',
    notificationInfo:
      'Philippe Baguette has sent you a Disclosure Request for report 123.2 GRI Act ethically, lead in diversity and respect ..',
    date: 'Today at 08:14',
    buttonAccept: 'Accept',
    buttonReject: 'Reject',
    status: 'unread',
  },
  {
    icon: 'TP',
    notificationInfo:
      'Person Name has approved your submission for GRI disclosure  404-1',
    date: 'Thursday  April 20, 2022 at 11:04',
    message:
      'Thanks for doing a great job so quickly! I will include this in the Better Company report.',
    status: 'read',
  },
  {
    icon: 'AB',
    notificationInfo:
      'Person Name has sent a Disclosure Request for Report GRI 123.1 Be climate neutral and drive clean and..',
    date: 'Wednesday April 19, 2022 at 11:24 ',
    status: 'read',
  },
];
