import { FbMenu, FBMenu, FbTabs } from '@fb/ui-kit';
import { useState } from 'react';
import { TbBell } from 'react-icons/tb';

import { useTranslation } from '@esg/i18n';

import CollectionTab from './CollectionTab/CollectionTab';
import DisclosureTab from './DisclosureTab/DisclosureTab';
import ReportTab from './ReportTab/ReportTab';
import TabListMenu from './TabListMenu';

export function NotificationsMenu() {
  const { t } = useTranslation('notification-menu');
  const [tabVal, setTabValue] = useState<string>('reports');

  return (
    <div className="relative">
      <FbMenu position={'bottom-end'} shadow={'md'} width={520}>
        <FBMenu.Target>
          <button
            className="relative pr-1 pt-1"
            data-testid="notification-menu-btn"
            data-cy="notification-menu-btn"
          >
            <TbBell size={24} />

            <span className="bg-error w-3 h-3 flex rounded-full absolute right-0.5 top-0.5" />
          </button>
        </FBMenu.Target>

        {/* Dropdown */}
        <FBMenu.Dropdown
          sx={{ maxWidth: 500 }}
          className="p-0 mt-2 border-gray-300"
          data-testid="notification-menu-dropdown"
        >
          <div className="w-full">
            <h3 className="font-bold text-black text-h5 px-8 pt-8 pb-2">
              {t('menu.notification.title')}
            </h3>

            {/* Tabs */}
            <FbTabs
              sx={{ maxWidth: 500 }}
              mx="auto"
              defaultValue="reports"
              variant="default"
              onTabChange={(e: string) => setTabValue(e)}
            >
              {/* tablist */}
              <TabListMenu selectedValue={tabVal} />

              {/* panels */}
              <div className="pannelWrapper px-4 py-4 overflow-auto h-[calc(100vh-210px)]">
                <ReportTab />
                <DisclosureTab />
                <CollectionTab />
              </div>
            </FbTabs>
          </div>
        </FBMenu.Dropdown>
      </FbMenu>
    </div>
  );
}

export default NotificationsMenu;
