import { create, StateCreator } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { appAsideSlice, AppAsideState } from './slices/appAsideState';
import { chatPageSlice, ChatPageState } from './slices/chatPageState';
import {
  dashboardPageSlice,
  DashboardPageState,
} from './slices/dashboardPageState';
import {
  materialityCreationSlice,
  MaterialityCreationState,
} from './slices/materialityCreationState';
import {
  memberInviteSlice,
  MemberInviteState,
} from './slices/memberInviteState';
import {
  tutorialCardSlice,
  tutorialCardState,
} from './slices/tutorialCardState';
import { userSessionSlice, UserSessionState } from './slices/userSession';

export type AppState = DashboardPageState &
  ChatPageState &
  UserSessionState &
  AppAsideState &
  tutorialCardState &
  MemberInviteState &
  MaterialityCreationState;

const myMiddlewares = (f: StateCreator<AppState, [], [], AppState>) =>
  devtools(f, { enabled: process.env.NODE_ENV !== 'test' });

export const useAppStore = create<AppState>()(
  persist(
    myMiddlewares((...a) => ({
      ...userSessionSlice(...a),
      ...dashboardPageSlice(...a),
      ...chatPageSlice(...a),
      ...appAsideSlice(...a),
      ...tutorialCardSlice(...a),
      ...memberInviteSlice(...a),
      ...materialityCreationSlice(...a),
    })),
    {
      name: 'esg-fujitsu-platform',
      partialize: (state) => ({ tutorialCard: state.tutorialCard }),
    },
  ),
);
