import { SVGProps } from 'react';

function ChainValueIcon({
  width = 30,
  height = 30,
  viewBox = '0 0 30 30',
  fill = '#595470',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" {...props}>
      <path
        d="M21.5304 7.00568H8.84287C7.91245 7.00568 7.1512 7.79318 7.1512 8.75568V21.0057C7.1512 21.9682 7.91245 22.7557 8.84287 22.7557H21.5304C22.4608 22.7557 23.222 21.9682 23.222 21.0057V8.75568C23.222 7.79318 22.4608 7.00568 21.5304 7.00568ZM21.5304 8.75568V11.3807H8.84287V8.75568H21.5304ZM17.3012 21.0057H13.072V13.1307H17.3012V21.0057ZM8.84287 13.1307H11.3804V21.0057H8.84287V13.1307ZM18.9929 21.0057V13.1307H21.5304V21.0057H18.9929Z"
        fill={fill}
      />
    </svg>
  );
}

export default ChainValueIcon;
