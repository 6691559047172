import { ReactNode, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import GlobalErrorFallback from '../GlobalErrorFallback/GlobalErrorFallback';

function ErrorBoundryHandler({ children }: { children: ReactNode }) {
  const onReset = useCallback(() => {
    // do something before reseting
  }, []);

  const onError = (error: any) => {
    // log the errors
    console.error(error);
  };

  return (
    <ErrorBoundary
      FallbackComponent={GlobalErrorFallback}
      onError={onError}
      onReset={onReset}
    >
      {children}
    </ErrorBoundary>
  );
}

export default ErrorBoundryHandler;
