import { Burger, Header } from '@fb/ui-kit';

interface AppHeaderProps {
  opened: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpened: (value: (prevValue: boolean) => boolean) => void;
}

export default function AppHeader({ opened, setOpened }: AppHeaderProps) {
  return (
    <Header height={{ base: 50, lg: 0 }}>
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <div className="xl:hidden flex items-center h-full">
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size="sm"
            color={'black'}
            mr="xl"
            ml="md"
          />
          {/* Header content */}
        </div>
      </div>
    </Header>
  );
}
