import { Framework, MasterDisclosureTemplate } from '@fb-ui/shared-types';

export const ESRSS11: MasterDisclosureTemplate = {
  meta: {
    id: 'S1-1',
    docType: 'tax-disclosure',
    fileName: 'esrs_401010_S1-1_2022-11-22.tax-disclosure.json',
    framework: Framework.ESRS,
    taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
    taxonomyURI:
      'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
    taxonomyPublicationDate: '2022-11-22',
    disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401010',
    disclosureName: '[401010] S1-1 Policies related to own workforce',
    disclosureSortId: 401010,
    namespaces: [
      {
        prefix: 'esrs',
        namespaceURI: 'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22',
      },
    ],
    dictionaries: [],
    labels: [
      {
        id: 'S1-1_labels',
        docType: 'tax-disclosure-labels',
        fileName:
          'esrs_401010_S1-1_labels_2022-11-22.tax-disclosure-labels.json',
        framework: Framework.ESRS,
        taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
        taxonomyURI:
          'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
        disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401010',
        taxonomyPublicationDate: '2022-11-22',
        libVersion: '0.1.0',
      },
    ],
    libVersion: '0.1.0',
    creationTimestamp: '2023-11-13T12:57:45+00:00',
  },
  children: [
    {
      id: 'esrs:PoliciesRelatedToOwnWorkforceGeneralAbstract',
      label: 'Policies related to own workforce - general',
      elementType: 'measure',
      dataType: null,
      isAbstract: true,
      children: [
        {
          id: 'esrs:PoliciesRelatedToOwnWorkforceTable',
          label: 'Policies related to own workforce',
          elementType: 'table',
          dataType: null,
          dimensions: [
            {
              id: 'esrs:TopicalESRSAxis',
              label: 'Topical ESRS',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:AllTopicsTopicUnspecifiedNAMember',
                  label: 'All topics / Topic unspecified / NA',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:ESRSS1OwnWorkforceMember',
                      label: 'ESRS S1 Own workforce',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
          ],
          measures: [
            {
              id: 'esrs:DisclosureOfPoliciesRelatedToOwnWorkersValueChainWorkersAffectedCommunitiesConsumersAndEndUsersExplanatory',
              label:
                'Disclosure of policies related to own workers / value chain workers / affected communities / consumers and end-users',
              elementType: 'measure',
              dataType: 'textBlock',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
              children: [
                {
                  id: 'esrs:DescriptionOfPoliciesThatAddressManagementOfMaterialImpactsAsWellAsAssociatedMaterialRisksAndOpportunitiesAndSummaryDescriptionOfPoliciesContentExplanatory',
                  label:
                    'Description of policies that address management of material impacts as well as associated material risks and opportunities and summary description of policies content',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                  children: [
                    {
                      id: 'esrs:DisclosureOfExplanationsOfSignificantChangesToPoliciesAdoptedDuringReportingYearExplanatory',
                      label:
                        'Disclosure of explanations of significant changes to policies adopted during reporting year',
                      elementType: 'measure',
                      dataType: 'textBlock',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                    },
                    {
                      id: 'esrs:DescriptionOfRelevantHumanRightsPolicyCommitmentsExplanatory',
                      label:
                        'Description of relevant human rights policy commitments',
                      elementType: 'measure',
                      dataType: 'textBlock',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                      children: [
                        {
                          id: 'esrs:DisclosureOfGeneralApproachInRelationToRespectForHumanRightsExplanatory',
                          label:
                            'Disclosure of general approach in relation to respect for human rights',
                          elementType: 'measure',
                          dataType: 'textBlock',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                        {
                          id: 'esrs:DisclosureOfGeneralApproachInRelationToEngagementWithAffectedStakeholdersExplanatory',
                          label:
                            'Disclosure of general approach in relation to engagement with affected stakeholders',
                          elementType: 'measure',
                          dataType: 'textBlock',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                        {
                          id: 'esrs:DisclosureOfGeneralApproachInRelationToMeasuresToProvideAndOrEnableRemedyForHumanRightsImpactsExplanatory',
                          label:
                            'Disclosure of general approach in relation to measures to provide and (or) enable remedy for human rights impacts',
                          elementType: 'measure',
                          dataType: 'textBlock',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                      ],
                    },
                    {
                      id: 'esrs:PoliciesAreAlignedWithRelevantInternationallyRecognisedStandards',
                      label:
                        'Policies are aligned with relevant internationally recognised standards',
                      elementType: 'measure',
                      dataType: 'boolean',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                      children: [
                        {
                          id: 'esrs:DescriptionOfHowPoliciesAreAlignedWithRelevantInternationallyRecognisedStandardsRelevantExplanatory',
                          label:
                            'Description of how policies are aligned with relevant internationally recognised standards',
                          elementType: 'measure',
                          dataType: 'textBlock',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                      ],
                    },
                    {
                      id: 'esrs:PoliciesExplicitlyAddressTraffickingInHumanBeingsForcedOrCompulsoryLabourAndChildLabour',
                      label:
                        'Policies explicitly address trafficking in human beings, forced or compulsory labour and child labour',
                      elementType: 'measure',
                      dataType: 'boolean',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                      children: [
                        {
                          id: 'esrs:PoliciesExplicitlyAddressTraffickingInHumanBeings',
                          label:
                            'Policies explicitly address trafficking in human beings',
                          elementType: 'measure',
                          dataType: 'boolean',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                        {
                          id: 'esrs:PoliciesExplicitlyAddressForcedOrCompulsoryLabour',
                          label:
                            'Policies explicitly address forced or compulsory labour',
                          elementType: 'measure',
                          dataType: 'boolean',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                        {
                          id: 'esrs:PoliciesExplicitlyAddressChildLabour',
                          label: 'Policies explicitly address child labour',
                          elementType: 'measure',
                          dataType: 'boolean',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                      ],
                    },
                    {
                      id: 'esrs:WorkplaceAccidentPreventionPolicyIsInPlace',
                      label: 'Workplace accident prevention policy is in place',
                      elementType: 'measure',
                      dataType: 'boolean',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                    },
                    {
                      id: 'esrs:WorkplaceAccidentManagementSystemIsInPlace',
                      label: 'Workplace accident management system is in place',
                      elementType: 'measure',
                      dataType: 'boolean',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                    },
                    {
                      id: 'esrs:SpecificPoliciesAimedAtEliminationOfDiscriminationAreInPlace',
                      label:
                        'Specific policies aimed at elimination of discrimination are in place',
                      elementType: 'measure',
                      dataType: 'boolean',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                      children: [
                        {
                          id: 'esrs:PoliciesAimedAtEliminationOfHarassmentAreInPlace',
                          label:
                            'Policies aimed at elimination of harassment are in place',
                          elementType: 'measure',
                          dataType: 'boolean',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                        {
                          id: 'esrs:PoliciesAimedAtPromotingEqualOpportunitiesAreInPlace',
                          label:
                            'Policies aimed at promoting equal opportunities are in place',
                          elementType: 'measure',
                          dataType: 'boolean',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                        {
                          id: 'esrs:PoliciesAimedAtOtherWaysToAdvanceDiversityAndInclusionAreInPlace',
                          label:
                            'Policies aimed at other ways to advance diversity and inclusion are in place',
                          elementType: 'measure',
                          dataType: 'boolean',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                      ],
                    },
                    {
                      id: 'esrs:GroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicy',
                      label:
                        'Grounds for discrimination and diversity are specifically covered in policy',
                      elementType: 'measure',
                      dataType: 'enumerationSet',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                      children: [
                        {
                          id: 'esrs:AgeGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Age grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:RacialAndEthnicOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Racial and ethnic origin grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:SexGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Sex grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:PoliticalOpinionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Political opinion grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:ReligionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Religion grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:SocialOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Social origin grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:NationalExtractionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'National extraction grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:SexualOrientationGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Sexual orientation grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:DisabilityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Disability grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:GenderIdentityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Gender identity grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:ColourGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Colour grounds for discrimination and diversity are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:OtherFormsOfDiscriminationCoveredByEuRegulationAndNationalLawAreSpecificallyCoveredInPolicyMember',
                          label:
                            'Other forms of discrimination covered by EU regulation and national law are specifically covered in policy',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                      ],
                    },
                    {
                      id: 'esrs:SpecificPolicyCommitmentsRelatedToInclusionAndOrAffirmativeActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace',
                      label:
                        'Specific policy commitments related to inclusion and (or) affirmative action for people from groups at particular risk of vulnerability in own workforce are in place',
                      elementType: 'measure',
                      dataType: 'boolean',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                      children: [
                        {
                          id: 'esrs:DisclosureOfSpecificPolicyCommitmentsRelatedToInclusionAndOrAffirmativeActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory',
                          label:
                            'Disclosure of specific policy commitments related to inclusion and (or) affirmative action for people from groups at particular risk of vulnerability in own workforce',
                          elementType: 'measure',
                          dataType: 'textBlock',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                      ],
                    },
                    {
                      id: 'esrs:PoliciesAreImplementedThroughSpecificProceduresToEnsureDiscriminationIsPreventedMitigatedAndActedUponOnceDetectedAsWellAsToAdvanceDiversityAndInclusion',
                      label:
                        'Policies are implemented through specific procedures to ensure discrimination is prevented, mitigated and acted upon once detected, as well as to advance diversity and inclusion',
                      elementType: 'measure',
                      dataType: 'boolean',
                      isAbstract: false,
                      isNillable: true,
                      periodType: 'duration',
                      children: [
                        {
                          id: 'esrs:DisclosureOfHowPoliciesAreImplementedThroughSpecificProceduresToEnsureDiscriminationIsPreventedMitigatedAndActedUponOnceDetectedAsWellAsToAdvanceDiversityAndInclusionExplanatory',
                          label:
                            'Disclosure of how policies are implemented through specific procedures to ensure discrimination is prevented, mitigated and acted upon once detected, as well as to advance diversity and inclusion',
                          elementType: 'measure',
                          dataType: 'textBlock',
                          isAbstract: false,
                          isNillable: true,
                          periodType: 'duration',
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 'esrs:DisclosureOfHowUndertakingEngagesWithAtriskOrVulnerableGroupsExplanatory',
                  label:
                    'Disclosure of how undertaking engages with at-risk or vulnerable groups',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:DisclosureOfHowPotentialBarriersToWorkerEngagementAreTakenIntoAccountExplanatory',
                  label:
                    'Disclosure of how potential barriers to worker engagement are taken into account',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:DisclosureOfHowWorkersAreProvidedWithInformationThatIsUnderstandableAndAccessibleThroughAppropriateCommunicationChannelsExplanatory',
                  label:
                    'Disclosure of how workers are provided with information that is understandable and accessible through appropriate communication channels',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:DisclosureOfAnyConflictingInterestsThatHaveArisenAmongDifferentWorkersAndHowTheseConflictingInterestsHaveBeenResolvedExplanatory',
                  label:
                    'Disclosure of any conflicting interests that have arisen among different workers and how these conflicting interests have been resolved',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:DisclosureOfHowUndertakingSeeksToRespectHumanRightsOfAllStakeholdersEngagedExplanatory',
                  label:
                    'Disclosure of how undertaking seeks to respect human rights of all stakeholders engaged',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:DisclosureOfReasonsForNotHavingAdoptedPoliciesExplanatory',
                  label:
                    'Disclosure of reasons for not having adopted policies',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:DisclosureOfTimeframeForAdoptionOfPoliciesExplanatory',
                  label: 'Disclosure of timeframe for adoption of policies',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
              ],
            },
          ],
        },
        {
          id: 'esrs:DisclosureContentOnPoliciesRelatedToOwnWorkforceTable',
          label: 'Disclosure content on policies related to own workforce',
          elementType: 'table',
          dataType: null,
          dimensions: [
            {
              id: 'esrs:NameOfPolicyTypedAxis',
              label: 'Name of policy',
              elementType: 'dimensionTyped',
              dataType: 'string',
              isNillable: true,
            },
            {
              id: 'esrs:TopicalESRSAxis',
              label: 'Topical ESRS',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:AllTopicsTopicUnspecifiedNAMember',
                  label: 'All topics / Topic unspecified / NA',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:ESRSS1OwnWorkforceMember',
                      label: 'ESRS S1 Own workforce',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
          ],
          measures: [
            {
              id: 'esrs:SustainabilityMattersAddressedByPolicyForOwnWorkforce',
              label:
                'Sustainability matters addressed by policy for own workforce',
              elementType: 'measure',
              dataType: 'enumerationSet',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
              children: [
                {
                  id: 'esrs:WorkingConditionsForOwnWorkersMember',
                  label: 'Working conditions for own workers',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:SecureEmploymentForOwnWorkersMember',
                      label: 'Secure employment for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:WorkingTimeForOwnWorkersMember',
                      label: 'Working time for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:AdequateWagesForOwnWorkersMember',
                      label: 'Adequate wages for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:SocialDialogueForOwnWorkersMember',
                      label: 'Social dialogue for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:FreedomOfAssociationForOwnWorkersMember',
                      label: 'Freedom of association for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:CollectiveBargainingForOwnWorkersMember',
                      label: 'Collective bargaining for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:WorklifeBalanceForOwnWorkersMember',
                      label: 'Work-life balance for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:HealthAndSafetyForOwnWorkersMember',
                      label: 'Health and safety for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
                {
                  id: 'esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkersMember',
                  label:
                    'Equal treatment and opportunities for all for own workers',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForOwnWorkersMember',
                      label:
                        'Gender equality and equal pay for work of equal value for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:TrainingAndSkillsDevelopmentForOwnWorkersMember',
                      label: 'Training and skills development for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForOwnWorkersMember',
                      label:
                        'Employment and inclusion of persons with disabilities for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForOwnWorkersMember',
                      label:
                        'Measures against violence and harassment in workplace for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:DiversityForOwnWorkersMember',
                      label: 'Diversity for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
                {
                  id: 'esrs:OtherWorkrelatedRightsForOwnWorkersMember',
                  label: 'Other work-related rights for own workers',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:ChildLabourForOwnWorkersMember',
                      label: 'Child labour for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:ForcedLabourForOwnWorkersMember',
                      label: 'Forced labour for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:AdequateHousingForOwnWorkersMember',
                      label: 'Adequate housing for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:PrivacyForOwnWorkersMember',
                      label: 'Privacy for own workers',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 'esrs:NameOfRelatedImpactsRisksAndOpportunities',
              label: 'Name of related impacts, risks and opportunities',
              elementType: 'measure',
              dataType: 'string',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:DescriptionOfKeyContentsOfPolicyExplanatory',
              label: 'Description of key contents of policy',
              elementType: 'measure',
              dataType: 'textBlock',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:DescriptionOfScopeOfPolicyExplanatory',
              label: 'Description of scope of policy',
              elementType: 'measure',
              dataType: 'textBlock',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:DescriptionOfMostSeniorLevelInOrganisationThatIsAccountableForImplementationOfPolicyExplanatory',
              label:
                'Description of most senior level in organisation that is accountable for implementation of policy',
              elementType: 'measure',
              dataType: 'textBlock',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:DisclosureOfThirdpartyStandardsOrInitiativesThatAreRespectedThroughImplementationOfPolicyExplanatory',
              label:
                'Disclosure of third-party standards or initiatives that are respected through implementation of policy',
              elementType: 'measure',
              dataType: 'textBlock',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:DescriptionOfConsiderationGivenToInterestsOfKeyStakeholdersInSettingPolicyExplanatory',
              label:
                'Description of consideration given to interests of key stakeholders in setting policy',
              elementType: 'measure',
              dataType: 'textBlock',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:PolicyIsMadeAvailableToPotentiallyAffectedStakeholders',
              label:
                'Policy is made available to potentially affected stakeholders',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
              children: [
                {
                  id: 'esrs:ExplanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersExplanatory',
                  label:
                    'Explanation of how policy is made available to potentially affected stakeholders',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
              ],
            },
            {
              id: 'esrs:PolicyThatAddressesManagementOfMaterialImpactsRisksAndOpportunitiesCoversAllOwnWorkersValueChainWorkersAffectedCommunitiesConsumersAndEndUsers',
              label:
                'Policy that addresses management of material impacts, risks and opportunities covers all own workers / value chain workers / affected communities / consumers and end-users',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:PolicyThatAddressesManagementOfMaterialImpactsRisksAndOpportunitiesCoversSpecificGroupsOfOwnWorkersValueChainWorkersAffectedCommunitiesConsumersAndEndUsers',
              label:
                'Policy that addresses management of material impacts, risks and opportunities covers specific groups of own workers / value chain workers / affected communities / consumers and end-users',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:PolicyHasAlreadyBeenDisclosedAsPartOfAnotherESRS',
              label:
                'Policy has already been disclosed as part of another ESRS',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
              children: [
                {
                  id: 'esrs:DisclosureOfCrossreferenceToIdentifyAspectsOfPolicyThatSatisfyRequirementsOfThisDisclosureRequirementExplanatory',
                  label:
                    'Disclosure of cross-reference to identify aspects of policy that satisfy requirements of this Disclosure Requirement',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
