export type DisclosureValidator = IsNotEmpty | IsInRange | HasLength | Matches;

export interface IsNotEmpty {
  id: 'isNotEmpty';
  value: boolean;
}

export interface IsInRange {
  id: 'isInRange';
  value: {
    min?: number;
    max?: number;
  };
}

export interface HasLength {
  id: 'hasLength';
  value: {
    max?: number;
    min?: number;
  };
}
export interface Matches {
  id: 'matches';
  value: string;
}

export function isInRangeValidator(
  validator: DisclosureValidator,
): validator is IsInRange {
  return validator.id === 'isInRange';
}

export function isHasLengthValidator(
  validator: DisclosureValidator,
): validator is HasLength {
  return validator.id === 'hasLength';
}
