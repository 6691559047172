import { ApolloProvider } from '@apollo/client';
import { FbsHocDatesProvider } from '@fb/ui-kit';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';

import { client } from '@esg/graphql/apollo-graphql-client';
import RootStyleRegistry from '@esg/shared/components/RootStyleRegistry/RootStyleRegistry';

// eslint-disable-next-line import/order
import { defaultLanguage, languages } from '../../../i18n';

const SharedProviders = function ({ children }: { children: ReactNode }) {
  const router = useRouter();
  const { asPath, query } = router;

  // Detect current language
  const slug = asPath.split('/')[1];
  const langSlug = languages.includes(slug) && slug;
  const language: string =
    (query.lang as string) || langSlug || defaultLanguage;

  const [clientLanguage, setClientLanguage] = useState<string>(language);

  useEffect(() => {
    setClientLanguage(language);
  }, [language]);

  return (
    <ApolloProvider client={client}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <RootStyleRegistry>
        <FbsHocDatesProvider settings={{ locale: clientLanguage }}>
          {children}
        </FbsHocDatesProvider>
      </RootStyleRegistry>
    </ApolloProvider>
  );
};

export default SharedProviders;
