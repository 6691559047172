/* eslint-disable no-unused-vars */
import { create } from 'zustand';

export interface AuditLogStore {
  context: string;
  setContext: (context: string) => void;
}

export const useAuditLogStore = create<AuditLogStore>((set) => ({
  context: '',
  setContext: (context: string) => set(() => ({ context: context })),
}));
