import { Menu, MenuProps } from '@mantine/core';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FbMenuProps extends MenuProps {}

export const FBMenu: typeof Menu = Menu;

export function FbMenu(props: FbMenuProps) {
  return <Menu {...props} />;
}
