export const StrategyInputValidation = {
  visionDescMinLength: 3,
  visionDescMaxLength: 100,
  strategyTitleMinLength: 3,
  strategyTitleMaxLength: 50,
  strategyDescMinLength: 3,
  strategyDescMaxLength: 100,
  objectiveDescMinLength: 3,
  objectiveDescMaxLength: 100,
  goalDescMinLength: 3,
  goalDescMaxLength: 100,
};
