/* eslint-disable no-unused-vars */

export enum DataCollectionStatus {
  UNSTRUCTURE = 'UNSTRUCTURE',
  SUBMITTED = 'SUBMITTED',
  IN_PROGRESS = 'IN_PROGRESS',
  REQUESTED = 'REQUESTED',
  STRUCTURING = 'STRUCTURING',
  STRUCTURED = 'STRUCTURED',
}

/**
 * @description -> A Disclosure goes to this whole lifecycle, FE can use this status to identify if the Disclosure should be visible to OWNER
 * -> Various Button on FE can be also visible/hidden with the status
 * -> Can be used to show Labels for Tags
 */
export enum DataCollectionPkgLifeCycleStatus {
  NEW = 'NEW', // A Newly Created Pck
  DRAFT = 'DRAFT', // Stored In Redis
  SHARED = 'SHARED', // Shared with Owner
  SUBMITTED = 'SUBMITTED', // Submitted Final Version to Owner
  REJECTED_BY_OWNER = 'REJECTED_BY_OWNER', // Rejected By Owner
  ACCEPTED_BY_OWNER = 'ACCEPTED_BY_OWNER', // Accepted by Owner
  DRAFT_LOCKED = 'DRAFT_LOCKED', // Last Draft Version of Redis
}

export enum CollectionFrequency {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export enum DisclosureStructureSaveStatus {
  DRAFT = 'DRAFT',
  FINAL = 'FINAL',
}

export enum DisclosureDataSaveStatus {
  DRAFT = 'DRAFT', // To Store in Redis
  SHARE = 'SHARE', // Shared to Owner
  FINAL = 'FINAL', // For CC , Final Submission
}
