/* eslint-disable no-unused-vars */

import { IUserCompany } from '@esg/graphql/company/fetchCompany.query';
import {
  AppUser,
  FreeSubscriptionTier,
  SubscriptionPlan,
} from '@fb-ui/shared-types';
import { StateCreator } from 'zustand';

export interface UserSessionState {
  userCompany: IUserCompany | null;
  userProfile: AppUser | null;
  isSigningOut: boolean;
  subscriptionPlan: SubscriptionPlan;
  isSubscriptionExpired: boolean;
  setUserCompany: (company: IUserCompany) => void;
  setUserProfie: (userProfile: AppUser) => void;
  setSigningOut: (isSigningOut: boolean) => void;
}

export const userSessionSlice: StateCreator<UserSessionState> = (set) => ({
  userCompany: null,
  userProfile: null,
  isSigningOut: false,
  subscriptionPlan: FreeSubscriptionTier.DATA_PROVIDER,
  isSubscriptionExpired: false,
  setUserCompany: (company) => set({ userCompany: company }),
  setUserProfie: (userProfile: AppUser) => set({ userProfile }),
  setSigningOut: (isSigningOut: boolean) => set({ isSigningOut }),
});
