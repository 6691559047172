import {
  CalculationFormula,
  CalculationFormulaPercent,
  CalculationFormulaRound,
  CalculationFormulaSum,
  CalculationMultipleFormulas,
} from './disclousure-template.type';

export function isCalculationFormulaPercent(
  formula: CalculationFormula,
): formula is CalculationFormulaPercent {
  return formula.operation === 'PERCENT';
}

export function isCalculationFormulaSum(
  formula: CalculationFormula,
): formula is CalculationFormulaSum {
  return formula.operation === 'SUM';
}

export function isCalculationFormulaRound(
  formula: CalculationFormula,
): formula is CalculationFormulaRound {
  return formula.operation === 'ROUND';
}

export function isCalculationFormulaSplit(
  formula: CalculationFormula,
): formula is CalculationMultipleFormulas {
  return formula.operation === 'MULTIPLE_FORMULAS';
}
