import { FbTabsList, FbTabsTab } from '@fb/ui-kit';
import { useState } from 'react';

import { TabDataType } from './NotificationsMenu.types';

const TabListMenu = (props: { selectedValue: string }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabData: TabDataType[] = [
    {
      name: 'Report',
      value: 'reports',
    },
    {
      name: 'Disclosures',
      value: 'disclosures',
    },
    {
      name: 'Data Collection',
      value: 'collection',
    },
  ];
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };
  return (
    <FbTabsList className="border-b px-8 flex">
      {tabData.map((elem, index) => {
        return (
          <FbTabsTab
            className={`grow border-b-4 py-6 ${
              props.selectedValue == elem.value
                ? '!text-accent !border-accent'
                : ''
            } ${activeTab == index ? 'active' : ''}
            `}
            data-cy={`tab-${index}`}
            key={index}
            value={elem.value}
            onClick={() => handleTabClick(index)}
          >
            <p className="flex items-center justify-center">
              <span>{elem.name}</span>
              <span className="ml-1 bg-accent-light text-accent w-5 flex  rounded-full items-center justify-center h-5 p-1 text-dateIndication">
                3
              </span>
            </p>
          </FbTabsTab>
        );
      })}
    </FbTabsList>
  );
};
export default TabListMenu;
