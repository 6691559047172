import { ReactNode } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { IoCloseCircleOutline } from 'react-icons/io5';

import Button from '../Button/Button';
import { Info } from '../Info/Info';

interface DefaultErrorProps {
  errorMessage?: string;
  icon?: ReactNode;
  reset?: () => void;
  resetText?: string;
}

export const DefaultErrorMessage = (props: DefaultErrorProps) => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <Info
      icon={props.icon || <IoCloseCircleOutline size={70} />}
      message={props.errorMessage || 'Something went wrong'}
      type="error"
    >
      <Button
        onClick={props?.reset || resetBoundary}
        className="mt-3 !bg-error hover:!bg-error/90"
      >
        {props?.resetText || 'Reset'}
      </Button>
    </Info>
  );
};
