import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from '@esg/i18n';
// eslint-disable-next-line import/order
import { AuthProvidersAndLayout } from '@esg/shared/components/AuthLayout/AuthLayout';

import '../styles/global.css';
import SharedProviders from '@esg/shared/components/SharedLayout.tsx/SharedLayout';

// eslint-disable-next-line import/order
import { changeLanguage } from 'i18next';

import { defaultLanguage, languages } from '../i18n/config';
import { isPathContainsLang } from '../i18n/helpers';
import {
  appPaths,
  getTitleKeyFromPathname,
} from '@esg/config/app-paths.config';

const App = function ({ Component, pageProps }: unknown) {
  const router = useRouter();
  const { asPath, query, replace, isReady, pathname } = router;
  const { t: globalTranslation } = useTranslation('global');
  // Detect current language
  const slug = asPath.split('/')[1];
  const langSlug = languages.includes(slug) && slug;
  const language: string =
    (query.lang as string) || langSlug || defaultLanguage;

  const [clientLanguage, setClientLanguage] = useState<string>(language);

  useEffect(() => {
    setClientLanguage(language);
  }, [language]);

  const [isPublicDomain, setIsPublicDomain] = useState<boolean>(false);

  useEffect(() => {
    const isPathWithLang = isPathContainsLang(asPath);

    if (asPath.includes('/public/')) {
      setIsPublicDomain(true);
      // Always navigate to /en/some-view if user provided url without language
    } else {
      setIsPublicDomain(false);
    }
    if (asPath === '/en/' || asPath === '/') {
      void replace(`/${clientLanguage}${appPaths.getHomePagePath()}`);
      return;
    }
    if (isReady && !isPathWithLang) {
      void changeLanguage(clientLanguage);
      void replace(`/${clientLanguage}${asPath}`);
    }
  }, [asPath, clientLanguage, isReady]);

  useEffect(() => {
    // Don't trigger `i18next.changeLanguage()` on root folder, use `router` to redirect to the specific language
    if (asPath !== '/' && asPath !== '/404') {
      void changeLanguage(clientLanguage);
    }
  }, [clientLanguage]);
  const title = globalTranslation(
    `pageTitle.${getTitleKeyFromPathname(pathname)}`,
  );
  return isPublicDomain ? (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <SharedProviders>
        <Component {...pageProps} />
      </SharedProviders>
    </>
  ) : (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <SharedProviders>
        <AuthProvidersAndLayout>
          <Component {...pageProps} />
        </AuthProvidersAndLayout>
      </SharedProviders>
    </>
  );
};

export default App;
