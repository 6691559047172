import { IConfig } from '@unleash/proxy-client-react';

// This default config object is just a fallback to avoid building errors
// It's necessary to set the correct env variables for each environment
const defaultConfig = {
  url: 'http://localhost:4242/api/frontend',
  clientKey: 'default:development.unleash-insecure-frontend-api-token',
  appName: 'default',
  refreshInterval: 15,
};

const config: IConfig = {
  url: process.env.NEXT_PUBLIC_FEATURE_FLAG_URL || defaultConfig.url,
  clientKey:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_CLIENT_KEY || defaultConfig.clientKey,
  refreshInterval:
    Number(process.env.NEXT_PUBLIC_FEATURE_FLAG_REFRESH_INTERVAL) ||
    defaultConfig.refreshInterval,
  appName:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_APP_NAME || defaultConfig.appName,
};

export default config;
