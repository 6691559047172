import classnames from 'classnames';
import Image from 'next/image';

import { AvatarProps } from './types';

export function Avatar({
  src,
  initials,
  color = 'supportive-2',
  className = '',
  size = 48,
  ...props
}: AvatarProps) {
  const classes = classnames({
    'rounded-full': true,
    'flex items-center justify-center uppercase w-12 h-12 text-sm font-bold':
      Boolean(initials),
    [`bg-${color}`]: true,
    [className]: true,
  });

  return (
    <div className={classes} style={{ width: size, height: size }} {...props}>
      {src ? (
        <Image
          className="rounded-full"
          src={src}
          width={size}
          height={size}
          alt="avatar"
        />
      ) : (
        initials
      )}
    </div>
  );
}
