import { MantineTheme } from '@fb/ui-kit';
import { MantineThemeComponents } from '@mantine/styles/lib/theme/types/MantineTheme';

import Breadcrumbs from './matine-components/breadcrumbs';
import Table from './matine-components/table';

const MantineCustomTheme: MantineTheme = {
  colorScheme: 'light',

  // Controls focus ring styles:
  // auto – display focus ring only when user navigates with keyboard (default)
  // always – display focus ring when user navigates with keyboard and mouse
  // never – focus ring is always hidden (not recommended)
  // focusRing: 'auto',

  // Change focus ring styles
  // focusRingStyles: {
  //   styles: (theme: Omit<MantineTheme, 'fn'>): CSSObject => {
  //     return {} as CSSObject;
  //   },
  //   resetStyles: (theme: Omit<MantineTheme, 'fn'>): CSSObject => {
  //     return {} as CSSObject;
  //   },
  //   inputStyles: (theme: Omit<MantineTheme, 'fn'>): CSSObject => {
  //     return {} as CSSObject;
  //   },
  // },

  // Determines whether motion based animations should be disabled for
  // users who prefer to reduce motion in their OS settings
  // respectReducedMotion: false,

  // Determines whether elements that do not have pointer cursor by default
  // (checkboxes, radio, native select) should have it
  cursorType: 'pointer',

  // Default border-radius used for most elements
  // defaultRadius: 'xs',

  // White and black colors, defaults to '#fff' and '#000'
  white: '#FFFFFF',
  black: '#000000',

  // Object of arrays with 10 colors
  colors: {
    blue: ['#f3eeff', '#e0dbf6', '', '', '', '', '#5037B5', '', '', ''],

    // dark: ['', '', '', '', '', '', '', '', '', ''],
    // gray: ['', '', '', '', '', '', '', '', '', ''],
    red: ['', '', '', '', '', '', '#E22222', '', '', ''],
    pink: ['', '', '', '', '', '', '', '', '', ''],
    grape: ['', '', '', '', '', '', '', '', '', ''],
    violet: ['', '', '', '', '', '', '', '', '', ''],
    indigo: ['', '', '', '', '', '', '', '', '', ''],
    cyan: ['', '', '', '', '', '', '', '', '', ''],
    green: ['', '', '', '', '', '', '', '', '', ''],
    lime: ['', '', '', '', '', '', '', '', '', ''],
    yellow: ['', '', '', '', '', '', '', '', '', ''],
    orange: ['', '', '', '', '', '', '', '', '', ''],
    teal: ['', '', '', '', '', '', '', '', '', ''],
  },

  // Key of theme.colors
  // primaryColor: '',

  // Index of color from theme.colors that is considered primary, Shade type is 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  // primaryShade: 3,

  // Default gradient used in components that support `variant="gradient"` (Button, ThemeIcon, etc.)
  // defaultGradient: {
  //   from: '',
  //   to: '',
  // },

  // font-family and line-height used in most components
  fontFamily: 'var(--source-sans-pro)',
  // lineHeight: '',

  // Timing function used for animations, defaults to 'ease'
  // transitionTimingFunction: 'ease',

  // Monospace font-family, used in Code, Kbd and Prism components
  // fontFamilyMonospace: '',

  // Sizes for corresponding properties
  // fontSizes: {
  //   xs: '',
  //   sm: '',
  //   md: '',
  //   lg: '',
  //   xl: '',
  // },
  radius: {
    md: '6px',
  },
  // spacing: {
  //   xs: '',
  //   sm: '',
  //   md: '',
  //   lg: '',
  //   xl: '',
  // },

  // Values used for box-shadow
  // shadows: {
  //   xs: '',
  //   sm: '',
  //   md: '',
  //   lg: '',
  //   xl: '',
  // },

  // Breakpoints used in some components to add responsive styles
  // breakpoints: {
  //   xs: '',
  //   sm: '',
  //   md: '',
  //   lg: '',
  //   xl: '',
  // },

  // Styles added to buttons with `:active` pseudo-class
  // activeStyles: {},

  // h1-h6 styles, used in Title and TypographyStylesProvider components
  // headings: {
  //   fontFamily: '',
  //   fontWeight: '',
  //   sizes: {
  //     h1: {
  //       fontSize: '',
  //       fontWeight: undefined,
  //       lineHeight: undefined,
  //     },
  //     h2: {
  //       fontSize: '',
  //       fontWeight: undefined,
  //       lineHeight: undefined,
  //     },
  //     h3: {
  //       fontSize: '',
  //       fontWeight: undefined,
  //       lineHeight: undefined,
  //     },
  //     h4: {
  //       fontSize: '',
  //       fontWeight: undefined,
  //       lineHeight: undefined,
  //     },
  //     h5: {
  //       fontSize: '',
  //       fontWeight: undefined,
  //       lineHeight: undefined,
  //     },
  //     h6: {
  //       fontSize: '',
  //       fontWeight: undefined,
  //       lineHeight: undefined,
  //     },
  //   },
  // },

  // theme functions, see in theme functions guide
  // fn: null,

  // Left to right or right to left direction, see RTL Support guide to learn more
  // dir: 'ltr',

  // Default loader used in Loader and LoadingOverlay components
  loader: 'oval',

  // defaultProps, styles and classNames for components
  components: {
    Breadcrumbs,
    Table,
  } as MantineThemeComponents,

  // Global styles
  // globalStyles: (theme: MantineTheme) => null,

  // Add your own custom properties on Mantine theme
  other: {},
  defaultRadius: 'md',
} as MantineTheme;

export default MantineCustomTheme;
