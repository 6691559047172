import { ActionIcon, ActionIconProps } from '@mantine/core';

interface FbActionIconProps extends Partial<ActionIconProps> {
  // eslint-disable-next-line no-unused-vars
  onClick: (args: any) => void;
}

export function FbActionIcon(props: FbActionIconProps) {
  return <ActionIcon {...props}>{props.children}</ActionIcon>;
}
