'use client';

import Image from 'next/image';
import { useEffect, useState } from 'react';
import { MdOutlineMinimize } from 'react-icons/md';

function ChatBot() {
  const [chatbotVisible, setChatbotVisible] = useState(false);
  const chatbotId = process.env.NEXT_PUBLIC_CHATBOT_ID;

  useEffect(() => {
    if (!chatbotId) {
      console.error('chatbotId is not defined.');
    } else {
      console.log(`chatbotId is defined`);
    }
  }, [chatbotId]);

  const toggleChatbot = () => {
    setChatbotVisible((prevVisible) => !prevVisible);
    console.log(`Chatbot visibility toggled`);
  };

  const renderIframe = () => {
    const iframeSrc = process.env.NEXT_PUBLIC_CHATBOT_NAVIGATION_AiASSISTANT;
    console.log(`Rendering iframe with src`);
    return iframeSrc;
  };

  return (
    <div
      className={`fixed bottom-8 ${
        chatbotVisible ? 'right-6 min-w-[25rem]' : 'right-10 min-w-[20rem]'
      } z-[101] bg-supportive-2 border border-monochrome-1 rounded-md`}
    >
      <button
        className="flex flex-row items-center relative w-full"
        onClick={toggleChatbot}
      >
        <Image
          src={'/images/chat-bot/aihelp.svg'}
          alt={'ai help'}
          width={65}
          height={61}
        />
        <div className="text-caption font-bold text-secondary w-[12.5rem]">
          Get the right help, the right time from our AI Assistant
        </div>
        {chatbotVisible ? (
          <div className="mr-5 ml-20">
            <MdOutlineMinimize size={24} className="mb-3.5" />
          </div>
        ) : (
          <Image
            className="absolute w-auto h-[140%] right-[-2.188rem] top-[-0.313rem]"
            src={'/images/chat-bot/messanger.svg'}
            alt={'messanger'}
            width={60}
            height={60}
          />
        )}
      </button>
      {chatbotVisible && (
        <div className="w-auto h-[34.375rem] bg-background">
          <iframe
            src={renderIframe()}
            className="w-full h-full border-none"
          ></iframe>
        </div>
      )}
    </div>
  );
}

export default ChatBot;
