import { FbSkeleton } from '@fb/ui-kit';

function ActivityItemSkeleton() {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex">
        <FbSkeleton height="50px" width="50px" circle className="flex-none" />
        <div className="flex-grow pl-2.5">
          <FbSkeleton height={18} radius={0} />
          <FbSkeleton height={18} mt={6} radius={0} />
        </div>
      </div>
      <FbSkeleton height="18px" radius={0} />
      <FbSkeleton height="18px" radius={0} />
    </div>
  );
}

export default ActivityItemSkeleton;
