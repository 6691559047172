import { UserRole } from '@fb-ui/shared-types';
import { useEffect, useMemo } from 'react';

import { useTranslation } from '@esg/i18n';
import Button from '@esg/shared/components/Button/Button';
import useAuth from '@esg/shared/hooks/useAuth';

import { AuthProps } from './Auth.types';
import AuthMessage from './AuthMessage';

export function Auth({ children }: AuthProps) {
  const { t } = useTranslation('global');

  const auth = useAuth();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { events, isAuthenticated, user, activeNavigator, isSigningOut } = auth;

  const signIn = () => {
    return auth
      .signinSilent()
      .then((re) => {
        if (!re) {
          console.log(
            'Signing silent did not have response, therefore we are signinRedirect',
          );
          return auth.signinRedirect();
        }
        console.log('Signing silent response', re);
      })
      .catch((_) => {
        console.error(
          'Catched sileent signing in error. Starts sign in redirect',
          _,
        );
        return auth.signinRedirect();
      });
  };

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return events.addAccessTokenExpiring(() => {
      void signIn();
      return;
    });
  }, [events, auth]);

  useEffect(() => {
    if (auth.user?.profile?.userType) {
      if (shouldGoToKYC()) {
        return void redirectToKYC();
      }
      return;
    }
    if (!isAuthenticated) {
      void signIn();
    }
  }, [user?.profile?.userType, isAuthenticated]);

  const redirectToKYC = () => {
    return auth.removeUser().then(() => {
      setTimeout(
        () => window.open(process.env.NEXT_PUBLIC_ONBOARDING_APP, '_self'),
        100,
      );
      return null;
    });
  };

  const shouldGoToKYC = () => {
    if (!user) {
      return false;
    }

    const userType = user.profile.userType;
    if (!userType) {
      return false;
    }
    return (
      userType &&
      [UserRole.KYC_USER, UserRole.PLATFORM_ADMIN].includes(userType)
    );
  };

  const hasUserProfileType = !!auth.user?.profile.userType;

  const isLoading = auth.isLoading;

  const authenticationTemplate = useMemo(() => {
    if (!hasUserProfileType) {
      if (activeNavigator === 'signoutRedirect') {
        return <AuthMessage message={t('auth.signing-out')} />;
      }

      if (activeNavigator === 'signinRedirect') {
        return <AuthMessage message={t('auth.redirecting')} />;
      }

      if (activeNavigator === 'signinSilent') {
        return <AuthMessage message={t('auth.checking')} />;
      }

      if (isLoading) {
        return <AuthMessage message={t('auth.loading')} />;
      }

      return (
        <div className="flex flex-col items-center justify-center w-full h-screen">
          <div className="mb-5">{t('auth.logout.message')}</div>
          <Button variant="primary" onClick={() => void auth.signinRedirect()}>
            {t('auth.login')}
          </Button>
        </div>
      );
    } else {
      return null;
    }
  }, [hasUserProfileType, activeNavigator, isLoading]);

  if (isSigningOut) {
    return <AuthMessage message={t('auth.signing-out')} />;
  }

  return authenticationTemplate || children;
}

export default Auth;
