/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  ApolloClient,
  ApolloLink,
  concat,
  from,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import oidcConfig from '@esg/config/auth.config';

const linkProps = {
  uri: process.env.NEXT_PUBLIC_GRAPHQL_API,
  headers: {
    'Content-Type': 'application/json',
  },
  fetch,
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}`, { locations, path }),
    );

  if (networkError) {
    console.log(`[Network error]`, networkError);
  }
});

const uploadLink = createUploadLink(linkProps);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers

  const oidcUserKey = `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`;
  const profileData = sessionStorage.getItem(oidcUserKey);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const authToken: string = profileData
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (JSON.parse(profileData).access_token as string)
    : '';

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      DataCollectionResponse: {
        fields: {
          DataCollection: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
    },
  }),
  link: from([errorLink, concat(authMiddleware, uploadLink)]),
});
