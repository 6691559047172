import {
  MasterDisclosureTemplate,
  DisclosureData,
  DisclosureUiConfig,
  DisclosureDataType,
  DisclosureValue,
  DisclosureStringValue,
  DisclosureStringArrayValue,
  DisclosureDecimalValue,
  DisclosureBooleanValue,
  DisclosureIntegerValue,
} from '@fb-ui/shared-types';
import { createIdToDataTypeMap } from './disclosure-data-type-collection.helpers';

export function calculateDisclosureDataCollectionAggregatedValue({
  values,
  ui,
  template,
}: {
  template: MasterDisclosureTemplate;
  values: DisclosureData[];
  ui: DisclosureUiConfig;
}): DisclosureData | null {
  if (!(!!values.length && ui)) {
    return null;
  }

  // 1. Create a map of id to dataType

  // Example: {
  //   'esrs:textString': 'string',
  //   'esrs:textBlock': 'textBlock',
  // }
  const idToDataTypeMap: Record<string, DisclosureDataType> =
    createIdToDataTypeMap(template);

  // 2. To have a looping mechanism to go through all the values

  const resultAggregatedValues: DisclosureData = {
    meta: {
      templateId: ui.meta.templateId,
    },
    data: {},
  };

  Object.entries(idToDataTypeMap).forEach(([id, dataType]) => {
    // Implement the logic to get the values by id from each data
    const valuesByIdFromEachData = values
      .map((data) => data.data[id])
      .filter((d) => d && d.value !== null && d.value !== undefined);

    if (valuesByIdFromEachData.length) {
      try {
        const aggregatedValue = aggregate(valuesByIdFromEachData, dataType);
        if (
          aggregatedValue &&
          aggregatedValue.value !== null &&
          aggregatedValue.value !== undefined
        ) {
          resultAggregatedValues.data[id] = aggregatedValue;
        }
      } catch (error) {
        // eslint-disable-next-line no-undef
        console.error(error);
      }
    }
  });

  return resultAggregatedValues;
}

function aggregate(
  disclosureValues: DisclosureValue[],
  dataType: DisclosureDataType,
): DisclosureValue | null {
  switch (dataType) {
    case 'area':
      return aggregateArea(disclosureValues);
    case 'boolean':
      return aggregateBooleanOR(disclosureValues);
    case 'decimal':
      return aggregateDecimal(disclosureValues);
    case 'monetary':
      return aggregateMonetarySum(disclosureValues);
    case 'energy':
      return aggregateEnergy(disclosureValues);
    case 'enumeration':
      return aggregateEnumeration(disclosureValues);
    case 'enumerationSet':
      return aggregateEnumerationSet(disclosureValues);
    case 'ghgEmissions':
      return aggregateGhgEmissions(disclosureValues);
    case 'year':
      return aggregateYear(disclosureValues);
    case 'integer':
      return aggregateInteger(disclosureValues);
    case 'mass':
      return aggregateMass(disclosureValues);
    case 'string':
      return aggregateString(disclosureValues);
    case 'textBlock':
      return aggregateTextBlock(disclosureValues);
    case 'volume':
      return aggregateVolume(disclosureValues);
    case 'percent':
      return aggregatePercent(disclosureValues);
    case 'date':
      return aggregateDate(disclosureValues);

    case 'energyPerMonetary':
      return aggregateEnergyPerMonetary(disclosureValues);
    case 'ghgEmissionsPerMonetary':
      return aggregateGhgEmissionsPerMonetary(disclosureValues);
    case 'volumePerMonetary':
      return aggregateVolumePerMonetary(disclosureValues);
    default:
      throw new Error(`Unhandled data type: ${dataType}`);
  }
}

export function aggregateArea(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const sum = disclosureValues.reduce((acc, currentValue) => {
    return (
      acc + (typeof currentValue.value === 'number' ? currentValue.value : 0)
    );
  }, 0);
  return { value: sum };
}
export function aggregatePercent(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const validValues = disclosureValues
    .map((currentValue) => currentValue.value)
    .filter(
      (value) =>
        typeof value === 'number' && value !== undefined && value !== null,
    ) as number[];

  const sum = validValues.reduce((acc, value) => acc + value, 0);
  const mean = validValues.length > 0 ? sum / validValues.length : 0;

  return { value: mean };
}

export function aggregateBooleanAND(
  disclosureValues: DisclosureValue[],
): DisclosureBooleanValue {
  const result = disclosureValues.every(
    (value) => (value as DisclosureBooleanValue).value === true,
  );
  return { value: result };
}

export function aggregateBooleanOR(
  disclosureValues: DisclosureValue[],
): DisclosureBooleanValue {
  const result = disclosureValues.some(
    (value) => (value as DisclosureBooleanValue).value === true,
  );
  return { value: result };
}

export function aggregateDecimal(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const sum = disclosureValues.reduce((acc, currentValue) => {
    if (typeof currentValue.value === 'number') {
      return acc + currentValue.value;
    } else {
      throw new Error(
        'Invalid value encountered while aggregating decimal values.',
      );
    }
  }, 0);

  return { value: sum };
}

export function aggregateEnergyPerMonetary(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const sum = disclosureValues.reduce((acc, currentValue) => {
    if (typeof currentValue.value === 'number') {
      return acc + currentValue.value;
    } else {
      throw new Error(
        'Invalid value encountered while aggregating energyPerMonetary values.',
      );
    }
  }, 0);
  return { value: sum };
}
export function aggregateEnergy(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const sum = disclosureValues.reduce((acc, currentValue) => {
    if (typeof currentValue.value === 'number') {
      return acc + currentValue.value;
    } else {
      throw new Error(
        'Invalid value encountered while aggregating energy values.',
      );
    }
  }, 0);
  return { value: sum };
}

export function aggregateReturnFirst(
  disclosureValues: DisclosureValue[],
): DisclosureValue | undefined {
  return disclosureValues.find((d) => d.value);
}
export function aggregateEnumeration(
  _disclosureValues: DisclosureValue[],
): DisclosureStringValue {
  throw new Error('Aggregation not supported for enumeration data type.');
}

export function aggregateYear(
  _disclosureValues: DisclosureValue[],
): DisclosureValue | null {
  return aggregateReturnFirst(_disclosureValues) || null;
}
export function aggregateDate(
  _disclosureValues: DisclosureValue[],
): DisclosureValue | null {
  return aggregateReturnFirst(_disclosureValues) || null;
}

export function aggregateEnumerationSet(
  disclosureValues: DisclosureValue[],
): DisclosureStringArrayValue {
  const unionSet = new Set<string>();

  disclosureValues.forEach((disclosureValue) => {
    if (Array.isArray(disclosureValue.value)) {
      disclosureValue.value.forEach((val) => unionSet.add(val));
    } else {
      throw new Error(
        'Invalid value encountered while aggregating enumeration set values.',
      );
    }
  });

  return { value: Array.from(unionSet) };
}

export function aggregateGhgEmissions(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const sum = disclosureValues.reduce((acc, currentValue) => {
    if (typeof currentValue.value === 'number') {
      return acc + currentValue.value;
    } else {
      throw new Error(
        'Invalid value encountered while aggregating GHG emissions values.',
      );
    }
  }, 0);
  return { value: sum };
}
export function aggregateGhgEmissionsPerMonetary(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const sum = disclosureValues.reduce((acc, currentValue) => {
    if (typeof currentValue.value === 'number') {
      return acc + currentValue.value;
    } else {
      throw new Error(
        'Invalid value encountered while aggregating ghgEmissionsPerMonetary values.',
      );
    }
  }, 0);
  return { value: sum };
}

export function aggregateInteger(
  disclosureValues: DisclosureValue[],
): DisclosureIntegerValue {
  const sum = disclosureValues.reduce((acc, currentValue) => {
    if (
      typeof currentValue.value === 'number' &&
      Number.isInteger(currentValue.value)
    ) {
      return acc + currentValue.value;
    } else {
      throw new Error(
        'Invalid value encountered while aggregating integer values.',
      );
    }
  }, 0);
  return { value: sum };
}

export function aggregateMass(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const sum = disclosureValues.reduce((acc, currentValue) => {
    if (typeof currentValue.value === 'number' && !isNaN(currentValue.value)) {
      return acc + currentValue.value;
    } else {
      throw new Error(
        'Invalid value encountered while aggregating mass values.',
      );
    }
  }, 0);
  return { value: sum };
}

export function aggregateMonetarySum(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const sum = disclosureValues.reduce((acc, currentValue) => {
    if (typeof currentValue.value === 'number' && !isNaN(currentValue.value)) {
      return acc + currentValue.value;
    } else {
      throw new Error(
        'Invalid value encountered while aggregating monetary values.',
      );
    }
  }, 0);

  return { value: sum };
}

export function aggregateString(
  disclosureValues: DisclosureValue[],
): DisclosureStringValue {
  const strings = disclosureValues
    .map((value) => (value as DisclosureStringArrayValue).value)
    .flat();
  const aggregatedString = Array.from(new Set(strings)).join(', ');
  return { value: aggregatedString };
}

export function aggregateTextBlock(
  disclosureValues: DisclosureValue[],
): DisclosureStringValue {
  const aggregatedText = disclosureValues
    .map((value) => value.value as string)
    .join('\n');
  return { value: aggregatedText };
}

export function aggregateVolume(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const volumes: number[] = [];
  for (const value of disclosureValues) {
    const volume = value.value as number;
    volumes.push(volume);
  }

  const sum = volumes.reduce((acc, volume) => acc + volume, 0);
  return { value: sum };
}
export function aggregateVolumePerMonetary(
  disclosureValues: DisclosureValue[],
): DisclosureDecimalValue {
  const volumes: number[] = [];
  for (const value of disclosureValues) {
    const volume = value.value as number;
    volumes.push(volume);
  }

  const sum = volumes.reduce((acc, volume) => acc + volume, 0);
  return { value: sum };
}

export function aggregateStartDate(
  disclosureValues: DisclosureValue[],
): DisclosureIntegerValue {
  const dates = disclosureValues.map((value) => value.value as number);
  const minDate = Math.min(...dates);
  return { value: minDate };
}

export function aggregateEndDate(
  disclosureValues: DisclosureValue[],
): DisclosureIntegerValue {
  const dates = disclosureValues.map((value) => value.value as number);
  const maxDate = Math.max(...dates);
  return { value: maxDate };
}
