/* eslint-disable no-unused-vars */
export enum ConnectionRelation {
  CHILD_ENTITY = 'Sub Organization',
  SUPPLIER = 'Supplier',
}

export enum UserType {
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_EMPLOYEE = 'COMPANY_EMPLOYEE',
  KYC_USER = 'KYC_USER',
}

export enum OnboardingRequestStatus {
  INVITED = 'INVITED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export const CompanyStatus = {
  KYC_STATUS_NEW: 'NEW',
  KYC_STATUS_REQUEST_REJECTED: 'REQUEST_REJECTED',
  KYC_STATUS_REQUEST_APPROVED: 'REQUEST_APPROVED',
  KYC_STATUS_SUBMITTED: 'SUBMITTED',
  KYC_STATUS_APPROVED: 'APPROVED',
  KYC_STATUS_REJECTED: 'REJECTED',
  KYC_STATUS_DRAFT: 'DRAFT',
  KYC_STATUS_EDIT: 'EDIT',
};

export enum CompanyFullTextSearchInpFilterEnum {
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_IDENTIFIER = 'COMPANY_IDENTIFIER',
}

/**
 * * CORPORATE : Companies who have purchased License -> Reporting Company
 * Companies can upgrade to Corporate and become Reporting Company
 * Companies can downgrade to Data_Provider and become Non Reporting Company
 */
export enum PaidSubscriptionTier {
  CORPORATE = 'CORPORATE', // Reporting Company are Assigned 'CORPORATE'
}

/**
 * * DATA_PROVIDER : Companies who haven't purchased License -> Non Reporting Company
 * Companies can upgrade to Corporate and become Reporting Company
 * Companies can downgrade to Data_Provider and become Non Reporting Company
 */
export enum FreeSubscriptionTier {
  DATA_PROVIDER = 'DATA_PROVIDER', // Default License Type , Non Reporting are assigned 'DATA_PROVIDER'
}

export type SubscriptionPlan = FreeSubscriptionTier | PaidSubscriptionTier;

/**
 * * Status are Maintained Dynamically based on End Date
 *  1. If Current Date Time has passed End Date of Subscription, Status is 'EXPIRED'
 *  2. If Current Date is between Start and End Date of Subscription , Status is 'ACTIVE'
 *  3. Else, Status is Pending , Indicating Subscription has not started / approved
 */
export enum CompanySubscriptionStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
}
