import { SVGProps } from 'react';

function MaterialityAssessmentIcon({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  fill = '#595470',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" {...props}>
      <path
        d="M16.8519 15.1044C17.2241 14.4919 17.444 13.7831 17.444 13.0131C17.444 10.8344 15.7439 9.07561 13.6377 9.07561C11.5316 9.07561 9.83149 10.8344 9.83149 13.0131C9.83149 15.1919 11.5316 16.9506 13.6293 16.9506C14.3736 16.9506 15.0672 16.7231 15.6508 16.3381L18.2898 19.0681L19.4909 17.8256L16.8519 15.1044ZM13.6377 15.2006C12.4705 15.2006 11.5232 14.2206 11.5232 13.0131C11.5232 11.8056 12.4705 10.8256 13.6377 10.8256C14.805 10.8256 15.7523 11.8056 15.7523 13.0131C15.7523 14.2206 14.805 15.2006 13.6377 15.2006ZM13.3332 7.76311C12.7073 7.78062 12.1068 7.92062 11.557 8.15687L11.0918 7.43061L7.87761 12.8381L5.33165 9.75811L2.26128 14.8419L0.882568 13.8181L5.11174 6.81812L7.64924 9.88062L11.0326 4.19312L13.3332 7.76311ZM15.5239 8.20061C14.9826 7.95562 14.399 7.80687 13.79 7.77187L18.1207 0.693115L19.4909 1.72562L15.5239 8.20061Z"
        fill={fill}
      />
    </svg>
  );
}

export default MaterialityAssessmentIcon;
