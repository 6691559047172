import { useLazyQuery } from '@apollo/client';
import { ReactElement, useEffect } from 'react';

import {
  CompanyDetailsResponse,
  FETCH_COMPANY_DETAILS,
} from '@esg/graphql/company/fetchCompany.query';
import useAuth from '@esg/shared/hooks/useAuth';
import { useAppStore } from '@esg/shared/store';

function UserStore({ children }: { children: ReactElement<unknown, string> }) {
  const {
    user: { profile },
  } = useAuth();

  const { setUserCompany } = useAppStore();

  const [fetchCompany, { data: companyDetails }] =
    useLazyQuery<CompanyDetailsResponse>(FETCH_COMPANY_DETAILS, {
      variables: { id: profile?.companyId },
    });

  useEffect(() => {
    if (!profile.companyId) {
      return;
    }
    void fetchCompany({ variables: { id: profile.companyId } });
  }, [profile?.companyId]);

  useEffect(() => {
    if (!companyDetails) {
      return;
    }
    setUserCompany(companyDetails.company);
  }, [companyDetails]);

  return children || null;
}

export default UserStore;
