/* eslint-disable no-unused-vars */
import { ReactNode } from 'react';

export type CardVariant = 'Default' | 'BoxShadow';

export enum CardVariants {
  DEFAULT = 'Default',
  SHADOW = 'BoxShadow',
}

export interface EsgCardProps {
  children: ReactNode;
  height?: string;
  width?: string;
  variant?: CardVariant;
  color?: string;
  border?: boolean;
  padding?: string;
  className?: string;
  clickable?: boolean;
  imageLeft?: string;
  imageLeftBackground?: string;
  imageRight?: string;
  imageRightBackground?: string;
  accordionTitle?: string | ReactNode; // If it's provided, the card will be an accordion
  isAccordionDefaultOpen?: boolean;
  [key: string]: unknown;
}
