/* eslint-disable no-unused-vars */
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';

export enum ButtonVariants {
  PRIMARY = 'primary',
  DESELECTED = 'deselected',
  SECONDARY = 'secondary',
  ALTERNATIVE = 'alternative',
  TRANSPARENT = 'transparent',
  SUPPORTIVE_4 = 'supportive-4',
  ICON = 'icon',
  SHADED = 'shaded',
}

export type ButtonVariant = `${ButtonVariants}`;

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: ReactNode;
  variant?: ButtonVariant;
  color?: 'primary' | 'accent' | 'supportive-4';
  badge?: number;
  loading?: boolean;
  replace?: boolean;
  underDevelopment?: boolean;
}
