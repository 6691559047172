import { MultiSelect, MultiSelectProps, SelectItem } from '@mantine/core';

export interface FbMultiSelectProps<T extends string>
  extends Omit<MultiSelectProps, 'data' | 'value' | 'onChange'> {
  data: SelectItem[];
  value: T[];
  onChange: (value: T[]) => void;
}

export function FbMultiSelect<T extends string>({
  data,
  value,
  onChange,
  ...otherProps
}: FbMultiSelectProps<T>) {
  return (
    <MultiSelect
      {...otherProps}
      data={data}
      value={value.map(String)}
      onChange={(newValue: any[]) =>
        onChange(newValue.map((item) => item as T))
      }
    />
  );
}
