import { Framework, MasterDisclosureTemplate } from '@fb-ui/shared-types';

export const ESRSS16: MasterDisclosureTemplate = {
  meta: {
    id: 'S1-6',
    docType: 'tax-disclosure',
    fileName: 'ESRS_S1-6.tax-disclosure.json',
    framework: Framework.ESRS,
    taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
    taxonomyURI:
      'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
    taxonomyPublicationDate: '2022-11-22',
    disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401060',
    disclosureName: '[401060] S1-6 Characteristics of undertaking’s employees',
    disclosureSortId: 401060,
    namespaces: [
      {
        prefix: 'esrs',
        namespaceURI: 'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22',
      },
    ],
    dictionaries: [
      {
        id: 'dimensions',
        docType: 'tax-dictionary',
        fileName: 'ESRS_dimensions.tax-dictionary.json',
        framework: Framework.ESRS,
        taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
        taxonomyURI:
          'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
        taxonomyPublicationDate: '2022-11-22',
        libVersion: '0.2.0',
        labels: [],
        namespaces: [],
      },
    ],
    labels: [
      {
        id: 'S1-6_labels',
        docType: 'tax-disclosure-labels',
        fileName: 'ESRS_S1-6_labels.tax-disclosure-labels.json',
        framework: Framework.ESRS,
        taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
        taxonomyURI:
          'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
        disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401060',
        taxonomyPublicationDate: '2022-11-22',
        libVersion: '0.2.0',
      },
    ],
    libVersion: '0.2.0',
    creationTimestamp: '2023-11-13T12:57:45+00:00',
  },
  children: [
    {
      id: 'esrs:DescriptionOfKeyCharacteristicsOfEmployeesInOwnWorkforceExplanatory',
      label: 'Description of key characteristics of employees in own workforce',
      elementType: 'measure',
      dataType: 'textBlock',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
      children: [
        {
          id: 'esrs:NumberOfEmployeesHeadcount',
          label: 'Total number of employees (head count)',
          labelRole: 'totalLabel',
          elementType: 'measure',
          dataType: 'integer',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:AverageNumberOfEmployeesHeadCount',
          label: 'Average number of employees (head count)',
          elementType: 'measure',
          dataType: 'integer',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesByGenderTable',
          label:
            'Characteristics of undertaking’s employees - number of employees by gender',
          elementType: 'table',
          dataType: null,
          dimensions: [
            {
              id: 'esrs:GenderAxis',
              label: 'Gender',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:GenderMember',
                  label: 'Gender',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:FemaleMember',
                      label: 'Female',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:MaleMember',
                      label: 'Male',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:OtherThanFemaleAndMaleMember',
                      label: 'Other than female and male',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:GenderNotDisclosedMember',
                      label: 'Gender not disclosed',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'aggragate:TotalTableEmployees',
                      label: 'Total employees',
                      elementType: 'domainMember',
                      isAbstract: false,
                      dataType: null,
                      isCustom: true,
                      isCalculated: true,
                    },
                  ],
                },
              ],
            },
          ],
          measures: [
            {
              id: 'esrs:NumberOfEmployeesHeadcount',
              label: 'Total number of employees (head count)',
              labelRole: 'totalLabel',
              elementType: 'measure',
              dataType: 'integer',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:AverageNumberOfEmployeesHeadCount',
              label: 'Average number of employees (head count)',
              elementType: 'measure',
              dataType: 'integer',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
          ],
        },
        {
          id: 'esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployees',
          label: 'Number of employees in countries with 50 or more employees',
          elementType: 'measure',
          dataType: 'integer',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:AverageNumberOfEmployeesInCountriesWith50OrMoreEmployees',
          label:
            'Average number of employees in countries with 50 or more employees',
          elementType: 'measure',
          dataType: 'integer',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:CharacteristicsOfUndertakingsEmployeesNumberOfEmployeesInCountriesWith50OrMoreEmployeesTable',
          label:
            'Characteristics of undertaking’s employees - number of employees in countries with 50 or more employees',
          elementType: 'table',
          dataType: null,
          dimensions: [
            {
              id: 'esrs:CountryAxis',
              label: 'Country',
              elementType: 'dimensionExplicit',
              dataType: null,
              dictionaryRef: 'dimensions',
              dictionaryKey: 'countryList',
            },
            {
              id: 'esrs:SectorsAndEconomicActivitiesAxis',
              label: 'Sectors and economic activities',
              elementType: 'dimensionExplicit',
              dataType: null,
              dictionaryRef: 'dimensions',
              dictionaryKey: 'role-902000',
            },
          ],
          measures: [
            {
              id: 'esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployees',
              label:
                'Number of employees in countries with 50 or more employees',
              elementType: 'measure',
              dataType: 'integer',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:AverageNumberOfEmployeesInCountriesWith50OrMoreEmployees',
              label:
                'Average number of employees in countries with 50 or more employees',
              elementType: 'measure',
              dataType: 'integer',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
          ],
        },
        {
          id: 'esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalent',
          label: 'Number of employees (head count or full-time equivalent)',
          elementType: 'measure',
          dataType: 'decimal',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:AverageNumberOfEmployeesHeadCountOrFulltimeEquivalent',
          label:
            'Average number of employees (head count or full-time equivalent)',
          elementType: 'measure',
          dataType: 'decimal',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndGenderTable',
          label:
            'Characteristics of undertaking’s employees - information on employees by contract type and gender',
          elementType: 'table',
          dataType: null,
          dimensions: [
            {
              id: 'esrs:MethodologyOfEmployeeMeasurementAxis',
              label: 'Methodology of employee measurement',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:MethodologyOfEmployeeMeasurementMember',
                  label: 'Methodology of employee measurement',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:HeadCountMember',
                      label: 'Head count',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:FulltimeEquivalentMember',
                      label: 'Full-time equivalent',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 'esrs:GenderAxis',
              label: 'Gender',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:GenderMember',
                  label: 'Gender',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:FemaleMember',
                      label: 'Female',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:MaleMember',
                      label: 'Male',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:OtherThanFemaleAndMaleMember',
                      label: 'Other than female and male',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:GenderNotDisclosedMember',
                      label: 'Gender not disclosed',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 'esrs:TypeOfEmployeeContractAxis',
              label: 'Type of employee contract',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:TypeOfEmployeeContractMember',
                  label: 'Type of employee contract',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:PermanentEmployeesMember',
                      label: 'Permanent employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:TemporaryEmployeesMember',
                      label: 'Temporary employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:NonguaranteedHoursEmployeesMember',
                      label: 'Non-guaranteed hours employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:FulltimeEmployeesMember',
                      label: 'Full-time employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:ParttimeEmployeesMember',
                      label: 'Part-time employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 'esrs:SectorsAndEconomicActivitiesAxis',
              label: 'Sectors and economic activities',
              elementType: 'dimensionExplicit',
              dataType: null,
              dictionaryRef: 'dimensions',
              dictionaryKey: 'role-902000',
            },
          ],
          measures: [
            {
              id: 'esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalent',
              label: 'Number of employees (head count or full-time equivalent)',
              elementType: 'measure',
              dataType: 'decimal',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:AverageNumberOfEmployeesHeadCountOrFulltimeEquivalent',
              label:
                'Average number of employees (head count or full-time equivalent)',
              elementType: 'measure',
              dataType: 'decimal',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
          ],
        },
        {
          id: 'esrs:CharacteristicsOfUndertakingsEmployeesInformationOnEmployeesByContractTypeAndRegionTable',
          label:
            'Characteristics of undertaking’s employees - information on employees by contract type and region',
          elementType: 'table',
          dataType: null,
          dimensions: [
            {
              id: 'esrs:RegionTypedAxis',
              label: 'Region',
              elementType: 'dimensionTyped',
              dataType: 'string',
              isNillable: true,
            },
            {
              id: 'esrs:MethodologyOfEmployeeMeasurementAxis',
              label: 'Methodology of employee measurement',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:MethodologyOfEmployeeMeasurementMember',
                  label: 'Methodology of employee measurement',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:HeadCountMember',
                      label: 'Head count',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:FulltimeEquivalentMember',
                      label: 'Full-time equivalent',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
            {
              id: 'esrs:TypeOfEmployeeContractAxis',
              label: 'Type of employee contract',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:TypeOfEmployeeContractMember',
                  label: 'Type of employee contract',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:PermanentEmployeesMember',
                      label: 'Permanent employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:TemporaryEmployeesMember',
                      label: 'Temporary employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:NonguaranteedHoursEmployeesMember',
                      label: 'Non-guaranteed hours employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:FulltimeEmployeesMember',
                      label: 'Full-time employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:ParttimeEmployeesMember',
                      label: 'Part-time employees',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
          ],
          measures: [
            {
              id: 'esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalent',
              label: 'Number of employees (head count or full-time equivalent)',
              elementType: 'measure',
              dataType: 'decimal',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:AverageNumberOfEmployeesHeadCountOrFulltimeEquivalent',
              label:
                'Average number of employees (head count or full-time equivalent)',
              elementType: 'measure',
              dataType: 'decimal',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
          ],
        },
        {
          id: 'esrs:NumberOfOwnEmployeeTurnover',
          label: 'Total number of own employee turnover',
          labelRole: 'totalLabel',
          elementType: 'measure',
          dataType: 'integer',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:PercentageOfOwnEmployeeTurnover',
          label: 'Percentage of own employee turnover',
          elementType: 'measure',
          dataType: 'percent',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:DescriptionOfMethodologiesAndAssumptionsUsedToCompileDataEmployeesExplanatory',
          label:
            'Description of methodologies and assumptions used to compile data (employees)',
          elementType: 'measure',
          dataType: 'textBlock',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
          children: [
            {
              id: 'esrs:EmployeesNumbersAreReportedInHeadCount',
              label: 'Employees numbers are reported in head count',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:EmployeesNumbersAreReportedInFulltimeEquivalent',
              label: 'Employees numbers are reported in full-time equivalent',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:EmployeesNumbersAreReportedAtEndOfReportingPeriod',
              label:
                'Employees numbers are reported at end of reporting period',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:EmployeesNumbersAreReportedAsReportingPeriodAverage',
              label:
                'Employees numbers are reported as reporting period average',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:EmployeesNumbersAreReportedUsingAnotherMethodology',
              label: 'Employees numbers are reported using another methodology',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
          ],
        },
        {
          id: 'esrs:DisclosureOfContextualInformationNecessaryToUnderstandDataEmployeesExplanatory',
          label:
            'Disclosure of contextual information necessary to understand data (employees)',
          elementType: 'measure',
          dataType: 'textBlock',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:DisclosureOfCrossreferenceOfInformationReportedUnderParagragph51AToMostRepresentativeNumberInFinancialStatementsExplanatory',
          label:
            'Disclosure of cross-reference of information reported under paragragph 51 (a) to most representative number in financial statements',
          elementType: 'measure',
          dataType: 'textBlock',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
      ],
    },
  ],
};
