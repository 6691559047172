import React from 'react';
import { FallbackProps } from 'react-error-boundary';

import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';

interface GlobalErrorFallbackProps extends FallbackProps {
  error: {
    message: string;
    [key: string]: unknown;
  };
}

function GlobalErrorFallback({ error }: GlobalErrorFallbackProps) {
  return (
    <div className="flex flex-col justify-center items-center grow h-[100vh]">
      <DefaultErrorMessage
        errorMessage={error.message}
        resetText="Reload page"
      />
    </div>
  );
}

export default GlobalErrorFallback;
