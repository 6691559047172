import { appPaths } from '@esg/config/app-paths.config';

const oidcConfig = {
  authority: process.env.NEXT_PUBLIC_OIDC_AUTHORITY as string,
  client_id: process.env.NEXT_PUBLIC_OIDC_CLIENT_ID as string,
  redirect_uri: `${
    process.env.NEXT_PUBLIC_NEXT_APP as string
  }${appPaths.getHomePagePath()}`,
  post_logout_redirect_uri: process.env.NEXT_PUBLIC_NEXT_APP as string,
  revokeAccessTokenOnSignout: true,
  revokeTokensOnSignout: true,
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  monitorSession: true,
};
export default oidcConfig;
