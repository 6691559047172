module.exports = {
  hero: {
    fontSize: '96px',
    fontWeight: '700',
    letterSpacing: '',
    lineHeight: '96px',
  },

  headlines: {
    1: {
      fontSize: '64px',
      fontWeight: '700',
      letterSpacing: '',
      lineHeight: '64px',
    },
    2: {
      fontSize: '48px',
      fontWeight: '700',
      letterSpacing: '',
      lineHeight: '56px',
    },
    3: {
      fontSize: '40px',
      fontWeight: '700',
      letterSpacing: '',
      lineHeight: '48px',
    },
    4: {
      fontSize: '32px',
      fontWeight: '700',
      letterSpacing: '',
      lineHeight: '40px',
    },
  },

  bodyTexts: {
    default: {
      fontSize: '16px',
      fontWeight: '400',
      letterSpacing: '',
      lineHeight: '24px',
    },
    large: {
      fontSize: '24px',
      fontWeight: '400',
      letterSpacing: '',
      lineHeight: '32px',
    },
  },

  captions: {
    default: {
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '',
      lineHeight: '24px',
    },
    small: {
      fontSize: '12px',
      fontWeight: '400',
      letterSpacing: '',
      lineHeight: '20px',
    },
  },

  hairlines: {
    large: {
      fontSize: '16px',
      fontWeight: '700',
      letterSpacing: '',
      lineHeight: '16px',
    },
    small: {
      fontSize: '12px',
      fontWeight: '700',
      letterSpacing: '',
      lineHeight: '12px',
    },
  },

  buttons: {
    default: {
      fontSize: '16px',
      fontWeight: '700',
      letterSpacing: '',
      lineHeight: '16px',
    },
    small: {
      fontSize: '14px',
      fontWeight: '700',
      letterSpacing: '',
      lineHeight: '16px',
    },
  },
};
