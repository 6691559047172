import EsgCard from '@esg/shared/components/EsgCard/EsgCard';
import Image from 'next/image';
import { useEffect, useState } from 'react';

function PageLoader({
  text,
  className = '',
  debounceTime = 0,
}: {
  text: string;
  className?: string;
  debounceTime?: number;
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (debounceTime === 0) {
      setShow(true);
      return;
    }
    const timer = setTimeout(() => {
      setShow(true);
    }, debounceTime);

    return () => clearTimeout(timer);
  }, [debounceTime]);

  return (
    <div
      className={`flex h-[100vh] margin-auto flex-col items-center justify-center bg-gradient-to-tl from-supportive-1 via-supportive-2 to-supportive-5 ${className}`}
    >
      <EsgCard
        style={{ opacity: show ? 1 : 0 }}
        variant="BoxShadow"
        className="flex justify-between items-center gap-5 w-[600px] pl-10 transition-opacity ease-in-out"
      >
        <h3 className="animate-bounce w-2/5 text-body-text-large font-bold">
          {text}
        </h3>
        <Image
          src="/config-program.svg"
          width={322}
          height={220}
          alt="Intro loading image"
        />
      </EsgCard>
    </div>
  );
}

export default PageLoader;
