import { Framework, MasterDisclosureTemplate } from '@fb-ui/shared-types';

export const ESRSS18: MasterDisclosureTemplate = {
  meta: {
    id: 'S1-8',
    docType: 'tax-disclosure',
    fileName: 'ESRS_S1-8.tax-disclosure.json',
    framework: 'ESRS' as Framework,
    taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
    taxonomyURI:
      'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
    taxonomyPublicationDate: '2022-11-22',
    disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401080',
    disclosureName:
      '[401080] S1-8 Collective bargaining coverage and social dialogue',
    disclosureSortId: 401080,
    namespaces: [
      {
        prefix: 'esrs',
        namespaceURI: 'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22',
      },
    ],
    dictionaries: [
      {
        id: 'dimensions',
        docType: 'tax-dictionary',
        fileName: 'ESRS_dimensions.tax-dictionary.json',
        framework: 'ESRS' as Framework,
        taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
        taxonomyURI:
          'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
        taxonomyPublicationDate: '2022-11-22',
        libVersion: '0.2.0',
        labels: [],
        namespaces: [],
      },
    ],
    labels: [
      {
        id: 'S1-8_labels',
        docType: 'tax-disclosure-labels',
        fileName: 'ESRS_S1-8_labels.tax-disclosure-labels.json',
        framework: 'ESRS' as Framework,
        taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
        taxonomyURI:
          'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
        disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401080',
        taxonomyPublicationDate: '2022-11-22',
        libVersion: '0.2.0',
      },
    ],
    libVersion: '0.2.0',
    creationTimestamp: '2023-11-13T12:57:45+00:00',
  },
  children: [
    {
      id: 'esrs:DisclosureOfInformationOnExtentToWhichWorkingConditionsAndTermsOfEmploymentOfOwnWorkforceAreDeterminedOrInfluencedByCollectiveBargainingAgreementsAndToExtentToWhichEmployeesAreCoveredInSocialDialogueInEeaAtEstablishmentAndEuropeanLevelExplanatory',
      label:
        'Disclosure of information on extent to which working conditions and terms of employment of own workforce are determined or influenced by collective bargaining agreements and to extent to which employees are covered in social dialogue in EEA at establishment and European level',
      elementType: 'measure',
      dataType: 'textBlock',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
      children: [
        {
          id: 'esrs:PercentageOfEmployeesCoveredByCollectiveBargainingAgreements',
          label:
            'Percentage of employees covered by collective bargaining agreements',
          elementType: 'measure',
          dataType: 'percent',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:CollectiveBargainingCoverageAndSocialDialogueByCountryTable',
          label:
            'Collective bargaining coverage and social dialogue by country',
          elementType: 'table',
          dataType: null,
          dimensions: [
            {
              id: 'esrs:CountryAxis',
              label: 'Country',
              elementType: 'dimensionExplicit',
              dataType: null,
              dictionaryRef: 'dimensions',
              dictionaryKey: 'countryList',
            },
            {
              id: 'esrs:CoverageRateAxis',
              label: 'Coverage rate',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:CoverageRateMember',
                  label: 'Coverage rate',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:CoverageRate0To19PercentMember',
                      label: 'Coverage rate 0 to 19%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:CoverageRate20To39PercentMember',
                      label: 'Coverage rate 20 to 39%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:CoverageRate40To59PercentMember',
                      label: 'Coverage rate 40 to 59%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:CoverageRate60To79PercentMember',
                      label: 'Coverage rate 60 to 79%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:CoverageRate80To100PercentMember',
                      label: 'Coverage rate 80 to 100%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
          ],
          measures: [
            {
              id: 'esrs:EmployeesInCountryEeaCoveredByCollectiveBargainingAgreementsAreWithinCoverageRate',
              label:
                'Employees in country (EEA) covered by collective bargaining agreements are within coverage rate',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:NonemployeeWorkersInOwnWorkforceInCountryEeaWhoseWorkingConditionsAndTermsOfEmploymentAreDeterminedOrInfluencedByCollectiveBargainingAgreementsAreWithinCoverageRate',
              label:
                'Non-employee workers in own workforce in country (EEA) whose working conditions and terms of employment are determined or influenced by collective bargaining agreements are within coverage rate',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
            {
              id: 'esrs:EmployeesInCountryEeaCoveredAtEstablishmentLevelByWorkersRepresentativesAreWithinCoverageRate',
              label:
                'Employees in country (EEA) covered at establishment level by workers’ representatives are within coverage rate',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
          ],
        },
        {
          id: 'esrs:CollectiveBargainingCoverageByRegionTable',
          label: 'Collective bargaining coverage by region',
          elementType: 'table',
          dataType: null,
          dimensions: [
            {
              id: 'esrs:RegionTypedAxis',
              label: 'Region',
              elementType: 'dimensionTyped',
              dataType: 'string',
              isNillable: true,
            },
            {
              id: 'esrs:CoverageRateAxis',
              label: 'Coverage rate',
              elementType: 'dimensionExplicit',
              dataType: null,
              children: [
                {
                  id: 'esrs:CoverageRateMember',
                  label: 'Coverage rate',
                  elementType: 'domainMember',
                  dataType: null,
                  isAbstract: false,
                  children: [
                    {
                      id: 'esrs:CoverageRate0To19PercentMember',
                      label: 'Coverage rate 0 to 19%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:CoverageRate20To39PercentMember',
                      label: 'Coverage rate 20 to 39%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:CoverageRate40To59PercentMember',
                      label: 'Coverage rate 40 to 59%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:CoverageRate60To79PercentMember',
                      label: 'Coverage rate 60 to 79%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                    {
                      id: 'esrs:CoverageRate80To100PercentMember',
                      label: 'Coverage rate 80 to 100%',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                    },
                  ],
                },
              ],
            },
          ],
          measures: [
            {
              id: 'esrs:OwnWorkforceInRegionNon-eeaCoveredByCollectiveBargainingAgreementsAreWithinCoverageRate',
              label:
                'Own workforce in region (non-EEA) covered by collective bargaining agreements are within coverage rate',
              elementType: 'measure',
              dataType: 'boolean',
              isAbstract: false,
              isNillable: true,
              periodType: 'duration',
            },
          ],
        },
        {
          id: 'esrs:WorkingConditionsAndTermsOfEmploymentForEmployeesNotCoveredByCollectiveBargainingAgreementsAreDeterminedBasedOnCollectiveBargainingAgreementsThatCoverOtherEmployees',
          label:
            'Working conditions and terms of employment for employees not covered by collective bargaining agreements are determined based on collective bargaining agreements that cover other employees',
          elementType: 'measure',
          dataType: 'boolean',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:WorkingConditionsAndTermsOfEmploymentForEmployeesNotCoveredByCollectiveBargainingAgreementsAreDeterminedBasedOnCollectiveBargainingAgreementsFromOtherUndertakings',
          label:
            'Working conditions and terms of employment for employees not covered by collective bargaining agreements are determined based on collective bargaining agreements from other undertakings',
          elementType: 'measure',
          dataType: 'boolean',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:DescriptionOfExtentToWhichWorkingConditionsAndTermsOfEmploymentOfNonemployeeWorkersInOwnWorkforceAreDeterminedOrInfluencedByCollectiveBargainingAgreementsExplanatory',
          label:
            'Description of extent to which working conditions and terms of employment of non-employee workers in own workforce are determined or influenced by collective bargaining agreements',
          elementType: 'measure',
          dataType: 'textBlock',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:PercentageOfNonemployeeWorkersInOwnWorkforceWhoseWorkingConditionsAndTermsOfEmploymentAreDeterminedOrInfluencedByCollectiveBargainingAgreements',
          label:
            'Percentage of non-employee workers in own workforce whose working conditions and terms of employment are determined or influenced by collective bargaining agreements',
          elementType: 'measure',
          dataType: 'percent',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:UndertakingHasOneOrMoreCollectiveBargainingAgreementsInEea',
          label:
            'Undertaking has one or more collective bargaining agreements in EEA',
          elementType: 'measure',
          dataType: 'boolean',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
        {
          id: 'esrs:DisclosureOfExistenceOfAnyAgreementWithEmployeesForRepresentationByEuropeanWorksCouncilEwcSocietasEuropaeaSeWorksCouncilOrSocietasCooperativaEuropaeaSceWorksCouncilExplanatory',
          label:
            'Disclosure of existence of any agreement with employees for representation by European Works Council (EWC), Societas Europaea (SE) Works Council, or Societas Cooperativa Europaea (SCE) Works Council',
          elementType: 'measure',
          dataType: 'textBlock',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
        },
      ],
    },
  ],
};
