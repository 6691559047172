import {
  AppPermission,
  CompanySubscriptionStatus,
  FreeSubscriptionTier,
  UserRole,
} from '@fb-ui/shared-types';
import {
  useFlag as defaultUseFlag,
  useFlagsStatus,
  useUnleashClient,
} from '@unleash/proxy-client-react';
import { useMemo } from 'react';

import useAuth from './useAuth';

import { convertToUpperCaseSnakeCase } from '../helpers/string-manipulator';
import { useAppStore } from '../store';
import { getUserPermissions } from '@fb-ui/shared-helpers';

export function usePermission() {
  const { user } = useAuth();
  const { userCompany } = useAppStore();
  const { flagsReady } = useFlagsStatus();
  const unleashClient = useUnleashClient();

  const isFlagEnabled = (permission: AppPermission[]) => {
    if (!flagsReady) {
      return false;
    }
    return permission.some((permission) =>
      unleashClient.isEnabled(String(permission)),
    );
  };

  const useFlag = (permission: AppPermission) =>
    defaultUseFlag(String(permission));

  const userGroups: UserRole[] = useMemo(
    () =>
      user?.profile?.groups?.length
        ? user.profile.groups
            .map((group: string) => {
              if (!group) {
                return;
              }
              return convertToUpperCaseSnakeCase(group);
            })
            .filter((x): x is UserRole => !!x)
        : [],
    [user?.profile.groups, flagsReady],
  );

  const allPermissions: AppPermission[] = useMemo(() => {
    if (!userGroups.length) {
      return [];
    }

    const permissions = getUserPermissions({
      userRoles: userGroups,
      subscriptionPlan:
        userCompany?.subscription?.type || FreeSubscriptionTier.DATA_PROVIDER,
      isSubscriptionActive:
        userCompany?.subscription?.status === CompanySubscriptionStatus.ACTIVE,
    });
    return permissions;
  }, [userGroups, userCompany?.subscription]);

  const hasDefaultPermission = (permissions: AppPermission[]) => {
    const result = allPermissions.length
      ? permissions.some((permission) =>
          allPermissions.some((allPerm) => {
            return allPerm === permission;
          }),
        )
      : false;
    return result;
  };

  const filterPermissionsBasedOnEnvVariable = (): AppPermission[] => {
    const envPermissions = process.env.NEXT_PUBLIC_CHECK_FEATURE_FLAGS;
    if (!envPermissions) {
      return [];
    }
    const envPermissionArray = envPermissions
      .trim()
      .split(',')
      .map((el) => el.trim());

    if (!envPermissionArray.length) {
      return [];
    }

    let result: AppPermission[] = [];
    for (let index = 0; index < envPermissionArray.length; index++) {
      const entry = envPermissionArray[index];

      // Env entry has '*' sign (all feature flags)
      if (entry === '*') {
        result = allPermissions;
        break;
      }
      // Entry consist of * sign
      if (entry.indexOf('*') > 0) {
        const el = entry.split('*')[0];
        const filtered = allPermissions.filter((permission) =>
          permission.startsWith(el),
        );
        result = [...result, ...filtered];
      }

      const isEntryInAllPermissions = allPermissions.find(
        (permission) => permission === entry,
      );
      if (isEntryInAllPermissions) {
        result.push(entry as AppPermission);
      }
    }
    const uniqResult = Array.from(new Set(result));
    return uniqResult.sort();
  };

  const filteredPermissions = useMemo(
    () => filterPermissionsBasedOnEnvVariable(),
    [allPermissions],
  );

  const _shouldCheckFeatureFlag = (permissions: AppPermission[]): boolean => {
    if (!filteredPermissions.length) {
      return false;
    }
    return filteredPermissions.some((filtered) =>
      permissions.some((permission) => permission === filtered),
    );
  };

  const _hasAtLeastOnePermission = (permissions: AppPermission[]): boolean =>
    allPermissions.length
      ? (_shouldCheckFeatureFlag(permissions)
          ? isFlagEnabled(permissions)
          : true) && hasDefaultPermission(permissions)
      : false;

  const hasPermissions = (permissions: AppPermission[]) =>
    _hasAtLeastOnePermission(permissions);

  return {
    filterPermissionsBasedOnEnvVariable,
    hasPermissions,
    hasDefaultPermission,
    userDefaultPermissions: allPermissions,
    isFlagEnabled,
    flagsReady,
    useFlag,
  };
}
