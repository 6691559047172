import {
  ChildDisclosureFlatSection,
  ChildDisclosureTable,
  DisclosureChild,
  DisclosureMeasure,
  DisclosureTableDomainMember,
  DisclosureTableDimensionExplicit,
} from './disclousure-template.type';

export function isChildDisclosureTable(
  obj: DisclosureChild,
): obj is ChildDisclosureTable {
  return obj && obj.elementType === 'table';
}

export function isChildDisclosureFlatSection(
  obj: DisclosureChild,
): obj is ChildDisclosureFlatSection {
  return obj && obj.elementType === 'measure';
}

export function isDisclosureTableDomainMember(
  obj: DisclosureMeasure | DisclosureTableDomainMember,
): obj is DisclosureTableDomainMember {
  return obj && obj.elementType === 'domainMember';
}

export function isDisclosureTableMeasure(
  obj: DisclosureMeasure | DisclosureTableDomainMember,
): obj is DisclosureMeasure {
  return obj && obj.elementType === 'measure';
}

export function isDisclosureTableDimensionExplicit(
  obj: DisclosureMeasure | DisclosureTableDimensionExplicit,
): obj is DisclosureMeasure {
  return obj && obj.elementType === 'dimensionExplicit';
}
