import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// Helper function to debounce
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
const debounce = (fn: (args: any) => void, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: unknown[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fn(...args);
    }, delay);
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useRouteScroll(dependency?: any) {
  const router = useRouter();
  const [initialized, setInitialized] = useState(false);

  // Extract the scroll position from the queryParams
  const scrollX = parseInt(router.query?.scrollX?.toString() || '0', 10);
  const scrollY = parseInt(router.query?.scrollY?.toString() || '0', 10);

  const updateScrollPosition = debounce(() => {
    void router.replace(
      {
        query: {
          ...router.query,
          scrollX: window.scrollX,
          scrollY: window.scrollY,
        },
      },
      undefined,
      { scroll: false },
    );
  }, 100);

  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener('scroll', updateScrollPosition);

    return () => {
      // Detach the event listener when the component unmounts
      window.removeEventListener('scroll', updateScrollPosition);
    };
  }, [router.query]);

  useEffect(() => {
    if (initialized) {
      return;
    }

    const restoreScrollPosition = () => {
      window.scrollTo({
        left: scrollX,
        top: scrollY,
        behavior: 'smooth',
      });
    };

    const timerId = setTimeout(restoreScrollPosition, 300);

    // Cleanup function to clear the timeout
    return () => clearTimeout(timerId);
  }, [initialized, dependency]);

  // Whenever the dependency changes, we'll set initialized to false to allow for re-initialization
  useEffect(() => {
    if (dependency !== undefined) {
      setInitialized(false);
    }
  }, [dependency]);

  return {};
}
