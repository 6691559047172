import { Framework, MasterDisclosureTemplate } from '@fb-ui/shared-types';

// For local dev testing purposes only
export const FAKE_MDT: MasterDisclosureTemplate = {
  meta: {
    id: 'FAKE',
    docType: 'tax-disclosure',
    fileName: 'ESRS_S1-6.tax-disclosure.json',
    framework: Framework.ESRS,
    taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
    taxonomyURI:
      'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
    taxonomyPublicationDate: '2022-11-22',
    disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401060',
    disclosureName: '[401060] S1-6 Characteristics of undertaking’s employees',
    disclosureSortId: 401060,
    namespaces: [
      {
        prefix: 'esrs',
        namespaceURI: 'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22',
      },
    ],
    dictionaries: [
      {
        id: 'dimensions',
        docType: 'tax-dictionary',
        fileName: 'ESRS_dimensions.tax-dictionary.json',
        framework: Framework.ESRS,
        taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
        taxonomyURI:
          'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
        taxonomyPublicationDate: '2022-11-22',
        libVersion: '0.2.0',
        labels: [],
        namespaces: [],
      },
    ],
    labels: [
      {
        id: 'S1-6_labels',
        docType: 'tax-disclosure-labels',
        fileName: 'ESRS_S1-6_labels.tax-disclosure-labels.json',
        framework: Framework.ESRS,
        taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20221122esrsallxsd',
        taxonomyURI:
          'https://xbrl.efrag.org/taxonomy/draft-esrs/2022-11-22/esrs_all.xsd',
        disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401060',
        taxonomyPublicationDate: '2022-11-22',
        libVersion: '0.2.0',
      },
    ],
    libVersion: '0.2.0',
    creationTimestamp: '2023-11-13T12:57:45+00:00',
  },
  children: [
    {
      id: 'esrs:textString',
      label: 'Test string type',
      elementType: 'measure',
      dataType: 'string',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:textBlock',
      label: 'Test boolean type',
      elementType: 'measure',
      dataType: 'textBlock',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:booleanType',
      label: 'Test boolean type',
      elementType: 'measure',
      dataType: 'boolean',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:decimalType',
      label: 'Test decimal type',
      elementType: 'measure',
      dataType: 'decimal',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:areaType',
      label: 'Test area type',
      elementType: 'measure',
      dataType: 'area',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:energyType',
      label: 'Test energy type',
      elementType: 'measure',
      dataType: 'energy',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:monetaryType',
      label: 'Test monetary type',
      elementType: 'measure',
      dataType: 'monetary',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:massType',
      label: 'Test mass type',
      elementType: 'measure',
      dataType: 'mass',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:ghgEmissionsType',
      label: 'Test GHG emissions type',
      elementType: 'measure',
      dataType: 'ghgEmissions',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:volumeType',
      label: 'Test volume type',
      elementType: 'measure',
      dataType: 'volume',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:dateType',
      label: 'Test date type',
      elementType: 'measure',
      dataType: 'date',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    {
      id: 'esrs:yearType',
      label: 'Test year type',
      elementType: 'measure',
      dataType: 'year',
      isAbstract: false,
      isNillable: true,
      periodType: 'duration',
    },
    // {
    //         id: 'esrs:TargetsRelatedToClimateChangeMitigationAndAdaptationGHGEmissionsReductionTargetsTable',
    //   label:
    //     'Targets related to climate change mitigation and adaptation - GHG emissions reduction targets',
    //   elementType: 'table',
    //   dataType: null,
    //   dimensions: [
    //     {
    //       id: 'esrs:ReportingScopeAxis',
    //       label: 'Reporting scope',
    //       elementType: 'dimensionExplicit',
    //       dataType: null,
    //       children: [
    //         {
    //           id: 'esrs:CurrentAndRetrospectiveMember',
    //           label: 'Current and retrospective',
    //           elementType: 'domainMember',
    //           dataType: null,
    //           isAbstract: false,
    //           children: [
    //             {
    //               id: 'esrs:BaselineYearMember',
    //               label: 'Baseline year',
    //               elementType: 'domainMember',
    //               dataType: null,
    //               isAbstract: false,
    //             },
    //             {
    //               id: 'esrs:MilestonesAndTargetYearsMember',
    //               label: 'Milestones and target years',
    //               elementType: 'domainMember',
    //               dataType: null,
    //               isAbstract: false,
    //               children: [
    //                 {
    //                   id: 'esrs:Milestone2025YearMember',
    //                   label: '2025',
    //                   elementType: 'domainMember',
    //                   dataType: null,
    //                   isAbstract: false,
    //                 },
    //                 {
    //                   id: 'esrs:Milestone2030YearMember',
    //                   label: '2030',
    //                   elementType: 'domainMember',
    //                   dataType: null,
    //                   isAbstract: false,
    //                 },
    //                 {
    //                   id: 'esrs:Milestone2035YearMember',
    //                   label: '2035',
    //                   elementType: 'domainMember',
    //                   dataType: null,
    //                   isAbstract: false,
    //                 },
    //                 {
    //                   id: 'esrs:Milestone2040YearMember',
    //                   label: '2040',
    //                   elementType: 'domainMember',
    //                   dataType: null,
    //                   isAbstract: false,
    //                 },
    //                 {
    //                   id: 'esrs:Milestone2045YearMember',
    //                   label: '2045',
    //                   elementType: 'domainMember',
    //                   dataType: null,
    //                   isAbstract: false,
    //                 },
    //                 {
    //                   id: 'esrs:Milestone2050YearMember',
    //                   label: '2050',
    //                   elementType: 'domainMember',
    //                   dataType: null,
    //                   isAbstract: false,
    //                 },
    //               ],
    //             },
    //             {
    //               id: 'esrs:RestatedCorrectedOrRevisedMember',
    //               label: 'Restated, corrected or revised',
    //               elementType: 'domainMember',
    //               dataType: null,
    //               isAbstract: false,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    //   measures: [
    //     {
    //       id: 'esrs:GrossGreenhouseGasEmissions',
    //       label: 'Total Gross greenhouse gas emissions',
    //       labelRole: 'totalLabel',
    //       elementType: 'measure',
    //       dataType: 'ghgEmissions',
    //       isAbstract: false,
    //       isNillable: true,
    //       periodType: 'duration',
    //       children: [
    //         {
    //           id: 'esrs:GrossScope1GreenhouseGasEmissions',
    //           label: 'Gross Scope 1 greenhouse gas emissions',
    //           elementType: 'measure',
    //           dataType: 'ghgEmissions',
    //           isAbstract: false,
    //           isNillable: true,
    //           periodType: 'duration',
    //         },
    //         {
    //           id: 'esrs:GrossLocationBasedScope2GreenhouseGasEmissions',
    //           label: 'Gross location-based Scope 2 greenhouse gas emissions',
    //           elementType: 'measure',
    //           dataType: 'ghgEmissions',
    //           isAbstract: false,
    //           isNillable: true,
    //           periodType: 'duration',
    //         },
    //         {
    //           id: 'esrs:GrossMarketBasedScope2GreenhouseGasEmissions',
    //           label: 'Gross market-based Scope 2 greenhouse gas emissions',
    //           elementType: 'measure',
    //           dataType: 'ghgEmissions',
    //           isAbstract: false,
    //           isNillable: true,
    //           periodType: 'duration',
    //         },
    //         {
    //           id: 'esrs:GrossScope3GreenhouseGasEmissions',
    //           label: 'Gross Scope 3 greenhouse gas emissions',
    //           elementType: 'measure',
    //           dataType: 'ghgEmissions',
    //           isAbstract: false,
    //           isNillable: true,
    //           periodType: 'duration',
    //         },
    //       ],
    //     },
    //   ],
    // }

    // {
    //   id: 'esrs:DiversityMetricsTable',
    //   label: 'Diversity metrics',
    //   elementType: 'table',
    //   dataType: null,
    //   dimensions: [
    //     {
    //       id: 'esrs:ReportingScopeAxis',
    //       label: 'Reporting scope',
    //       elementType: 'dimensionExplicit',
    //       dataType: null,
    //       children: [
    //         {
    //           id: 'esrs:CurrentAndRetrospectiveMember',
    //           label: 'Current and retrospective',
    //           elementType: 'domainMember',
    //           dataType: null,
    //           isAbstract: false,
    //           children: [
    //             {
    //               id: 'esrs:MilestonesAndTargetYearsMember',
    //               label: 'Milestones and target years',
    //               elementType: 'domainMember',
    //               dataType: null,
    //               isAbstract: false,
    //             },
    //             {
    //               id: 'esrs:RestatedCorrectedOrRevisedMember',
    //               label: 'Restated, corrected or revised',
    //               elementType: 'domainMember',
    //               dataType: null,
    //               isAbstract: false,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       id: 'esrs:GenderAxis',
    //       label: 'Gender',
    //       elementType: 'dimensionExplicit',
    //       dataType: null,
    //       children: [
    //         {
    //           id: 'esrs:GenderMember',
    //           label: 'Total / all genders / NA',
    //           labelRole: 'totalLabel',
    //           elementType: 'domainMember',
    //           dataType: null,
    //           isAbstract: false,
    //           children: [
    //             {
    //               id: 'esrs:FemaleMember',
    //               label: 'Female',
    //               elementType: 'domainMember',
    //               dataType: null,
    //               isAbstract: false,
    //             },
    //             {
    //               id: 'esrs:MaleMember',
    //               label: 'Male',
    //               elementType: 'domainMember',
    //               dataType: null,
    //               isAbstract: false,
    //             },
    //             {
    //               id: 'esrs:OtherThanFemaleAndMaleMember',
    //               label: 'Other than female and male',
    //               elementType: 'domainMember',
    //               dataType: null,
    //               isAbstract: false,
    //             },
    //             {
    //               id: 'esrs:GenderNotReportedMember',
    //               label: 'Gender not reported',
    //               elementType: 'domainMember',
    //               dataType: null,
    //               isAbstract: false,
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    //   measures: [
    //     {
    //       id: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
    //       label:
    //         'Number of employees (head count) at top management level, during period',
    //       elementType: 'measure',
    //       dataType: 'integer',
    //       isAbstract: false,
    //       isNillable: true,
    //       periodType: 'duration',
    //     },
    //     {
    //       id: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod',
    //       label:
    //         'Number of employees (head count) at top management level, at end of period',
    //       elementType: 'measure',
    //       dataType: 'integer',
    //       isAbstract: false,
    //       isNillable: true,
    //       periodType: 'instant',
    //     },
    //     {
    //       id: 'esrs:PercentageOfEmployeesAtTopManagementLevel',
    //       label: 'Percentage of employees at top management level',
    //       elementType: 'measure',
    //       dataType: 'percent',
    //       isAbstract: false,
    //       isNillable: true,
    //       periodType: 'duration',
    //     },
    //   ],
    // },

    // {
    //   id: 'esrs:textString',
    //   label: 'Test boolean type',
    //   elementType: 'measure',
    //   dataType: 'string',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:textBlock',
    //   label: 'Test boolean type',
    //   elementType: 'measure',
    //   dataType: 'textBlock',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:booleanType',
    //   label: 'Test boolean type',
    //   elementType: 'measure',
    //   dataType: 'boolean',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:decimalType',
    //   label: 'Test decimal type',
    //   elementType: 'measure',
    //   dataType: 'decimal',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:areaType',
    //   label: 'Test area type',
    //   elementType: 'measure',
    //   dataType: 'area',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:energyType',
    //   label: 'Test energy type',
    //   elementType: 'measure',
    //   dataType: 'energy',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:monetaryType',
    //   label: 'Test monetary type',
    //   elementType: 'measure',
    //   dataType: 'monetary',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:massType',
    //   label: 'Test mass type',
    //   elementType: 'measure',
    //   dataType: 'mass',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:ghgEmissionsType',
    //   label: 'Test GHG emissions type',
    //   elementType: 'measure',
    //   dataType: 'ghgEmissions',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:volumeType',
    //   label: 'Test volume type',
    //   elementType: 'measure',
    //   dataType: 'volume',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:dateType',
    //   label: 'Test date type',
    //   elementType: 'measure',
    //   dataType: 'date',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
    // {
    //   id: 'esrs:yearType',
    //   label: 'Test year type',
    //   elementType: 'measure',
    //   dataType: 'year',
    //   isAbstract: false,
    //   isNillable: true,
    //   periodType: 'duration',
    // },
  ],
};
