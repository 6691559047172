import classNames from 'classnames';
import { CgSpinner } from 'react-icons/cg';

import { ButtonProps, ButtonVariants } from './button-types';
import { FbTooltip } from '@fb/ui-kit';
import { useTranslation } from '@esg/i18n';

function Button({
  variant = ButtonVariants.PRIMARY,
  color = 'primary',
  loading = false,
  underDevelopment = false,
  ...props
}: ButtonProps) {
  const extraClasses = props.className || '';
  const { t } = useTranslation('global');

  const buttonClasses: string = classNames({
    ['rounded-md flex-inline justify-center items-center flex-nowrap text-button-small']:
      true,
    //primary
    'bg-primary text-inverted hover:bg-primary/95 rounded-md':
      variant === ButtonVariants.PRIMARY &&
      color === ButtonVariants.PRIMARY &&
      !props.disabled,

    //supportive-4
    'bg-supportive-4 text-primary-dark hover:bg-supportive-4/95 rounded-md':
      variant === ButtonVariants.SUPPORTIVE_4 &&
      color === ButtonVariants.SUPPORTIVE_4 &&
      !props.disabled,

    // primary rounded
    'rounded-full h-[40px] min-w-[58px] px-5 ': variant !== ButtonVariants.ICON,

    // secondary
    'bg-background text-primary border border-monochrome hover:bg-monochrome-1 hover:bg-monochrome-1/20':
      variant === ButtonVariants.SECONDARY && !props.disabled,

    // Alternative
    'bg-selected text-primary rounded-full border border-monochrome-1':
      variant === ButtonVariants.ALTERNATIVE,

    'bg-transparent rounded-full border-0':
      variant === ButtonVariants.TRANSPARENT,

    'bg-transparent rounded-full border-0 text-primary':
      variant === ButtonVariants.TRANSPARENT && color === 'primary',

    // icon
    'relative h-[48px] w-[48px] flex justify-center items-center':
      variant === ButtonVariants.ICON,

    'border bg-background border-monochrome hover:bg-monochrome/50':
      variant === ButtonVariants.ICON && !props.disabled,

    // disabled
    'bg-monochrome-1 text-primary-dark/50 !border-monochrome':
      props.disabled || variant === ButtonVariants.DESELECTED,

    // shaded
    'bg-[#FEF2F2] border-[#D3D3D3] border-2 text-primary-dark/50 border-0':
      variant === ButtonVariants.SHADED,

    [extraClasses]: true,
  });

  const badgeClasses = classNames({
    'whitespace-nowrap absolute -top-[6px] -right-[6px] text-inverted w-[24px] h-[24px] rounded-full flex justify-center items-center':
      true,
    'bg-monochrome': props.disabled,
    'bg-primary': !props.disabled,
  });

  const propsCopy = { ...props };
  /**
   * This property is being remove to avoid the error "Received true/false for non-boolean attribute"
   */
  delete propsCopy.badge;

  if (underDevelopment) {
    return (
      <FbTooltip
        label={t('viewUnderConstruction.title2')}
        transitionProps={{ duration: 400 }}
      >
        <button disabled className={buttonClasses}>
          <span
            className={
              'flex justify-center items-center flex-nowrap gap-2 whitespace-nowrap opacity-30'
            }
          >
            {props.children}
          </span>
        </button>
      </FbTooltip>
    );
  }

  return (
    <button {...propsCopy} className={buttonClasses}>
      <span
        className={`${
          variant !== 'icon'
            ? 'flex justify-center items-center flex-nowrap gap-2 whitespace-nowrap'
            : ''
        }`}
      >
        {loading && (
          <span>
            <CgSpinner className="animate-spin text-body-text-large" />
          </span>
        )}

        {props.children}

        {variant === 'icon' && !!props.badge ? (
          <span className={badgeClasses}>{props.badge}</span>
        ) : null}
      </span>
    </button>
  );
}

export default Button;
