import { AppUser } from '@fb-ui/shared-types';
import { useAuth as useOriginalAuth } from 'react-oidc-context';

import { useAppStore } from '../store';

// TODO: add proper return type
function useAuth() {
  const auth = useOriginalAuth();

  const { setSigningOut, isSigningOut } = useAppStore();

  const logOut = () => {
    setSigningOut(true);

    void auth
      .removeUser()
      .catch((e) => {
        console.error('Remove user error', e);
        setSigningOut(false);
      })
      .then(() => {
        console.log('Removed user');
      });

    void auth
      .signoutRedirect()
      .catch((e) => {
        console.error('Signout redirect error', e);
        setSigningOut(false);
      })
      .then(() => {
        console.log('Signed out redirect success');
      });
  };

  // Modify or pick specific parts of the auth object
  const transformedAuth = {
    ...auth,
    logOut,
    isSigningOut,
    user: auth?.user as AppUser,
  };

  return transformedAuth;
}

export default useAuth;
