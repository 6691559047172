import i18next from 'i18next';

export interface ITranslation {
  t: (_key: string, _params?: object) => string;
  exists: (_key: string, _params?: object) => boolean;
}

export function useTranslation(namespace: string): ITranslation {
  function t(key: string, params = {}) {
    // Use the given namespace if key doesn't provide one
    if (!key?.includes('::')) {
      return i18next.t(`${namespace}::${key}`, params);
    }

    // Use the key directly if it includes a namespace
    return i18next.t(key);
  }

  function exists(key: string, params = {}) {
    // Use the given namespace if key doesn't provide one
    if (!key?.includes('::')) {
      return i18next.exists(`${namespace}::${key}`, params);
    }
    return i18next.exists(key);
  }

  return { t, exists };
}
