import {
  ROLES_TO_PERMISSIONS_MAPPING,
  EXPIRED_SUBSCRIPTION_TO_PERMISSIONS_MAPPING,
  SUBSCRIPTION_TO_PERMISSIONS_MAPPING,
} from '@fb-ui/shared-configs';
import { AppPermission, SubscriptionPlan, UserRole } from '@fb-ui/shared-types';

export function getUserPermissions({
  userRoles,
  subscriptionPlan,
  isSubscriptionActive,
}: {
  userRoles: UserRole[];
  subscriptionPlan: SubscriptionPlan;
  isSubscriptionActive: boolean;
}): AppPermission[] {
  const subscriptionPlanPermissions = getPermissionsForSubscriptionPlan({
    subscriptionPlan,
    isActive: isSubscriptionActive,
  });

  const userRolePermissions: AppPermission[] =
    getPermissionsForUserRoles(userRoles);

  // Get  permissions from both user roles and subscription plan
  const resultPermissions: AppPermission[] = subscriptionPlanPermissions.filter(
    (subscriptionPermission) => {
      return userRolePermissions.includes(subscriptionPermission);
    },
  );

  return Array.from(new Set(resultPermissions));
}

export function getPermissionsForUserRoles(
  userRoles: UserRole[],
): AppPermission[] {
  return Array.from(
    new Set(userRoles.map((role) => ROLES_TO_PERMISSIONS_MAPPING[role]).flat()),
  );
}

export function getPermissionsForSubscriptionPlan({
  subscriptionPlan,
  isActive,
}: {
  subscriptionPlan: SubscriptionPlan;
  isActive: boolean;
}): AppPermission[] {
  const permissions = !isActive
    ? EXPIRED_SUBSCRIPTION_TO_PERMISSIONS_MAPPING[subscriptionPlan]
    : SUBSCRIPTION_TO_PERMISSIONS_MAPPING[subscriptionPlan];
  return Array.from(new Set(permissions));
}
