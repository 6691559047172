import i18next from 'i18next';
import Link from 'next/link';
import { BiChevronRight } from 'react-icons/bi';

import { appPaths } from '@esg/config/app-paths.config';

import useAuth from '../../hooks/useAuth';
import { Avatar } from '../Avatar/Avatar';

export function UserProfileLink() {
  const { user } = useAuth();
  const { language } = i18next;

  return user ? (
    <Link
      className="flex items-center gap-3 py-4 px-3 border-t border-t-monochrome-1"
      href={`/${language}${appPaths.getProfilePagePath()}`}
    >
      <div>
        <Avatar src="/images/avatars/no-profile-pics.svg" size={38} />
      </div>
      <div className="text-ellipsis grow truncate">
        <h4 className="font-semibold text-captionBold">{user.profile.name}</h4>

        <span className="text-background-500 text-captionRegularSmall">
          {user.profile.email}
        </span>
      </div>

      <BiChevronRight />
    </Link>
  ) : null;
}
