/**
 * This 'appPaths' object should be source of truth for all routing paths.
 *
 * SEO good practice is to use <a> tag or <Link> component for navigation.
 * Therefore, we export functions that returns string paths for such use case.
 *
 */
import { Framework } from '@fb-ui/shared-types';

export const publicAppPaths = {
  getCompanyOnboardingPath: () => '/public/company-onboarding',
};

export const appPaths = {
  getHomePagePath: () => '/auth/dashboard',
  getDashboardPagePath: () => '/auth/dashboard',
  getAnalyticsPagePath: () => '/auth/analytics',
  getMyOrganizationPagePath: () => '/auth/my-organization',
  getMyStrategyPagePath: () => '/auth/my-strategy',
  getMyValueChainPagePath: () => '/auth/my-value-chain',
  getDisclosureDetailsPagePath: (
    programId: string,
    reportId: string,
    disclosureId: string,
  ) =>
    `/auth/my-sustainability/program/${programId}/report/${reportId}/disclosure/${disclosureId}`,
  getMySustainabilityPagePath: () => '/auth/my-sustainability',
  getMySustainabilityOngoingPrograsPagePath: () =>
    '/auth/my-sustainability/#ongoing-programs',
  getMySustainabilityFinishedPrograsPagePath: () =>
    '/auth/my-sustainability/#finished-programs',
  // PROGRAMS
  getNewProgramPagePath: () => '/auth/my-sustainability/program/new',
  getNewStrategyPagePath: () => '/auth/my-strategy/new-strategy',
  getStrategyDetailsPagePath: (strategyId: string) =>
    `/auth/my-strategy/${strategyId}`,
  getStrategyEditPagePath: (strategyId: string) =>
    `/auth/my-strategy/${strategyId}/edit-strategy`,
  getNewObjectiveStrategyPagePath: (strategyId: string) =>
    `/auth/my-strategy/${strategyId}/add-objective`,
  getEditObjectiveDescriptionStrategyPagePath: (
    strategyId: string,
    objectiveId: string,
  ) =>
    `/auth/my-strategy/${strategyId}/edit-objective/edit-description/${objectiveId}`,
  getEditObjectiveTopicsStrategyPagePath: (
    strategyId: string,
    objectiveId: string,
  ) =>
    `/auth/my-strategy/${strategyId}/edit-objective/edit-topics/${objectiveId}`,
  getStrategyObjectivePagePath: (strategyId: string, objectiveId: string) =>
    `/auth/my-strategy/${strategyId}/objectives/${objectiveId}`,
  getStrategyObjectiveAddGoalPagePath: (
    strategyId: string,
    objectiveId: string,
  ) => `/auth/my-strategy/${strategyId}/objectives/${objectiveId}/add-goal`,
  getStrategyObjectiveGoalManageDisclosuresPagePath: (
    strategyId: string,
    objectiveId: string,
    goalId: string,
  ) =>
    `/auth/my-strategy/${strategyId}/objectives/${objectiveId}/goals/${goalId}/manage-disclosures`,
  getStrategyObjectiveEditGoalPagePath: (
    strategyId: string,
    objectiveId: string,
    goalId: string,
  ) =>
    `/auth/my-strategy/${strategyId}/objectives/${objectiveId}/goals/${goalId}/edit-goal`,

  getNewReportPagePath: () => '/auth/my-sustainability/program/new-report',
  getReportPagePath: (programId: string, reportId: string) =>
    `/auth/my-sustainability/program/${programId}/report/${reportId}`,
  getNewDataCollectionPagePath: (companyId: string, disclosureId: string) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/new-data-collection`,
  getAddDisclosureOwner: (companyId: string, disclosureId: string) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/add-disclosure-owner`,
  getNewMaterialityAssessmentPagePath: () =>
    '/auth/materiality-assessment/new-materiality-assessment',
  getNewMaterialityAssessmentDatesPagePath: () =>
    '/auth/materiality-assessment/new-materiality-assessment-dates',
  getProgramDetailsPagePath: (programId: string) => {
    return `/auth/my-sustainability/program/${programId}`;
  },
  getSelectFrameworkForManualDisclosureSelectionPagePath: (
    companyId: string,
  ) => {
    return `/auth/data-management/${companyId}/disclosure/new/select-framework`;
  },
  getManualDisclosureSelectionPagePath: (
    companyId: string,
    framework: Framework,
  ) => {
    return `/auth/data-management/${companyId}/disclosure/new/select-framework/${framework}/add-disclosures`;
  },
  // USER PROFILE PATHS
  getProfilePagePath: () => `/auth/profile`,
  getUserAccountSettingsPagePath: () => '/auth/profile/settings',
  getDisclosureRequestPagePath: () => '/auth/profile/disclosure-request',
  getTodosPagePath: () => '/auth/my-todos/#all',
  getAddSupplierPath: () => '/auth/my-organization/new-supplier',
  getAddSuborganizationPath: () => '/auth/my-organization/new-suborganization',
  getMaterialityAssessmentPagePath: () => '/auth/materiality-assessment',

  getDataManagementPagePath: () => '/auth/data-management',
  getDataManagementCompanyDisclosureListPagePath: (companyId: string) =>
    `/auth/data-management/${companyId}/disclosure`,
  getDataManagementAssignCoordinator: (companyId: string) =>
    `/auth/data-management/${companyId}/disclosure/assign-coordinator`,
  getDataManagementCompanyDisclosureDetailsPagePath: (
    companyId: string,
    disclosureId: string,
  ) => `/auth/data-management/${companyId}/disclosure/${disclosureId}`,
  getDataManagementCompanyDataProvidersDisclosureCollectionPagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/data-providers`,
  getDataManagementCompanyReStructureDisclosureCollectionPagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/re-structure`,
  getDataManagementCompanyAddProvidersDisclosureCollectionPagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/add-providers`,
  getDataManagementCompanyProvideDataDisclosureCollectionPagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provide-data`,

  getDataManagementCompanyProvideDataDisclosureCollectionPackagePagePath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
    collectionPackageId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provide-data/${collectionPackageId}`,

  getDataManagementCompanyProvidedDataDisclosureValuesPath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
    providerId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provider/${providerId}`,

  getDataManagementCompanyProvidedDataDisclosureValuesForPeriodSlotPath: (
    companyId: string,
    disclosureId: string,
    collectionId: string,
    providerId: string,
    periodSlotId: string,
  ) =>
    `/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provider/${providerId}/period-slot/${periodSlotId}`,

  getMaterialityAssessmentDetailsPagePath: (assessmentId: string) =>
    `/auth/materiality-assessment/${assessmentId}`,
  getMaterialityAssessmentTopicDetailsPagePath: (
    assessmentId: string,
    topicId: string,
  ) => `/auth/materiality-assessment/${assessmentId}/topic/${topicId}`,
  getTopicsPagePath: (programId: string) =>
    `/auth/my-sustainability/program/${programId}/disclosures`,
  getMyOrganizationPageTeamMemberPath: () =>
    '/auth/my-organization/#team-members',
  getMyOrganizationPageCompanyInformationPath: () =>
    '/auth/my-organization/#company-information',
  getMyOrganizationPageSubOrganizationTabPath: () =>
    '/auth/my-organization#suborganizations',
  getTeamMemberDetailPagePath: (teamMemberId: string) =>
    `/auth/my-organization/team-members/${teamMemberId}`,
  getEditTeamMemberPagePath: (teamMemberId: string) =>
    `/auth/my-organization/team-members/${teamMemberId}/edit-team-member`,
  getAddNewTeamMemberPagePath: () =>
    `/auth/my-organization/team-members/add-team-member`,
  setAddNewTeamMemberSetupRole: () =>
    `/auth/my-organization/team-members/add-team-member/setup-roles`,
  getMyMyValueChainExternalPartnersTabPath: () =>
    '/auth/my-value-chain/#external-partners',
  // STRATEGY PATHS

  getNewVisionPath: () => `/auth/my-strategy/new-vision`,
  getReviewInvitationPath: () => `/auth/my-todos/#pending-invitations`,
};

type PathDetails = {
  [key: string]: {
    titleKey: string;
  };
};

export const getTitleKeyFromPathname = (pathname: string): string => {
  const adaptedPathname = pathname
    .replace(/\/\[lang\]/, '')
    .replace(/\[/g, '${')
    .replace(/\]/g, '}');
  const pathDetail = appPathDetails[adaptedPathname];
  return pathDetail ? pathDetail.titleKey : 'esgPlatform';
};

export const appPathDetails: PathDetails = {
  '/auth/dashboard': {
    titleKey: 'dashboard',
  },
  '/auth/analytics': {
    titleKey: 'analytics',
  },
  '/auth/my-organization': {
    titleKey: 'myOrganization',
  },
  '/auth/my-strategy': {
    titleKey: 'myStrategy',
  },
  '/auth/my-value-chain': {
    titleKey: 'myValueChain',
  },
  '/auth/my-sustainability/program/${programId}/report/${reportId}/disclosure/${disclosureId}':
    {
      titleKey: 'disclosureDetails',
    },
  '/auth/my-sustainability': {
    titleKey: 'mySustainability',
  },
  '/auth/my-sustainability/#ongoing-programs': {
    titleKey: 'mySustainabilityOngoingPrograms',
  },
  '/auth/my-sustainability/#finished-programs': {
    titleKey: 'mySustainabilityFinishedPrograms',
  },
  '/auth/my-sustainability/program/new': {
    titleKey: 'newProgram',
  },
  '/auth/my-strategy/new-strategy': {
    titleKey: 'newStrategy',
  },
  '/auth/my-strategy/${strategyId}': {
    titleKey: 'strategyDetails',
  },
  '/auth/my-strategy/${strategyId}/edit-strategy': {
    titleKey: 'strategyEdit',
  },
  '/auth/my-strategy/${strategyId}/add-objective': {
    titleKey: 'newObjectiveStrategy',
  },
  '/auth/my-strategy/${strategyId}/edit-objective/edit-topics/${objectiveId}': {
    titleKey: 'strategyObjectiveEditTopic',
  },
  '/auth/my-strategy/${strategyId}/edit-objective/edit-description/${objectiveId}':
    {
      titleKey: 'strategyObjectiveEdit',
    },
  '/auth/my-strategy/${strategyId}/objectives/${objectiveId}': {
    titleKey: 'strategyObjectiveDetails',
  },
  '/auth/my-strategy/${strategyId}/objectives/${objectiveId}/goals/${goalId}/manage-disclosures':
    {
      titleKey: 'strategyGoalsManageDisclosures',
    },
  '/auth/my-sustainability/program/new-report': {
    titleKey: 'newReport',
  },
  '/auth/my-sustainability/program/${programId}/report/${reportId}': {
    titleKey: 'reportPage',
  },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}/new-data-collection':
    {
      titleKey: 'newDataCollection',
    },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}/add-disclosure-owner':
    {
      titleKey: 'addDisclosureOwner',
    },
  '/auth/materiality-assessment/new-materiality-assessment': {
    titleKey: 'newMaterialityAssessment',
  },
  '/auth/materiality-assessment/new-materiality-assessment-dates': {
    titleKey: 'newMaterialityAssessment',
  },
  '/auth/my-sustainability/program/${programId}': {
    titleKey: 'programDetails',
  },
  '/auth/data-management/${companyId}/disclosure/new/select-framework': {
    titleKey: 'selectFrameworkForManualDisclosureSelection',
  },
  '/auth/data-management/${companyId}/disclosure/new/select-framework/${framework}/add-disclosures':
    {
      titleKey: 'manualDisclosureSelection',
    },
  '/auth/profile': {
    titleKey: 'profile',
  },
  '/auth/profile/settings': {
    titleKey: 'userAccountSettings',
  },
  '/auth/profile/disclosure-request': {
    titleKey: 'disclosureRequest',
  },
  '/auth/my-todos': {
    titleKey: 'todos',
  },
  '/auth/my-organization/new-supplier': {
    titleKey: 'addSupplier',
  },
  '/auth/my-organization/new-suborganization': {
    titleKey: 'addSuborganization',
  },
  '/auth/my-value-chain/new-partner': {
    titleKey: 'addPartner',
  },
  '/auth/materiality-assessment': {
    titleKey: 'materialityAssessment',
  },
  '/auth/data-management': {
    titleKey: 'dataManagement',
  },
  '/auth/data-management/${companyId}/disclosure': {
    titleKey: 'dataManagementCompanyDisclosureList',
  },
  '/auth/data-management/${companyId}/disclosure/assign-coordinator': {
    titleKey: 'dataManagementAssignCoordinator',
  },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}': {
    titleKey: 'dataManagementCompanyDisclosureDetails',
  },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/data-providers':
    {
      titleKey: 'dataManagementCompanyDataProvidersDisclosureCollection',
    },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/re-structure':
    {
      titleKey: 'dataManagementCompanyReStructureDisclosureCollection',
    },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/add-providers':
    {
      titleKey: 'dataManagementCompanyAddProvidersDisclosureCollection',
    },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provide-data':
    {
      titleKey: 'dataManagementCompanyProvideDataDisclosureCollection',
    },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provide-data/${collectionPackageId}':
    {
      titleKey: 'dataManagementCompanyProvideDataDisclosureCollectionPackage',
    },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provider/${providerId}':
    {
      titleKey: 'dataManagementCompanyProvidedDataDisclosureValues',
    },
  '/auth/data-management/${companyId}/disclosure/${disclosureId}/collection/${collectionId}/provider/${providerId}/period-slot/${periodSlotId}':
    {
      titleKey:
        'dataManagementCompanyProvidedDataDisclosureValuesForPeriodSlot',
    },
  '/auth/materiality-assessment/${assessmentId}': {
    titleKey: 'materialityAssessmentDetails',
  },
  '/auth/materiality-assessment/${assessmentId}/topic/${topicId}': {
    titleKey: 'materialityAssessmentTopicDetails',
  },
  '/auth/my-sustainability/program/${programId}/disclosures': {
    titleKey: 'programDisclosures',
  },
  '/auth/my-organization/#team-members': {
    titleKey: 'teamMembers',
  },
  '/auth/my-organization/#company-information': {
    titleKey: 'companyInformation',
  },
  '/auth/my-organization#suborganizations': {
    titleKey: 'subOrganizations',
  },
  '/auth/my-organization/team-members/${teamMemberId}': {
    titleKey: 'teamMemberDetail',
  },
  '/auth/my-organization/team-members/${teamMemberId}/edit-team-member': {
    titleKey: 'editTeamMember',
  },
  '/auth/my-organization/team-members/add-team-member': {
    titleKey: 'addNewTeamMember',
  },
  '/auth/my-organization/team-members/add-team-member/setup-roles': {
    titleKey: 'newTeamMemberRoles',
  },
  '/auth/my-strategy/new-vision': {
    titleKey: 'newVision',
  },
  '/auth/my-todos/#pending-invitations({count})': {
    titleKey: 'reviewInvitations',
  },
};

export const pathsWithoutAppShell = [
  'program/new',
  'new-materiality-assessment',
  'new-supplier',
  'new-suborganization',
  'add-disclosures',
  'select-framework',
  'new-data-collection',
  'data-providers',
  'auth/assign-coordinator',
  'add-disclosure-owner',
  'edit-team-member',
  'add-team-member',
  'new-strategy',
  'edit-strategy',
  'auth/my-strategy/new-vision',
  'add-objective',
  'edit-objective',
  'add-goal',
  'edit-goal',
  'manage-disclosures',
];
