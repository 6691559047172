import ActivityItemSkeleton from '../ActivityItemSkeleton/ActivityItemSkeleton';

function AsideSkeleton({ amount = 5 }: { amount?: number }) {
  return (
    <div className="flex flex-col gap-5">
      {''
        .padEnd(amount, 'a')
        .split('')
        .map((_, index) => (
          <ActivityItemSkeleton key={index} />
        ))}
    </div>
  );
}

export default AsideSkeleton;
