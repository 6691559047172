import { AppPermission } from '@fb-ui/shared-types';
import { HTMLAttributes, ReactElement, ReactNode } from 'react';

import { usePermission } from '@esg/shared/hooks/usePermission';

interface PermissionWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  appPermission: AppPermission;
  checkFlag?: boolean;
  fallback?: ReactElement | null;
}

const PermissionWrapper = ({
  children,
  appPermission,
  checkFlag = true,
  fallback = null,
  ...props
}: PermissionWrapperProps) => {
  const { hasPermissions, hasDefaultPermission } = usePermission();

  return (
    checkFlag
      ? hasPermissions([appPermission])
      : hasDefaultPermission([appPermission])
  ) ? (
    <div {...props}>{children}</div>
  ) : (
    fallback
  );
};

export default PermissionWrapper;
