import i18next, { Callback } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { defaultLanguage, languages, namespaces } from './config';

// Load all locales
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const locales = Object.assign(
  {},
  ...Object.keys(languages).map((index: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const language: string = languages[Number(index)];

    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [language]: Object.assign(
        {},
        ...Object.keys(namespaces).map((index) => {
          const namespace: string = namespaces[Number(index)];
          return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            [namespace]: require(`../../public/locales/${language}/${namespace}.json`),
          };
        }),
      ),
    };
  }),
);

const detection = {
  // Order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],

  // Keys or params to lookup language from
  lookupCookie: 'lng',
  lookupLocalStorage: 'lng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // Cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // Optional set cookie options, reference: MDN Set-Cookie docs, https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie
  cookieOptions: { path: '/', sameSite: 'strict' },
};

export const i18NextInit = i18next.use(LanguageDetector).init({
  detection: detection,
  fallbackLng: defaultLanguage,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  resources: locales,
  ns: namespaces,
  defaultNS: 'common',
  nsSeparator: '::',
  returnObjects: true,
  debug: false,
  interpolation: {
    escapeValue: false, // Not needed for React
  },
  react: {
    wait: true,
  },
} as unknown as Callback);

export function getAllLanguageSlugs() {
  return languages.map((lang) => {
    return { params: { lang: lang } };
  });
}

export function getLanguage(lang: string): string {
  return languages.includes(lang) ? lang : defaultLanguage;
}

export function isPathContainsLang(path: string): boolean {
  const slug = path.split('/')[1];
  if (!slug) {
    return false;
  }
  return languages.includes(slug);
}
