import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useRouteSearch } from './useRouteSearch';

import getNestedValue from '../helpers/nestedObjectPath';

export interface UseTableHalpersProps<DataType> {
  data?: DataType[];
  filterBy?: string;
  sortKey?: string;
}

export function useTableHelpers<DataType>(
  props?: UseTableHalpersProps<DataType>,
) {
  const data: DataType[] = props?.data ? props.data : [];
  const router = useRouter();
  const sortKey = router.query?.sortKey?.toString() || props?.sortKey || '';
  const sortOrder = router.query?.sortOrder === 'desc' ? 'desc' : 'asc'; // default to 'asc'
  const filterTerm: string = (router.query?.frameworkFilter as string) || '';

  const { searchTerm, updateSearchTerm } = useRouteSearch();
  const updateSort = (key: string) => {
    const order = sortKey === key && sortOrder === 'asc' ? 'desc' : 'asc';
    void router.replace({
      pathname: router.pathname,
      query: { ...router.query, sortKey: key, sortOrder: order },
    });
  };

  const updateFilter = (filter: string) => {
    void router.replace({
      pathname: router.pathname,
      query: { ...router.query, frameworkFilter: filter },
    });
  };

  const filteredData = useMemo(() => {
    const filtered = data.filter((row: DataType) => {
      const rowValues: string[] = Object.values(row as ArrayLike<unknown>).map(
        (el) => JSON.stringify(el),
      );
      return searchTerm
        ? rowValues.some(
            (val) =>
              val?.toString()?.toLowerCase().includes(searchTerm.toLowerCase()),
          )
        : true;
    });

    if (props?.filterBy) {
      const filterBy: string = props.filterBy;

      return filtered.filter((el: DataType) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        filterTerm ? filterTerm === (el as any)[filterBy] : true,
      );
    }
    return filtered;
  }, [data, searchTerm, filterTerm, props?.filterBy]);

  const sortedFilteredData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      const aValue = getNestedValue(a as object, sortKey);
      const bValue = getNestedValue(b as object, sortKey);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (aValue < bValue) return sortOrder === 'desc' ? -1 : 1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (aValue > bValue) return sortOrder === 'desc' ? 1 : -1;
      return 0;
    });
  }, [filteredData, sortKey, sortOrder]);

  return {
    updateSort,
    updateSearchTerm,
    updateFrameworkFilter: updateFilter,
    sortKey,
    sortOrder,
    searchTerm,
    filterTerm,
    filteredData,
    sortedFilteredData,
  };
}
