/* eslint-disable no-unused-vars */
export enum DisclosureStatus {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
}

export enum ReportStatus {
  NEW = 'New',
  SAVED_REQUESTED = 'Requested',
  SAVED_IN_PROGRESS = 'In Progress',
  SAVED = 'Saved',
}

export enum ProgramStatus {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  OVER_DUE = 'Overdue',
  FINISHED = 'Finished',
}

export enum ProgramListType {
  ONGOING = 'Ongoing',
  FINISHED = 'Finished',
}

export enum Framework {
  ESRS = 'ESRS',
  GRI = 'GRI',
}
