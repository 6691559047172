import {
  DatePickerInput,
  DatePickerInputProps,
  DatePickerType,
} from '@mantine/dates';

// Make FbDatePickerInputProps generic, forwarding the generic parameter to DatePickerInputProps
export type FbDatePickerInputProps<Type extends DatePickerType = 'default'> =
  Partial<DatePickerInputProps<Type>>;

// Adjust FbDatePickerInput to be a generic React component that accepts FbDatePickerInputProps with the Type parameter
export function FbDatePickerInput<Type extends DatePickerType = 'default'>(
  props: FbDatePickerInputProps<Type>,
) {
  return <DatePickerInput {...props} />;
}
