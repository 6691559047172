import { StateCreator } from 'zustand';

export interface AppAsideState {
  isButtonActive: boolean;
  toggleButton: () => void;
}

export const appAsideSlice: StateCreator<AppAsideState> = (set) => ({
  isButtonActive: false,
  toggleButton: () =>
    set((state) => ({ isButtonActive: !state.isButtonActive })),
});
