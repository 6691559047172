import {
  NumberInput,
  NumberInputProps,
  NumberInputHandlers,
} from '@mantine/core';

export type FbNumberInputProps = Partial<NumberInputProps>;

export type FbNumberInputHandlers = NumberInputHandlers;

export function FbNumberInput(props: FbNumberInputProps) {
  return <NumberInput {...props}>{props.children}</NumberInput>;
}
