import { StateCreator } from 'zustand';

export interface DashboardPageState {
  dashboardPageState: {
    isEnabled: boolean;
    setIsEnabled: (_isEnabled: boolean) => void;
  };
}

export const dashboardPageSlice: StateCreator<
  DashboardPageState,
  [],
  [],
  DashboardPageState
> = (set) => ({
  dashboardPageState: {
    isEnabled: false,
    setIsEnabled: (isEnabled: boolean) => {
      set((state) => ({
        ...state,
        dashboardPageState: {
          ...state.dashboardPageState,
          isEnabled,
        },
      }));
    },
  },
});
