import { SVGProps } from 'react';

function BuildingsIcon({
  width = 30,
  height = 30,
  viewBox = '0 0 30 30',
  fill = '#595470',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" {...props}>
      <g clipPath="url(#clip0_5283_2559)">
        <path
          d="M21.1075 10.5057H19.4158V12.2557H21.1075V10.5057Z"
          fill={fill}
        />
        <path
          d="M21.1075 14.0057H19.4158V15.7557H21.1075V14.0057Z"
          fill={fill}
        />
        <path
          d="M21.1075 17.5057H19.4158V19.2557H21.1075V17.5057Z"
          fill={fill}
        />
        <path
          d="M5.88245 14.0057V22.7557H10.9575V18.3807H12.6491V22.7557H17.7241V14.0057L11.8033 9.63068L5.88245 14.0057ZM16.0325 21.0057H14.3408V16.6307H9.26579V21.0057H7.57412V14.8807L11.8033 11.8182L16.0325 14.8807V21.0057Z"
          fill={fill}
        />
        <path
          d="M13.495 7.00568V8.72943L15.1866 9.98068V8.75568H22.7991V21.0057H19.4158V22.7557H24.4908V7.00568H13.495Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5283_2559">
          <rect
            width="20.3"
            height="21"
            fill="white"
            transform="translate(5.03662 4.38068)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BuildingsIcon;
