/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  AnalyticsPermissions,
  CompanyProfilePermissions,
  DataCollectionPermissions,
  DashboardPermissions,
  MaterialityAssessmentPermissions,
  PlatformAdminPermissions,
  ProgramPermissions,
  ReportPermissions,
  StrategyPermissions,
  UserProfilePermissions,
  UserRole,
  AppPermission,
  BillingPermissions,
  EcosystemKYCPermissions,
  NotificationPermissions,
  SupportedFramework,
} from '@fb-ui/shared-types';

const DATA_COLLECTION_WIDGET = [DashboardPermissions.WIDGET_DATA_COLLECTION];

const MATERIALITY_ASSESSMENT_WIDGET = [
  DashboardPermissions.WIDGET_OWN_CONNECTED_ASSESSMENT,
];

const PROGRAM_WIDGET = [
  DashboardPermissions.WIDGET_OWN_PROGRAMS_FINISHED_COUNTER,
  DashboardPermissions.WIDGET_OWN_PROGRAMS_ONGOING_COUNTER,
];

const STRATEGY_WIDGETS = [
  DashboardPermissions.WIDGET_COMPANY_VISION,
  DashboardPermissions.WIDGET_STRATEGIES_COUNTER,
];

const COMMON_PERMISSIONS = [
  CompanyProfilePermissions.READ_EXTERNAL_PARTNER_LIST,
  CompanyProfilePermissions.READ_SUBORGANIZATION_LIST,
  NotificationPermissions.READ,
  DataCollectionPermissions.READ_DIS_DP,
  DataCollectionPermissions.READ_DIS_DO,
  MaterialityAssessmentPermissions.READ_AO,
  SupportedFramework.ESRS,
  DashboardPermissions.WIDGET_CONFIGURE_OWN_COMPANY_PROFILE_INTRO,
  DashboardPermissions.WIDGET_OWN_CONNECTED_COMPANY,
  DashboardPermissions.WIDGET_OWN_TEAM_MEMBERS,
  DashboardPermissions.WIDGET_SUB_ORGANIZATION_COUNTER,
  DashboardPermissions.WIDGET_CERTIFICATION_INTRO,
  DashboardPermissions.WIDGET_UPCOMING_DEADLINES,
  DashboardPermissions.WIDGET_MY_TODO,
];

export const ROLES_TO_PERMISSIONS_MAPPING: Record<UserRole, AppPermission[]> = {
  [UserRole.COMPANY_ADMIN]: [
    CompanyProfilePermissions.ADD_USERS_TO_COMPANY,
    CompanyProfilePermissions.ADD_SUBORGANIZATIONS,
    CompanyProfilePermissions.ADD_EXISTING_SUBORGANIZATIONS,
    CompanyProfilePermissions.ADD_SUPPLIERS,
    CompanyProfilePermissions.ADD_EXISTING_SUPPLIERS,
    CompanyProfilePermissions.READ_AUDIT_LOGS,
    CompanyProfilePermissions.ADD_USERS_TO_USER_GROUP,
    CompanyProfilePermissions.REMOVE_USERS_FROM_USER_GROUP,
    CompanyProfilePermissions.EDIT_KYC_INFORMATION,
    CompanyProfilePermissions.EDIT_PROFILE,
    CompanyProfilePermissions.EDIT_DATA_SHARING_AGREEMENT,
    CompanyProfilePermissions.REVIEW_INVITATION,
    DataCollectionPermissions.READ_DIS,
    DataCollectionPermissions.READ,
    DataCollectionPermissions.READ_PACKAGE,
    DataCollectionPermissions.READ_DCL,
    DataCollectionPermissions.READ_COLL_STRUCT,
    DataCollectionPermissions.READ_ADJ_AGR_DATA,
    ReportPermissions.READ,
    MaterialityAssessmentPermissions.READ,
    AnalyticsPermissions.READ_ADMIN_ANALYTICS,
    AnalyticsPermissions.READ_USER_ANALYTICS,
    UserProfilePermissions.CREATE,
    UserProfilePermissions.READ,
    UserProfilePermissions.ACCEPT_UPDATE,
    UserProfilePermissions.DELETE,
    DashboardPermissions.READ,
    BillingPermissions.read,
    StrategyPermissions.READ,
    StrategyPermissions.READ_VISION,
    EcosystemKYCPermissions.ADD_TO_ORGANIZATION,
    EcosystemKYCPermissions.ADD_TO_VALUE_CHAIN,
    ...COMMON_PERMISSIONS,
  ],
  [UserRole.COMPANY_PROGRAM_OWNER]: [
    ProgramPermissions.CREATE,
    ProgramPermissions.READ,
    ProgramPermissions.UPDATE,
    ProgramPermissions.TERMINATE,
    DataCollectionPermissions.READ,
    DataCollectionPermissions.READ_PACKAGE,
    DataCollectionPermissions.READ_DCL,
    DataCollectionPermissions.READ_DIS,
    DataCollectionPermissions.READ_COLL_STRUCT,
    DataCollectionPermissions.READ_ADJ_AGR_DATA,
    ReportPermissions.READ,
    MaterialityAssessmentPermissions.READ,
    UserProfilePermissions.READ,
    StrategyPermissions.READ_VISION,
    DashboardPermissions.READ,
    ...PROGRAM_WIDGET,
    ...COMMON_PERMISSIONS,
  ],
  [UserRole.COMPANY_DATA_MANAGEMENT_COORDINATOR]: [
    DataCollectionPermissions.SEND_REQUEST_TO_DISCLOSURE_OWNER,
    DataCollectionPermissions.READ,
    DataCollectionPermissions.READ_PACKAGE,
    DataCollectionPermissions.READ_DCL,
    DataCollectionPermissions.CREATE_DIS,
    DataCollectionPermissions.READ_DIS,
    DataCollectionPermissions.READ_COLL_STRUCT,
    DataCollectionPermissions.READ_ADJ_AGR_DATA,
    ReportPermissions.READ,
    UserProfilePermissions.READ,
    MaterialityAssessmentPermissions.READ,
    StrategyPermissions.READ_VISION,
    DashboardPermissions.READ,
    ...DATA_COLLECTION_WIDGET,
    ...COMMON_PERMISSIONS,
  ],
  [UserRole.COMPANY_REPORT_OWNER]: [
    ReportPermissions.CREATE,
    ReportPermissions.READ,
    ReportPermissions.ADD_DISCLOSURES,
    ReportPermissions.SEND_REQUEST_TO_DISCLOSURE_OWNER,
    ReportPermissions.SUBMIT,
    ReportPermissions.EDIT_IXBRL,
    ReportPermissions.REPORT_CONSUMPTION,
    UserProfilePermissions.READ,
    MaterialityAssessmentPermissions.READ,
    MaterialityAssessmentPermissions.CREATE,
    StrategyPermissions.READ_VISION,
    DashboardPermissions.READ,
    ...COMMON_PERMISSIONS,
  ],
  [UserRole.COMPANY_DISCLOSURE_OWNER]: [
    DataCollectionPermissions.CREATE,
    DataCollectionPermissions.ASSIGN_DATA_PROVIDER,
    DataCollectionPermissions.SEND_REQUEST,
    DataCollectionPermissions.READ,
    DataCollectionPermissions.READ_PACKAGE,
    DataCollectionPermissions.READ_DCL,
    DataCollectionPermissions.READ_DIS,
    DataCollectionPermissions.CANCEL_REQUEST,
    DataCollectionPermissions.SUBMIT_PREPARATION,
    DataCollectionPermissions.SUBMIT,
    DataCollectionPermissions.ACCEPT_REJECT_REQUEST,
    DataCollectionPermissions.APPROVE_REJECT_DATA,
    DataCollectionPermissions.SUBMIT_ADJUSTED_DATA,
    DataCollectionPermissions.READ_COLL_STRUCT,
    DataCollectionPermissions.READ_ADJ_AGR_DATA,
    UserProfilePermissions.READ,
    CompanyProfilePermissions.ADD_EXISTING_SUBORGANIZATIONS,
    CompanyProfilePermissions.ADD_EXISTING_SUPPLIERS,
    MaterialityAssessmentPermissions.READ,
    StrategyPermissions.READ_VISION,
    DashboardPermissions.READ,
    DashboardPermissions.WIDGET_ASSIGNED_DISCLOSURES,
    ...DATA_COLLECTION_WIDGET,
    ...COMMON_PERMISSIONS,
  ],
  [UserRole.COMPANY_MATERIALITY_ASSESSMENT_OWNER]: [
    StrategyPermissions.READ_VISION,
    MaterialityAssessmentPermissions.CREATE,
    MaterialityAssessmentPermissions.FILL_ASSESSMENT,
    MaterialityAssessmentPermissions.READ,
    MaterialityAssessmentPermissions.FINALIZE,
    UserProfilePermissions.READ,
    DashboardPermissions.READ,
    ...MATERIALITY_ASSESSMENT_WIDGET,
    ...COMMON_PERMISSIONS,
  ],
  [UserRole.COMPANY_STRATEGY_OWNER]: [
    StrategyPermissions.CREATE,
    StrategyPermissions.READ_VISION,
    StrategyPermissions.LINK_DISCLOSURES,
    StrategyPermissions.READ,
    StrategyPermissions.READ_VISION,
    StrategyPermissions.PUBLISH,
    StrategyPermissions.ARCHIVE,
    UserProfilePermissions.READ,
    DashboardPermissions.READ,
    MaterialityAssessmentPermissions.READ,
    ...COMMON_PERMISSIONS,
    ...STRATEGY_WIDGETS,
  ],
  [UserRole.COMPANY_DATA_PROVIDER]: [
    DataCollectionPermissions.FILL_DATA,
    DataCollectionPermissions.SUBMIT_DATA,
    DataCollectionPermissions.READ_PACKAGE,
    DataCollectionPermissions.READ_DCL,
    DataCollectionPermissions.READ_DIS_DP,
    DataCollectionPermissions.READ_COLL_STRUCT,
    MaterialityAssessmentPermissions.READ,
    UserProfilePermissions.READ,
    ...COMMON_PERMISSIONS,
  ],
  [UserRole.COMPANY_SUSTAINABILITY_APPROVER]: [
    CompanyProfilePermissions.READ_AUDIT_LOGS,
    ProgramPermissions.READ,
    DataCollectionPermissions.READ,
    DataCollectionPermissions.READ_DIS,
    DataCollectionPermissions.READ_ADJ_AGR_DATA,
    DataCollectionPermissions.READ_COLL_STRUCT,
    DataCollectionPermissions.READ_PACKAGE,
    DataCollectionPermissions.READ_DCL,
    ReportPermissions.READ,
    MaterialityAssessmentPermissions.READ,
    StrategyPermissions.READ,
    StrategyPermissions.CREATE_VISION,
    StrategyPermissions.READ_VISION,
    DashboardPermissions.READ,
    ...COMMON_PERMISSIONS,
    ...DATA_COLLECTION_WIDGET,
    ...STRATEGY_WIDGETS,
  ],
  [UserRole.COMPANY_USER]: [UserProfilePermissions.READ, ...COMMON_PERMISSIONS],
  [UserRole.ESG_DATA_CONSUMER]: [...COMMON_PERMISSIONS],
  [UserRole.PLATFORM_ADMIN]: [
    PlatformAdminPermissions.READ,
    PlatformAdminPermissions.APPROVE_REQUEST_FOR_INTEREST,
    PlatformAdminPermissions.APPROVE_KYC,
    PlatformAdminPermissions.BLOCK_COMPANY,
    PlatformAdminPermissions.READ_AUDIT_LOG,
    ...COMMON_PERMISSIONS,
  ],
  [UserRole.PLATFORM_ADMIN_READ]: [...COMMON_PERMISSIONS],
  [UserRole.AUDITOR_ADMIN]: [...COMMON_PERMISSIONS],
  [UserRole.AUDITOR_USER]: [...COMMON_PERMISSIONS],
  [UserRole.KYC_USER]: [],
};
