import { SVGProps } from 'react';

function DashboardIcon({
  width = 30,
  height = 30,
  viewBox = '0 0 30 30',
  fill = '#595470',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" {...props}>
      <path
        d="M21.1075 7.00568H9.26579C8.33538 7.00568 7.57413 7.79318 7.57413 8.75568V21.0057C7.57413 21.9682 8.33538 22.7557 9.26579 22.7557H21.1075C22.0379 22.7557 22.7991 21.9682 22.7991 21.0057V8.75568C22.7991 7.79318 22.0379 7.00568 21.1075 7.00568ZM9.26579 21.0057V8.75568H14.3408V21.0057H9.26579ZM21.1075 21.0057H16.0325V14.8807H21.1075V21.0057ZM21.1075 13.1307H16.0325V8.75568H21.1075V13.1307Z"
        fill={fill}
      />
    </svg>
  );
}

export default DashboardIcon;
