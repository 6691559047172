import {
  MasterDisclosureTemplate,
  DisclosureDataType,
  isChildDisclosureTable,
  isChildDisclosureFlatSection,
  DisclosureChild,
  ChildDisclosureTable,
  DisclosureMeasure,
  DomainMemberRef,
} from '@fb-ui/shared-types';
import {
  getAllMeasuresFlatten,
  getDisclosureTableCellIdFromIds,
  getLeafDomainMembers,
} from './disclosure.helpers';

export function createIdToDataTypeMap(
  _template: MasterDisclosureTemplate,
): Record<string, DisclosureDataType> {
  const idToDataTypeMap: Record<string, DisclosureDataType> = {};

  function processChildren(children: DisclosureChild[] | undefined) {
    if (!children) return;

    for (const child of children) {
      if (isChildDisclosureFlatSection(child)) {
        // if the child is a flatSection and has a dataType: we add it to idToDataTypeMap
        if (child.dataType) {
          idToDataTypeMap[child.id] = child.dataType;
        }
        // and process children
        processChildren(child.children);
      } else if (isChildDisclosureTable(child)) {
        const data = getCellsDataTypeFromTable(child);

        data.forEach((cell) => {
          idToDataTypeMap[cell.key] = cell.dataType;
        });
      }
    }
  }

  processChildren(_template.children);

  return idToDataTypeMap;
}

export interface DisclosureCombination {
  tableId: string;
  measure: DisclosureMeasure;
  domainMembers: DomainMemberRef[];
}

const getCellsDataTypeFromTable = (
  table: ChildDisclosureTable,
): { key: string; dataType: DisclosureDataType }[] => {
  const combinations = [] as DisclosureCombination[];
  generateCombinations(table, (comb) => combinations.push(comb));

  return combinations.map((d) => ({
    key: getDisclosureTableCellIdFromIds({
      tableId: d.tableId,
      measureId: d.measure.id,
      domainMembersRef: d.domainMembers,
    }),
    dataType: d.measure.dataType as DisclosureDataType,
  }));
};

function generateCombinations(
  table: ChildDisclosureTable,
  combinationValueCallback: (_combination: DisclosureCombination) => void,
): void {
  const dimensions = [...table.dimensions];

  const measuresFlatten = getAllMeasuresFlatten(table.measures);

  measuresFlatten.forEach((measure) => {
    combineMeasureWithDimensions(
      table.id,
      measure,
      dimensions,
      [],
      combinationValueCallback,
    );
  });
}

function combineMeasureWithDimensions(
  tableId: string,
  measure: any,
  remainingDimensions: any[],
  prefix: any[],
  combinationValueCallback: (_result: any) => void,
): void {
  if (remainingDimensions.length === 0) {
    combinationValueCallback({
      tableId,
      measure,
      domainMembers: prefix,
    });
    return;
  }

  const [currentDimension, ...nextRemainingDimensions] = remainingDimensions;
  const leafDomainMembers = getLeafDomainMembers(currentDimension.children);

  leafDomainMembers.forEach((leafDomainMember) => {
    combineMeasureWithDimensions(
      tableId,
      measure,
      nextRemainingDimensions,
      prefix.concat({
        ...leafDomainMember,
        dimensionId: currentDimension.id,
        domainMemberId: leafDomainMember.id,
      }),
      combinationValueCallback,
    );
  });
}
