import { useRouter } from 'next/router';

export function useRouteSearch() {
  const router = useRouter();

  const searchTerm = router.query?.searchTerm?.toString() || '';

  const updateSearchTerm = (term: string) => {
    void router.replace(
      {
        query: { ...router.query, searchTerm: term },
      },
      undefined,
      { scroll: false },
    );
  };

  return {
    updateSearchTerm,
    searchTerm,
  };
}
