import { AppShell } from '@mantine/core';
import { ReactElement, ReactNode } from 'react';

export type FbHocAppShellProps = {
  navBar: ReactElement;
  aside: ReactElement;
  appContent: ReactNode;
  header: ReactElement;
  navHiddenBreakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  customPadding?: string;
};

export function FbHocAppShell({
  navBar,
  aside,
  appContent,
  header,
  customPadding = '',
  navHiddenBreakpoint,
}: FbHocAppShellProps) {
  return (
    <AppShell
      styles={{ main: { maxWidth: 1920, paddingRight: customPadding } }}
      navbarOffsetBreakpoint={navHiddenBreakpoint}
      navbar={navBar}
      aside={aside}
      header={header}
    >
      {appContent}
    </AppShell>
  );
}
