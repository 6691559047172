import { GlobalPermissions } from '@fb-ui/shared-types';
import classNames from 'classnames';
import { MdCloseFullscreen, MdOpenInFull } from 'react-icons/md';

import { useTranslation } from '@esg/i18n';
import AuditLogView from '@esg/views/AuditLog/AuditLog';

import MessagesButton from './MessagesButton/MessagesButton';
import NotificationsMenu from './NotificationsMenu/NotificationsMenu';
import { AppAsideProps } from './types';

import PermissionWrapper from '../PermissionWrapper/PermissionWrapper';

export default function AppAside({ isOpen, openFunc }: AppAsideProps) {
  const { t } = useTranslation('aside');

  const asideClass = classNames({
    'h-full px-5 max-w-[18.125rem]': true,
    'max-w-[calc(120rem-18.125rem)]': isOpen,
  });

  return (
    <div className={asideClass}>
      <PermissionWrapper appPermission={GlobalPermissions.underConstruction}>
        <div className="flex justify-end mt-6">
          <div className="inline-flex items-center gap-1 bg-background rounded-full px-2 py-0.5">
            <NotificationsMenu />
            <div>
              <MessagesButton />
            </div>
          </div>
        </div>
      </PermissionWrapper>
      <div className="mt-24">
        <div className="flex items-center justify-between">
          <h4 className="text-body-text font-bold">{t('activity')}</h4>

          <button
            className={`flex items-center text-primary`}
            onClick={() => openFunc(!isOpen)}
          >
            {isOpen ? (
              <>
                <MdCloseFullscreen className="mr-1.5" />
                <span className="font-bold">{t('hideAll')}</span>
              </>
            ) : (
              <>
                <MdOpenInFull className="mr-1.5" />
                <span className="font-bold">{t('seeAll')}</span>
              </>
            )}
          </button>
        </div>
      </div>

      <main>
        <AuditLogView isOpen={isOpen} openFunc={openFunc}></AuditLogView>
      </main>
    </div>
  );
}
