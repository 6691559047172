import { FbTabsPanel } from '@fb/ui-kit';
import React from 'react';
import { BsDot } from 'react-icons/bs';

import { reportData } from './ReportTab.mock';

const ReportTab = () => {
  return (
    <FbTabsPanel
      value="reports"
      pr="sm"
      className="pr-0 notification-tab-content"
      data-cy={`tab-content-0`}
    >
      {reportData.map((elem, index) => {
        return (
          <div
            className={` notification-card flex items-start pt-6 hover:bg-background-100 rounded-lg pr-6
            ${elem.status == 'read' ? 'pl-10' : ''}`}
            key={index}
          >
            {elem.status == 'unread' && (
              <BsDot className="w-12 h-12 flex-none text-accent" />
            )}
            {elem.icon && (
              <span className="notification-icon w-12 h-12 flex-none rounded-full font-semibold tracking-wider bg-supportive2-light flex items-center justify-center">
                {elem.icon}
              </span>
            )}
            <div className="notification-card-body grow pl-4 border-b border-gray-100 pb-6">
              {elem.notificationInfo && (
                <p className="text-linkRegular">{elem.notificationInfo}</p>
              )}
              {elem.date && (
                <span className="time text-gray-500 text-dateIndication mt-3 block">
                  {elem.date}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </FbTabsPanel>
  );
};

export default ReportTab;
