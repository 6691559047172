import { useRouter } from 'next/router';

export function useRouteAccordionState() {
  const router = useRouter();

  // We can use a comma-separated list to represent open accordion items in the queryParams.
  const openAccordionItems =
    router.query?.openAccordionItems?.toString().split(',') || [];

  const setOpenAccordionItems = (ids: string[]) => {
    void router.replace(
      {
        query: { ...router.query, openAccordionItems: ids.join(',') },
      },
      undefined,
      { scroll: false },
    );
  };

  const toggleAccordionItem = (id: string) => {
    if (openAccordionItems.includes(id)) {
      const newOpenItems = openAccordionItems.filter((item) => item !== id);
      setOpenAccordionItems(newOpenItems);
    } else {
      setOpenAccordionItems([...openAccordionItems, id]);
    }
  };

  return {
    openAccordionItems: openAccordionItems,
    toggleAccordionItem: toggleAccordionItem,
    setOpenAccordionItems,
  };
}
