// Handle / Transmit Errors to FE
/* eslint-disable no-unused-vars */
export enum ErrorCodes {
  DISCLOSURE_NOT_FOUND = 'DISCLOSURE_NOT_FOUND',
  DATA_COL_NOT_FOUND = 'DATA_COL_NOT_FOUND',
  AGG_ADJ_NOT_FOUND = 'AGG_ADJ_NOT_FOUND',
  DISCL_STRUCT_NOT_FOUND = 'DISCL_STRUCT_NOT_FOUND',
  DATA_COL_PCK_NOT_FOUND = 'DATA_COL_PCK_NOT_FOUND',
  DATA_COL_PCK_HISTORY_NOT_FOUND = 'DATA_COL_PCK_HISTORY_NOT_FOUND',
  COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
  ASSESSMENT_NOT_FOUND = 'ASSESSMENT_NOT_FOUND',
  AUDIT_NOT_FOUND = 'AUDIT_NOT_FOUND',
  STRATEGY_NOT_FOUND = 'STRATEGY_NOT_FOUND',
  VISION_NOT_FOUND = 'VISION_NOT_FOUND',
  GOAL_NOT_FOUND = 'GOAL_NOT_FOUND',
  OBJECTIVE_NOT_FOUND = 'OBJECTIVE_NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  INVALID_TOKEN = 'INVALID_TOKEN',
  BAD_REQUEST = 'BAD_REQUEST',
  USER_EXISTS = 'USER_EXISTS',
  ADMIN_USER_EXISTS = 'ADMIN_USER_EXISTS',
  COMPANY_EXISTS = 'COMPANY_EXISTS',
  CHILD_COMPANY_EXISTS = 'CHILD_COMPANY_EXISTS',
  INPUT_VALIDATION_ERROR = 'INPUT_VALIDATION_ERROR',
  USER_ALREADY_INVITED = 'USER_ALREADY_INVITED',
  COMPANY_ID_MISMATCH = 'COMPANY_ID_MISMATCH',
  PASSWORD_POLICY_VIOLATION = 'PASSWORD_POLICY_VIOLATION',
  PACKAGE_CREATION_FAILED = 'PACKAGE_CREATION_FAILED',
  COMPANY_ALREADY_INVITED = 'COMPANY_ALREADY_INVITED',
  COMPANY_ALREADY_ADDED = 'COMPANY_ALREADY_ADDED',
  STRATEGY_ALREADY_PUBLISHED = 'STRATEGY_ALREADY_PUBLISHED',
  STRATEGY_ALREADY_ARCHIVED = 'STRATEGY_ALREADY_ARCHIVED',
  SAME_COMPANY_ERROR = 'SAME_COMPANY_ERROR',
  CONNECTION_AMBIGUITY_ERROR = 'CONNECTION_AMBIGUITY_ERROR',
  DATA_MISMATCH = 'DATA_MISMATCH',
  BLOB_DOWNLOAD_ERROR = 'BLOB_DOWNLOAD_ERROR',
}

interface ValidationConstraint {
  name: string;
  message: string;
}
interface ValidationError {
  field: string;
  constraints?: ValidationConstraint[];
  childErrors?: ValidationError[];
}

export interface BackEndError<T> {
  code: ErrorCodes;
  message?: string;
  validationErrors?: ValidationError[];
  error?: T;
}
