/* eslint-disable no-unused-vars */
import { ThemeComponent } from './mantiine-types';

const TableStylesProps: ThemeComponent = {
  defaultProps: {
    highlightOnHover: true,
    horizontalSpacing: 'md',
    verticalSpacing: 'md',
  },
};

export default TableStylesProps;
