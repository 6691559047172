import { Popover, PopoverProps } from '@mantine/core';

type FbPopoverProps = Partial<PopoverProps>;

export const FbPopover: React.FC<FbPopoverProps> = ({ children, ...props }) => {
  return <Popover {...props}>{children}</Popover>;
};

export const FbPopoverTarget = Popover.Target;
export const FbPopoverDropdown = Popover.Dropdown;
