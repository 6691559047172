/**
 * These colors definition servers as an interface between tailwind and figma's project
 */
module.exports = {
  primary: {
    dark: '#0D0D0D',
    default: '#391EA6',
  },

  secondary: '#595959',

  // state
  selected: '#F7F5FF',
  transparent: 'transparent',

  // status
  error: '#BA3C13',
  alert: '#8C5B3E',
  success: '#1C803A',

  gradient: {
    blueLight: '#7D46D7',
    blueDark: '#483DCA',
    yellowLight: '#ffefd3',
  },

  // gray scale
  gray: {
    medium: '#A7ABB0',
    light: '#DADCDE',
  },

  semantic: {
    teal: '#EDFBFC',
    green: '#E3FCEB',
    fuchsia: '#FFF5FB',
    red: '#FFF5F2',
    purple: '#F1EEFE',
    blue: '#F0F7FF',
    yellow: '#FCF8DE',
    lightGreen: '#EBFFF0',
    darkGreen: '#4CAD65',
    dark: {
      teal: '#037C8C',
      green: '#387D00',
      fuchsia: '#C21D83',
      red: '#CF2D2D',
      purple: '#6E4CF5',
      blue: '#0E6FCF',
      yellow: '#806C00',
      pink: '#D80084',
    },
  },

  white: '#FFFFFF',
};
