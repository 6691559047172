import {
  CellId,
  DimensionId,
  DomainMemberId,
  DomainMemberRef,
  MeasureId,
  TableId,
} from '../disclosure.interface';
import { DisclosureDataType } from './disclosure-data-type.type';
import { DisclosureTemplateMeta } from './disclosure-meta.type';
import { DisclosurePeriodType } from './disclosure-period.type';

export interface MasterDisclosureTemplate {
  meta: MasterDisclosureTemplateMeta;
  children: DisclosureChild[];
}

export type DisclosureChild = ChildDisclosureTable | ChildDisclosureFlatSection;

export interface ChildCommon {
  id: string;
  label: string;
  elementType: DisclosureElementType;
  labelRole?: 'totalLabel';
}
export type DisclosureElementType =
  | 'table'
  | 'measure'
  | 'dimensionExplicit'
  | 'dimensionTyped'
  | 'domainMember';

export type MasterDisclosureTemplateMeta = DisclosureTemplateMeta;

export interface ChildDisclosureFlatSection extends ChildCommon {
  isAbstract: boolean;
  dataType: DisclosureDataType | null;
  isNillable?: boolean;
  periodType?: DisclosurePeriodType;
  children?: (ChildDisclosureFlatSection | ChildDisclosureTable)[];
  isCustom?: boolean;
}

export interface ChildDisclosureTable extends ChildCommon {
  dataType: null;
  dimensions: DisclosureDimension[];
  measures: DisclosureMeasure[];
}

export type DisclosureDimensionElementType =
  | 'dimensionExplicit'
  | 'dimensionTyped';

export interface DisclosureDimension {
  id: string;
  label: string;
  elementType: DisclosureDimensionElementType;
  dataType: DisclosureDataType | null;
  children?: DisclosureTableDomainMember[];
  isNillable?: boolean;
  dictionaryRef?: string;
  dictionaryKey?: string;
}

export interface DisclosureMeasure {
  id: string;
  label: string;
  elementType: 'measure';
  dataType: DisclosureDataType;
  isAbstract: false;
  isNillable?: boolean;
  periodType?: DisclosurePeriodType;
  labelRole?: 'totalLabel';
  isCalculated?: boolean;
  children?: (DisclosureMeasure | DisclosureTableDomainMember)[];
}

export interface DisclosureTableDomainMember {
  id: string;
  label: string;
  elementType: 'domainMember';
  labelRole?: 'totalLabel';
  dataType: null;
  isAbstract: false;
  children?: DisclosureTableDomainMember[];
  isCustom?: boolean;
  isUserCustom?: boolean;
  isCalculated?: boolean;
}

export interface DisclosureTableDimensionExplicit {
  id: string;
  label: string;
  elementType: 'dimensionExplicit';
  labelRole?: 'totalLabel';
  dataType: null;
  children?: DisclosureTableDomainMember[];
  isCustom?: boolean;
  isUserCustom?: boolean;
  isCalculated?: boolean;
}

export interface SelectOptionValue {
  id: string;
  value: string;
}

export type DisclosureFormValue =
  | string
  | string[]
  | number
  | boolean
  | Date
  | undefined;

export type DisclosureAttributeType = DisclosureDataType;

export interface FormulaOperand {
  reference: MeasureId | CellId | CellIdObj;
}

export interface DimensionIdObj {
  dimensionId: DimensionId;
  domainMemberIds: DomainMemberId[]; // Take all domain members or only specific ones
}

export interface CellIdObj {
  tableId: TableId;
  measureId: MeasureId;
  dimensionIds: DimensionIdObj[];
}
/**
 * If measureId is provided, the formula will be calculated for the measure.
 * If dimensionIds is provided, the formula will be calculated for the specific domain members.
 * If both measureId and dimensionIds are provided, the formula will be calculated for the specific domain members of the measure.
 * If neither measureId nor dimensionIds are provided, the formula will be calculated for the specified cellIds provided in oprands.
 */
export interface CalculationSimpleOperand {
  tableId?: TableId;
  measureId?: MeasureId;
  dimensionIds?: DimensionIdObj[];
  cellId?: CellIdObj;
}
export type CalculationOperand = CalculationSimpleOperand | CalculationFormula;

export function isCalculationOperandSimple(
  operand: CalculationOperand,
): operand is CalculationSimpleOperand {
  return !(operand as CalculationFormula).operation;
}

export function isCalculationOperandFormulaBased(
  operand: CalculationOperand,
): operand is CalculationFormula {
  return !!(operand as CalculationFormula).operation;
}

interface MultipleSelectorsOptions {
  /**
   * If true, the formula will be populated to multiple table cells for selected measure.
   * This option should be used when one formula should be applied to multiple cells.
   */
  hasMultipleSelectors: boolean;
  /**
   * If speciiied with the hasMultipleSelectors, the formula will populated only to the domain members that are not in the list.
   */
  excludedDomainMembers?: DomainMemberRef[];
  /**
   * If speciiied with the hasMultipleSelectors, the formula will be populated only to the domain members that are in the list.
   */
  domainMembers?: DomainMemberRef[];
}

/**
 * Calculate the percentage of the operands
 */
export type CalculationFormulaPercent = {
  operation: 'PERCENT';
  operands: [CalculationOperand, CalculationOperand];
  options?: {
    multipleSelectors?: MultipleSelectorsOptions;
  };
};

/**
 * Sum the values of the operands
 */
export type CalculationFormulaSum = {
  operation: 'SUM';
  operands: CalculationOperand[];
  options?: {
    /**
     * If true, the formula will be calculated for the list of cells in the measure.
     * It should be used eg. to sum the all values of the selected measure.
     */
    isArrayOperation?: boolean;
    multipleSelectors?: MultipleSelectorsOptions;
  };
};

/**
 * Round the result of the calculation
 */
export type CalculationFormulaRound = {
  operation: 'ROUND';
  operands: [CalculationOperand];
  options?: {
    precision?: number;
    multipleSelectors?: MultipleSelectorsOptions;
  };
};

/**
 * Run mutliple calculations formulas, higher order formula, used for spliting the calculation
 * eg. in order to be able to apply different calculation logic for different domain members by using uniq
 * domainMembers or excludedDomainMembers
 */
export type CalculationMultipleFormulas = {
  operation: 'MULTIPLE_FORMULAS';
  operands: CalculationOperand[];
  options?: {
    multipleSelectors?: MultipleSelectorsOptions;
  };
};

export type CalculationFormula =
  | CalculationFormulaSum
  | CalculationFormulaPercent
  | CalculationFormulaRound
  | CalculationMultipleFormulas;
