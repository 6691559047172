/* eslint-disable no-unused-vars */
import { ThemeComponent } from './mantiine-types';

import * as colors from '../../../colors';
import * as fonts from '../../../fonts';

const BreadcrumbsStylesProps: ThemeComponent = {
  styles: {
    breadcrumb: {
      fontSize: fonts.captions.default.fontSize,
      lineHeight: fonts.captions.default.lineHeight,
      color: colors.secondary,
    },
  },
};

export default BreadcrumbsStylesProps;
