import {
  DisclosureMeasure,
  DisclosureTableDomainMember,
  DomainMemberRef,
  MeasureId,
  TableId,
} from '@fb-ui/shared-types';

export const DIMENSION_DOMAIN_MEMBER_SEPARATOR = ';';
export const DIMENSION_SEPARATOR = ';;';
export const MEASURE_DIMENSION_SEPARATOR = '$$';
export const TABLE_MEASURE_SEPARATOR = '##';

export const getDisclosureTableCellIdFromIds = ({
  tableId,
  measureId,
  domainMembersRef,
}: {
  tableId: TableId;
  measureId: MeasureId;
  domainMembersRef: DomainMemberRef[];
}): string => {
  const domainMembersSufix = getDimensionStringSufix(
    sortDomainMembersRef(domainMembersRef),
  );

  return `${tableId}${TABLE_MEASURE_SEPARATOR}${measureId}${MEASURE_DIMENSION_SEPARATOR}${domainMembersSufix}`;
};

export function getDimensionStringSufix(
  domainMembersRef: DomainMemberRef[],
): string {
  if (!domainMembersRef.length) {
    return '';
  }
  return sortDomainMembersRef(domainMembersRef)
    .map((dm) => {
      return `${dm.dimensionId}${DIMENSION_DOMAIN_MEMBER_SEPARATOR}${dm.domainMemberId}`;
    })
    .join(DIMENSION_SEPARATOR);
}

function sortDomainMembersRef(domainMembersRef: any[]): any[] {
  return domainMembersRef.sort((a, b) => {
    return a.dimensionId.localeCompare(b.dimensionId, 'en');
  });
}

// Helper function to get all measures as a flat array
export function getAllMeasuresFlatten(
  measures: (DisclosureMeasure | DisclosureTableDomainMember)[],
): (DisclosureMeasure | DisclosureTableDomainMember)[] {
  let leaves: any[] = [];
  measures.forEach(
    (member: DisclosureMeasure | DisclosureTableDomainMember) => {
      if (!member.children || member.children.length === 0) {
        leaves.push(member);
      } else {
        leaves.push(member);
        leaves = leaves.concat(getAllMeasuresFlatten(member.children));
      }
    },
  );
  return leaves;
}

// Helper function to get all leaf domain members from a dimension
export function getLeafDomainMembers(
  domainMembers: DisclosureTableDomainMember[],
): DisclosureTableDomainMember[] {
  let leaves: DisclosureTableDomainMember[] = [];
  domainMembers.forEach((member) => {
    if (!member.children || member.children.length === 0) {
      leaves.push(member);
    } else {
      leaves = leaves.concat(getLeafDomainMembers(member.children));
    }
  });

  return leaves;
}
