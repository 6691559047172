/* eslint-disable no-unused-vars */
import { Framework } from '@fb-ui/shared-types';
import { StateCreator } from 'zustand';

import {
  NewAssessmentDatesForm,
  NewAssessmentForm,
} from '@esg/views/NewMaterialityView/types';

export interface MaterialityCreationState {
  materialityCreationFormData: NewAssessmentForm;
  setMaterialityCreationFormData: (formData: NewAssessmentForm) => void;
  setMaterialityCreationDatesFormData: (
    datesFormData: NewAssessmentDatesForm,
  ) => void;
  resetMaterialityCreationFormData: () => void;
}

export const materialityCreationSlice: StateCreator<
  MaterialityCreationState
> = (set) => ({
  materialityCreationFormData: {
    assessmentName: '',
    framework: Framework.ESRS,
    startDate: null,
    endDate: null,
  },
  setMaterialityCreationFormData: (materialityCreationFormData) =>
    set({ materialityCreationFormData }),
  resetMaterialityCreationFormData: () =>
    set({
      materialityCreationFormData: {
        assessmentName: '',
        framework: Framework.ESRS,
        startDate: null,
        endDate: null,
      },
    }),
  setMaterialityCreationDatesFormData: (datesFormData) =>
    set((state) => ({
      materialityCreationFormData: {
        ...state.materialityCreationFormData,
        assessmentName: datesFormData.assessmentName,
        startDate: datesFormData.startDate,
        endDate: datesFormData.endDate,
      },
    })),
});
