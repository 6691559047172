import i18next from 'i18next';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BsCheck2Circle } from 'react-icons/bs';

import { appPaths } from '@esg/config/app-paths.config';
import { useTranslation } from '@esg/i18n';
import { usePermission } from '@esg/shared/hooks/usePermission';

import { RouteConfig, RouteLinkState } from './AppNavBar.types';
import AnalyticsIcon from './icons/Analytics';
import BuildingsIcon from './icons/Building';
import ChainValueIcon from './icons/ChainValue';
import DashboardIcon from './icons/Dashboard';
import DataManagementIcon from './icons/DataManagement';
import EcoHandsIcon from './icons/EcoHands';
import MaterialityAssessmentIcon from './icons/MaterialityAssessmentIcon';
import StrategyIcon from './icons/StrategyIcon';
import { UserProfileLink } from './UserProfileLink';

const routeConfigs: RouteConfig[] = [
  {
    key: 'dashboard',
    path: appPaths.getDashboardPagePath(),
    renderIcon: (color: string) => <DashboardIcon fill={color} />,
    textClasses: '',
    navItemClasses: '',
  },
  {
    key: 'mySustainability',
    path: appPaths.getMySustainabilityPagePath(),
    renderIcon: (color: string) => <EcoHandsIcon fill={color} />,
    textClasses: '',
    navItemClasses: '',
  },
  {
    key: 'myOrganization',
    path: appPaths.getMyOrganizationPagePath(),
    renderIcon: (color: string) => <BuildingsIcon fill={color} />,
    textClasses: '',
    navItemClasses: '',
  },
  {
    key: 'myStrategy',
    path: appPaths.getMyStrategyPagePath(),
    renderIcon: (color: string) => <StrategyIcon fill={color} />,
    textClasses: '',
    navItemClasses: '',
  },
  {
    key: 'materialityAssessment',
    path: appPaths.getMaterialityAssessmentPagePath(),
    renderIcon: (color: string) => <MaterialityAssessmentIcon fill={color} />,
    textClasses: '',
    navItemClasses: '',
  },
  {
    key: 'dataManagement',
    path: appPaths.getDataManagementPagePath(),
    renderIcon: (color: string) => <DataManagementIcon fill={color} />,
    textClasses: '',
    navItemClasses: '',
  },
  {
    key: 'myValueChain',
    path: appPaths.getMyValueChainPagePath(),
    renderIcon: (color: string) => <ChainValueIcon fill={color} />,
    textClasses: '',
    navItemClasses: '',
  },
  {
    key: 'analytics',
    path: appPaths.getAnalyticsPagePath(),
    renderIcon: (color: string) => <AnalyticsIcon fill={color} />,
    textClasses: '',
    navItemClasses: '',
  },
  {
    key: 'todos',
    path: appPaths.getTodosPagePath(),
    renderIcon: (color: string) => <BsCheck2Circle fill={color} size={20} />,
    textClasses: '',
    navItemClasses: 'border-t border-t-monochrome-1',
  },
];

export default function AppNavBar() {
  const { t } = useTranslation('app-nav-bar');
  const { language } = i18next;
  const pathname = usePathname();
  const { hasDefaultPermission } = usePermission();
  const [routeState, setRouteState] = useState<RouteLinkState[]>([]);

  // this is intended to avoid i18next to skip it on extraction script
  const linksTranslationsValues: { [key: string]: string } = useMemo(
    (): { [key: string]: string } => ({
      analytics: t('link.analytics'),
      dashboard: t('link.dashboard'),
      myOrganization: t('link.myOrganization'),
      mySustainability: t('link.mySustainabilityPrograms'),
      dataManagement: t('link.dataManagement'),
      settings: t('link.settings'),
      todos: t('link.todos'),
      materialityAssessment: t('link.materialityAssessment'),
      myStrategy: t('link.myStrategy'),
      myValueChain: t('link.myValueChain'),
    }),
    [t],
  );

  const getFullPath = useCallback(
    (routeConfig: RouteConfig) => {
      return `/${language}${routeConfig.path}`;
    },
    [language],
  );

  const isActive = useCallback(
    (routeConfig: RouteConfig) => {
      const path = getFullPath(routeConfig);
      return !!pathname?.startsWith(path);
    },
    [getFullPath, pathname],
  );

  useEffect(() => {
    setRouteState(
      routeConfigs
        .map((routeConfig) => ({
          ...routeConfig,
          fullPath: getFullPath(routeConfig),
          isActive: isActive(routeConfig),
        }))
        .filter((routeConfig) => {
          if (!routeConfig.appPermission) {
            return true;
          }
          return hasDefaultPermission([routeConfig.appPermission]);
        }),
    );
  }, [pathname, getFullPath, isActive]);

  return (
    <div className="flex flex-col h-full">
      <div className="mb-4 mt-5 px-4">
        <Image
          src="/images/fujitsu-logo.png"
          alt="logo"
          width="100"
          height="49"
          className="text-h4"
        />
      </div>

      <div className="grow mt-3">
        {routeState.map((routeStateProps) => {
          const { fullPath, isActive, key } = routeStateProps;
          const { navItemClasses, textClasses, renderIcon } = routeStateProps;

          const textColor = isActive ? 'text-primary' : 'text-secondary';
          const activeBgColor = isActive ? 'bg-selected' : '';
          const iconColor = isActive ? '#5037B5' : '#595470';

          const todosCount = 0;

          return (
            <Link
              className={`py-2 px-2.5 flex items-center hover:bg-selected hover:text-primary ${textColor} ${navItemClasses} ${activeBgColor}`}
              key={`nav-bar-route-${key}`}
              href={fullPath}
            >
              <span
                className={`w-[30px] h-[30px] flex items-center justify-center mr-2.5 ${textColor}`}
              >
                {renderIcon(iconColor)}
              </span>

              <span
                className={`font-sans text-body-text font-bold ${textClasses}`}
              >
                {linksTranslationsValues[routeStateProps.key]}
              </span>

              {key === 'todos' && todosCount > 0 && (
                <span className="bg-selected border border-monochrome-1 rounded-full p-2 ml-2.5 text-hairline-small">
                  {todosCount}
                </span>
              )}
            </Link>
          );
        })}
      </div>
      <div>
        <UserProfileLink></UserProfileLink>
      </div>
    </div>
  );
}
