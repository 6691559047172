/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApolloError } from '@apollo/client';
import { BackEndError, ICompany } from '@fb-ui/shared-types';
import { useEffect, useState } from 'react';

import { useTranslation } from '@esg/i18n';

export interface ErrorApiHandlerProps {
  error?: ApolloError | string;
  isTransparent?: boolean;
}

export function ErrorApiHandler({
  error,
  isTransparent = false,
}: ErrorApiHandlerProps) {
  const { t, exists } = useTranslation('global');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const convertErrorString = (errorString: string) => {
    if (errorString) {
      try {
        const errorObject: BackEndError<ICompany> = JSON.parse(errorString);
        if (exists(`error.${errorObject.code}`)) {
          setErrorMessage(t(`error.${errorObject.code}`));
        } else {
          setErrorMessage(t(`error.something`));
        }
      } catch (error) {
        setErrorMessage(t(`error.something`));
      }
    }
  };

  useEffect(() => {
    if (error) {
      let errorString = '';

      if (typeof error === 'string') {
        // Try splitting by 'failure:' first
        let splitErrorString = error.split('failure:').pop();
        if (!splitErrorString) {
          // If splitting by 'failure:' doesn't work, try splitting by 'Error:'
          splitErrorString = error.split('Error:').pop();
        }

        if (splitErrorString) {
          const jsonStartIndex = splitErrorString.indexOf('{');
          if (jsonStartIndex !== -1) {
            errorString = splitErrorString.substring(jsonStartIndex);
          }
        }
      } else {
        const apolloErrorObject = error as ApolloError;
        // Try splitting by 'failure:' first
        let splitErrorString = apolloErrorObject?.message
          .split('failure:')
          .pop();
        if (!splitErrorString) {
          // If splitting by 'failure:' doesn't work, try splitting by 'Error:'
          splitErrorString = apolloErrorObject?.message.split('Error:').pop();
        }

        if (splitErrorString) {
          const jsonStartIndex = splitErrorString.indexOf('{');
          if (jsonStartIndex !== -1) {
            errorString = splitErrorString.substring(jsonStartIndex);
          }
        }
      }

      convertErrorString(errorString);
    }
  }, [error]);

  if (!error) {
    return null;
  }

  console.log(error);

  return (
    <div
      className={`mb-4 mt-4 rounded-lg px-6 py-5 text-base justify-center items-center flex flex-col ${
        !isTransparent ? ' bg-supportive-3' : ''
      }`}
      role="alert"
    >
      <div className="font-bold text-error">
        {errorMessage ? (
          <div className="font-bold  text-error text-center">
            {errorMessage}
          </div>
        ) : (
          <div className="font-bold  text-error text-center">
            {t(`error.something`)}
            <div className="text-caption mt-2">{t('error.refresh')}</div>
          </div>
        )}
      </div>
    </div>
  );
}
