import { SVGProps } from 'react';

function AnalyticsIcon({
  width = 30,
  height = 30,
  viewBox = '0 0 30 30',
  fill = '#595470',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" {...props}>
      <path
        d="M21.1075 7.00568H9.26579C8.33538 7.00568 7.57413 7.79318 7.57413 8.75568V21.0057C7.57413 21.9682 8.33538 22.7557 9.26579 22.7557H21.1075C22.0379 22.7557 22.7991 21.9682 22.7991 21.0057V8.75568C22.7991 7.79318 22.0379 7.00568 21.1075 7.00568ZM21.1075 21.0057H9.26579V8.75568H21.1075V21.0057Z"
        fill={fill}
      />
      <path d="M12.6491 14.8807H10.9575V19.2557H12.6491V14.8807Z" fill={fill} />
      <path d="M19.4158 10.5057H17.7241V19.2557H19.4158V10.5057Z" fill={fill} />
      <path d="M16.0325 16.6307H14.3408V19.2557H16.0325V16.6307Z" fill={fill} />
      <path d="M16.0325 13.1307H14.3408V14.8807H16.0325V13.1307Z" fill={fill} />
    </svg>
  );
}

export default AnalyticsIcon;
