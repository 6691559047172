import { StateCreator } from 'zustand';

export interface ChatPageState {
  chatPageState: {
    isEnabled: boolean;
    setIsEnabled: (_isEnabled: boolean) => void;
  };
}

export const chatPageSlice: StateCreator<
  ChatPageState,
  [],
  [],
  ChatPageState
> = (set) => ({
  chatPageState: {
    isEnabled: false,
    setIsEnabled: (isEnabled: boolean) => {
      set((state) => ({
        ...state,
        chatPageState: {
          ...state.chatPageState,
          isEnabled,
        },
      }));
    },
  },
});
