import {
  TabProps,
  Tabs,
  TabsListProps,
  TabsPanelProps,
  TabsProps,
} from '@mantine/core';

export type FbTabsProps = TabsProps;
export type FbTabsListProps = TabsListProps;
export type FbTabProps = TabProps;
export type FbTabsPanelProps = TabsPanelProps;

export function FbTabs(props: FbTabsProps) {
  return <Tabs {...props}>{props.children}</Tabs>;
}

export function FbTabsTab(props: FbTabProps) {
  return <Tabs.Tab {...props}>{props.children}</Tabs.Tab>;
}

export function FbTabsList(props: FbTabsListProps) {
  return <Tabs.List {...props}>{props.children}</Tabs.List>;
}

export function FbTabsPanel(props: FbTabsPanelProps) {
  return <Tabs.Panel {...props}>{props.children}</Tabs.Panel>;
}
