import { ReactNode } from 'react';
import { TbLeaf } from 'react-icons/tb';

interface DefaultErrorProps {
  message?: string;
  icon?: ReactNode;
  type?: 'warn' | 'info' | 'error';
  children?: ReactNode;
}

const classNames = {
  error: 'text-error',
  warn: 'text-supportive3-dark',
  info: 'text-gray-600',
};

export const Info = ({ type = 'info', ...props }: DefaultErrorProps) => {
  return (
    <div className={`flex flex-col justify-center items-center`}>
      <div className="text-gray-200">{props.icon || <TbLeaf size={70} />}</div>
      <h1 className={`text-h5 font-normal mt-2 ${classNames[type]}`}>
        {props.message || ''}
      </h1>
      <>{props?.children || null}</>
    </div>
  );
};
