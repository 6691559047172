import { DisclosureUiConfig } from '@fb-ui/shared-types';

export const ESRSS19UI_VER2: DisclosureUiConfig = {
  meta: {
    templateId: 'S1-9',
    framework: 'ESRS',
    version: '0.0.1',
  },
  data: {
    'esrs:DiversityMetricsTable##esrs:PercentageOfEmployeesAtTopManagementLevel':
      {
        formula: {
          operation: 'MULTIPLE_FORMULAS',
          options: {
            multipleSelectors: {
              hasMultipleSelectors: true,
            },
          },
          operands: [
            {
              operation: 'PERCENT',
              options: {
                multipleSelectors: {
                  hasMultipleSelectors: true,
                  domainMembers: [
                    {
                      dimensionId: 'esrs:ReportingScopeAxis',
                      domainMemberId: 'esrs:MilestonesAndTargetYearsMember',
                    },
                  ],
                },
              },
              operands: [
                {
                  tableId: 'esrs:DiversityMetricsTable',
                  measureId:
                    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
                  dimensionIds: [
                    {
                      dimensionId: 'esrs:ReportingScopeAxis',
                      domainMemberIds: ['esrs:MilestonesAndTargetYearsMember'],
                    },
                  ],
                },
                {
                  operation: 'SUM',
                  options: {
                    isArrayOperation: true,
                  },
                  operands: [
                    {
                      tableId: 'esrs:DiversityMetricsTable',
                      measureId:
                        'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
                      dimensionIds: [
                        {
                          dimensionId: 'esrs:ReportingScopeAxis',
                          domainMemberIds: [
                            'esrs:MilestonesAndTargetYearsMember',
                          ],
                        },
                      ],
                    },
                    {
                      tableId: 'esrs:DiversityMetricsTable',
                      measureId:
                        'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod',
                      dimensionIds: [
                        {
                          dimensionId: 'esrs:ReportingScopeAxis',
                          domainMemberIds: [
                            'esrs:MilestonesAndTargetYearsMember',
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              operation: 'PERCENT',
              options: {
                multipleSelectors: {
                  hasMultipleSelectors: true,
                  domainMembers: [
                    {
                      dimensionId: 'esrs:ReportingScopeAxis',
                      domainMemberId: 'esrs:RestatedCorrectedOrRevisedMember',
                    },
                  ],
                },
              },
              operands: [
                {
                  tableId: 'esrs:DiversityMetricsTable',
                  measureId:
                    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
                  dimensionIds: [
                    {
                      dimensionId: 'esrs:ReportingScopeAxis',
                      domainMemberIds: [
                        'esrs:RestatedCorrectedOrRevisedMember',
                      ],
                    },
                  ],
                },
                {
                  operation: 'SUM',
                  options: {
                    isArrayOperation: true,
                  },
                  operands: [
                    {
                      tableId: 'esrs:DiversityMetricsTable',
                      measureId:
                        'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
                      dimensionIds: [
                        {
                          dimensionId: 'esrs:ReportingScopeAxis',
                          domainMemberIds: [
                            'esrs:RestatedCorrectedOrRevisedMember',
                          ],
                        },
                      ],
                    },
                    {
                      tableId: 'esrs:DiversityMetricsTable',
                      measureId:
                        'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod',
                      dimensionIds: [
                        {
                          dimensionId: 'esrs:ReportingScopeAxis',
                          domainMemberIds: [
                            'esrs:RestatedCorrectedOrRevisedMember',
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    'esrs:DiversityMetricsTable##esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId: 'esrs:DiversityMetricsTable',
              measureId:
                'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
            },
          ],
        },
      },
    'esrs:DiversityMetricsTable##esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod$$aggragate:TotalTableEmployees':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
        formula: {
          operation: 'SUM',
          options: {
            isArrayOperation: true,
          },
          operands: [
            {
              tableId: 'esrs:DiversityMetricsTable',
              measureId:
                'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod',
            },
          ],
        },
      },
    'esrs:DiversityMetricsDetailedTable##esrs:NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:DiversityMetricsDetailedTable##esrs:NumberOfEmployeesHeadCountUnder30YearsOldAtEndOfPeriod':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:DiversityMetricsDetailedTable##esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldDuringPeriod':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:DiversityMetricsDetailedTable##esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldAtEndOfPeriod':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:DiversityMetricsDetailedTable##esrs:NumberOfEmployeesHeadCountOver50YearsOldDuringPeriod':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:DiversityMetricsDetailedTable##esrs:NumberOfEmployeesHeadCountOver50YearsOldAtEndOfPeriod':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:DiversityMetricsTable##esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:DiversityMetricsTable##esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:DiversityMetricsDetailedTable##esrs:PercentageOfEmployeesUnder30YearsOld$$esrs:ReportingScopeAxis;esrs:MilestonesAndTargetYearsMember':
      {
        formula: {
          operation: 'PERCENT',
          operands: [
            {
              operation: 'SUM',
              operands: [
                {
                  cellId: {
                    tableId: 'esrs:DiversityMetricsDetailedTable',
                    measureId:
                      'esrs:NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod',
                    dimensionIds: [
                      {
                        dimensionId: 'esrs:ReportingScopeAxis',
                        domainMemberIds: [
                          'esrs:MilestonesAndTargetYearsMember',
                        ],
                      },
                    ],
                  },
                },
                {
                  cellId: {
                    tableId: 'esrs:DiversityMetricsDetailedTable',
                    measureId:
                      'esrs:NumberOfEmployeesHeadCountUnder30YearsOldAtEndOfPeriod',
                    dimensionIds: [
                      {
                        dimensionId: 'esrs:ReportingScopeAxis',
                        domainMemberIds: [
                          'esrs:MilestonesAndTargetYearsMember',
                        ],
                      },
                    ],
                  },
                },
              ],
            },
            {
              operation: 'SUM',
              options: {
                isArrayOperation: true,
              },
              operands: [
                {
                  cellId: {
                    tableId: 'esrs:DiversityMetricsDetailedTable',
                    measureId:
                      'esrs:NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod',
                    dimensionIds: [
                      {
                        dimensionId: 'esrs:ReportingScopeAxis',
                        domainMemberIds: [
                          'esrs:MilestonesAndTargetYearsMember',
                        ],
                      },
                    ],
                  },
                },
                {
                  cellId: {
                    tableId: 'esrs:DiversityMetricsDetailedTable',
                    measureId:
                      'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldDuringPeriod',
                    dimensionIds: [
                      {
                        dimensionId: 'esrs:ReportingScopeAxis',
                        domainMemberIds: [
                          'esrs:MilestonesAndTargetYearsMember',
                        ],
                      },
                    ],
                  },
                },
                {
                  cellId: {
                    tableId: 'esrs:DiversityMetricsDetailedTable',
                    measureId:
                      'esrs:NumberOfEmployeesHeadCountOver50YearsOldDuringPeriod',
                    dimensionIds: [
                      {
                        dimensionId: 'esrs:ReportingScopeAxis',
                        domainMemberIds: [
                          'esrs:MilestonesAndTargetYearsMember',
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      },
    'esrs:DiversityMetricsDetailedTable##esrs:PercentageOfEmployeesUnder30YearsOld$$esrs:ReportingScopeAxis;esrs:RestatedCorrectedOrRevisedMember':
      {
        formula: {
          operation: 'PERCENT',
          operands: [
            {
              operation: 'SUM',
              operands: [
                {
                  cellId: {
                    tableId: 'esrs:DiversityMetricsDetailedTable',
                    measureId:
                      'esrs:NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod',
                    dimensionIds: [
                      {
                        dimensionId: 'esrs:ReportingScopeAxis',
                        domainMemberIds: [
                          'esrs:RestatedCorrectedOrRevisedMember',
                        ],
                      },
                    ],
                  },
                },
                {
                  cellId: {
                    tableId: 'esrs:DiversityMetricsDetailedTable',
                    measureId:
                      'esrs:NumberOfEmployeesHeadCountUnder30YearsOldAtEndOfPeriod',
                    dimensionIds: [
                      {
                        dimensionId: 'esrs:ReportingScopeAxis',
                        domainMemberIds: [
                          'esrs:RestatedCorrectedOrRevisedMember',
                        ],
                      },
                    ],
                  },
                },
              ],
            },
            {
              operation: 'SUM',
              options: {
                isArrayOperation: true,
              },
              operands: [
                {
                  tableId: 'esrs:DiversityMetricsDetailedTable',
                  measureId:
                    'esrs:NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod',
                },
                {
                  tableId: 'esrs:DiversityMetricsDetailedTable',
                  measureId:
                    'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldDuringPeriod',
                },
                {
                  tableId: 'esrs:DiversityMetricsDetailedTable',
                  measureId:
                    'esrs:NumberOfEmployeesHeadCountOver50YearsOldDuringPeriod',
                },
              ],
            },
          ],
        },
      },
  },
};
