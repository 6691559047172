import { Framework, MasterDisclosureTemplate } from '@fb-ui/shared-types';

export const ESRSS19_VER2: MasterDisclosureTemplate = {
  meta: {
    id: 'S1-9',
    docType: 'tax-disclosure',
    fileName: 'ESRS_S1-9.tax-disclosure.json',
    framework: Framework.ESRS,
    taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20230731esrsallxsd',
    taxonomyURI:
      'https://xbrl.efrag.org/taxonomy/draft-esrs/2023-07-31/esrs_all.xsd',
    taxonomyPublicationDate: '2024-02-06',
    disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401090',
    disclosureName: '[401090] S1-9 Diversity metrics',
    disclosureSortId: 401090,
    namespaces: [
      {
        prefix: 'esrs',
        namespaceURI: 'https://xbrl.efrag.org/taxonomy/draft-esrs/2023-07-31',
      },
    ],
    dictionaries: [],
    labels: [
      {
        id: 'S1-9_labels',
        docType: 'tax-disclosure-labels',
        fileName: 'ESRS_S1-9_labels.tax-disclosure-labels.json',
        framework: Framework.ESRS,
        taxonomyId: 'httpsxbrlefragorgtaxonomydraftesrs20230731esrsallxsd',
        taxonomyURI:
          'https://xbrl.efrag.org/taxonomy/draft-esrs/2023-07-31/esrs_all.xsd',
        disclosureURI: 'https://xbrl.efrag.org/taxonomy/role-401090',
        taxonomyPublicationDate: '2024-02-06',
        libVersion: '0.2.0',
      },
    ],
    libVersion: '0.2.0',
    creationTimestamp: '2023-11-13T12:57:45+00:00',
  },
  children: [
    {
      id: 'esrs:DiversityMetricsGeneralAbstract',
      label: 'Diversity metrics - general',
      elementType: 'measure',
      dataType: null,
      isAbstract: true,
      children: [
        {
          id: 'esrs:DisclosureOfGenderDistributionAtTopManagementAndAgeDistributionAmongstEmployeesExplanatory',
          label:
            'Disclosure of gender distribution at top management and age distribution amongst employees',
          elementType: 'measure',
          dataType: 'textBlock',
          isAbstract: false,
          isNillable: true,
          periodType: 'duration',
          children: [
            {
              id: 'esrs:DiversityMetricsDetailedTable',
              label: 'Diversity metrics - detailed',
              elementType: 'table',
              dataType: null,
              dimensions: [
                {
                  id: 'esrs:ReportingScopeAxis',
                  label: 'Reporting scope',
                  elementType: 'dimensionExplicit',
                  dataType: null,
                  children: [
                    {
                      id: 'esrs:CurrentAndRetrospectiveMember',
                      label: 'Current and retrospective',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                      children: [
                        {
                          id: 'esrs:MilestonesAndTargetYearsMember',
                          label: 'Milestones and target years',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:RestatedCorrectedOrRevisedMember',
                          label: 'Restated, corrected or revised',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                      ],
                    },
                  ],
                },
              ],
              measures: [
                {
                  id: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod',
                  label:
                    'Number of employees (head count) under 30 years old, during period',
                  elementType: 'measure',
                  dataType: 'integer',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:NumberOfEmployeesHeadCountUnder30YearsOldAtEndOfPeriod',
                  label:
                    'Number of employees (head count) under 30 years old, at end of period',
                  elementType: 'measure',
                  dataType: 'integer',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'instant',
                },
                {
                  id: 'esrs:PercentageOfEmployeesUnder30YearsOld',
                  label: 'Percentage of employees under 30 years old',
                  elementType: 'measure',
                  dataType: 'percent',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldDuringPeriod',
                  label:
                    'Number of employees (head count) between 30 and 50 years old, during period',
                  elementType: 'measure',
                  dataType: 'integer',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldAtEndOfPeriod',
                  label:
                    'Number of employees (head count) between 30 and 50 years old, at end of period',
                  elementType: 'measure',
                  dataType: 'integer',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'instant',
                },
                {
                  id: 'esrs:PercentageOfEmployeesBetween30And50YearsOld',
                  label: 'Percentage of employees between 30 and 50 years old',
                  elementType: 'measure',
                  dataType: 'percent',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:NumberOfEmployeesHeadCountOver50YearsOldDuringPeriod',
                  label:
                    'Number of employees (head count) over 50 years old, during period',
                  elementType: 'measure',
                  dataType: 'integer',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:NumberOfEmployeesHeadCountOver50YearsOldAtEndOfPeriod',
                  label:
                    'Number of employees (head count) over 50 years old, at end of period',
                  elementType: 'measure',
                  dataType: 'integer',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'instant',
                },
                {
                  id: 'esrs:PercentageOfEmployeesOver50YearsOld',
                  label: 'Percentage of employees over 50 years old',
                  elementType: 'measure',
                  dataType: 'percent',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:DisclosureOfOwnDefinitionOfTopManagementUsedExplanatory',
                  label: 'Disclosure of own definition of top management used',
                  elementType: 'measure',
                  dataType: 'textBlock',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
              ],
            },
            {
              id: 'esrs:DiversityMetricsTable',
              label: 'Diversity metrics',
              elementType: 'table',
              dataType: null,
              dimensions: [
                {
                  id: 'esrs:ReportingScopeAxis',
                  label: 'Reporting scope',
                  elementType: 'dimensionExplicit',
                  dataType: null,
                  children: [
                    {
                      id: 'esrs:CurrentAndRetrospectiveMember',
                      label: 'Current and retrospective',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                      children: [
                        {
                          id: 'esrs:MilestonesAndTargetYearsMember',
                          label: 'Milestones and target years',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:RestatedCorrectedOrRevisedMember',
                          label: 'Restated, corrected or revised',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 'esrs:GenderAxis',
                  label: 'Gender',
                  elementType: 'dimensionExplicit',
                  dataType: null,
                  children: [
                    {
                      id: 'esrs:GenderMember',
                      label: 'Total / all genders / NA',
                      labelRole: 'totalLabel',
                      elementType: 'domainMember',
                      dataType: null,
                      isAbstract: false,
                      children: [
                        {
                          id: 'esrs:FemaleMember',
                          label: 'Female',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:MaleMember',
                          label: 'Male',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:OtherThanFemaleAndMaleMember',
                          label: 'Other than female and male',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                        {
                          id: 'esrs:GenderNotReportedMember',
                          label: 'Gender not reported',
                          elementType: 'domainMember',
                          dataType: null,
                          isAbstract: false,
                        },
                      ],
                    },
                  ],
                },
              ],
              measures: [
                {
                  id: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
                  label:
                    'Number of employees (head count) at top management level, during period',
                  elementType: 'measure',
                  dataType: 'integer',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
                {
                  id: 'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod',
                  label:
                    'Number of employees (head count) at top management level, at end of period',
                  elementType: 'measure',
                  dataType: 'integer',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'instant',
                },
                {
                  id: 'esrs:PercentageOfEmployeesAtTopManagementLevel',
                  label: 'Percentage of employees at top management level',
                  elementType: 'measure',
                  dataType: 'percent',
                  isAbstract: false,
                  isNillable: true,
                  periodType: 'duration',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
