import { useTranslation } from '@esg/i18n';
import { Avatar } from '@esg/shared/components/Avatar/Avatar';
import Button from '@esg/shared/components/Button/Button';
import dateDifferenceInDays from '@esg/shared/helpers/dateDiffrenceInDays';

interface ActivityItemProps {
  activity: {
    id: string;
    image: string;
    initials: string;
    description: string;
    showPreview: boolean;
    date: Date;
  };
  actionText: string;
}

const ActivityItem = ({ activity, actionText }: ActivityItemProps) => {
  const { t } = useTranslation('aside');
  const today = new Date();
  const difference = dateDifferenceInDays(activity.date, today);
  const hour = activity.date.getHours();
  const minutes = activity.date.getMinutes();
  const addZeroBefore = (num: number): string =>
    `${num < 10 ? num.toString().padStart(2, '0') : num}`;
  const timeString = `${addZeroBefore(hour)}:${addZeroBefore(minutes)}`;

  let periodString = '';

  if (difference < 1) {
    periodString = t('periods.today');
  } else if (difference === 1) {
    periodString = t('periods.yesterday');
  } else {
    periodString = t('periods.daysAgo', { days: difference });
  }

  return (
    <div className="py-5">
      <div className="flex items-center gap-2.5">
        <Avatar
          src={activity.image}
          initials={activity.initials}
          color="supportive-3"
        />

        <div className="flex flex-col text-secondary">
          <span className="text-caption-small">{periodString}</span>
          <span className="text-caption-small">{timeString}</span>
        </div>
      </div>

      <p className="text-caption-small text-secondary mt-2">
        {activity.description}
      </p>

      {activity.showPreview && <Button>{actionText}</Button>}
    </div>
  );
};

export default ActivityItem;
