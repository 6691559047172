import {
  AnalyticsPermissions,
  AppPermission,
  ChatbotPermissions,
  CompanyProfilePermissions,
  DashboardPermissions,
  DataCollectionPermissions,
  EcosystemKYCPermissions,
  FreeSubscriptionTier,
  MaterialityAssessmentPermissions,
  NotificationPermissions,
  PaidSubscriptionTier,
  ProgramPermissions,
  ReportPermissions,
  StrategyPermissions,
  SubscriptionPlan,
  SupportedFramework,
  UserProfilePermissions,
} from '@fb-ui/shared-types';

export const COMMON_SUBSCRIPTIONS_PERMISSIONS = [
  CompanyProfilePermissions.READ_EXTERNAL_PARTNER_LIST,
  CompanyProfilePermissions.READ_SUBORGANIZATION_LIST,
  CompanyProfilePermissions.ADD_USERS_TO_COMPANY,
  CompanyProfilePermissions.READ_AUDIT_LOGS,
  CompanyProfilePermissions.ADD_USERS_TO_USER_GROUP,
  CompanyProfilePermissions.REMOVE_USERS_FROM_USER_GROUP,
  CompanyProfilePermissions.ADD_COMPANY_ADMIN_TO_USER_GROUP,
  CompanyProfilePermissions.ADD_DATA_PROVIDER_TO_USER_GROUP,
  CompanyProfilePermissions.EDIT_PROFILE,
  CompanyProfilePermissions.EDIT_DATA_SHARING_AGREEMENT,
  CompanyProfilePermissions.REVIEW_INVITATION,

  DataCollectionPermissions.ACCEPT_REJECT_REQUEST,
  DataCollectionPermissions.FILL_DATA,
  DataCollectionPermissions.SUBMIT_DATA,
  DataCollectionPermissions.READ_PACKAGE,
  DataCollectionPermissions.READ_COLL_STRUCT,
  DataCollectionPermissions.READ_DIS_DP,
  DataCollectionPermissions.READ_DIS_DO,
  DataCollectionPermissions.READ_DCL,
  UserProfilePermissions.CREATE,
  UserProfilePermissions.READ,
  UserProfilePermissions.EDIT_OWN,
  UserProfilePermissions.ACCEPT_UPDATE,
  UserProfilePermissions.DELETE,
  ChatbotPermissions.USE,
  DashboardPermissions.READ,
  NotificationPermissions.READ,
  SupportedFramework.ESRS,

  DashboardPermissions.WIDGET_CONFIGURE_OWN_COMPANY_PROFILE_INTRO,
  DashboardPermissions.WIDGET_OWN_TEAM_MEMBERS,
  DashboardPermissions.WIDGET_CERTIFICATION_INTRO,
  DashboardPermissions.WIDGET_UPCOMING_DEADLINES,
  DashboardPermissions.WIDGET_MY_TODO,
  DashboardPermissions.WIDGET_COMPANY_VISION,
  DashboardPermissions.WIDGET_STRATEGIES_COUNTER,
  DashboardPermissions.WIDGET_OWN_PROGRAMS_FINISHED_COUNTER,
  DashboardPermissions.WIDGET_OWN_PROGRAMS_ONGOING_COUNTER,
  DashboardPermissions.WIDGET_OWN_CONNECTED_ASSESSMENT,
  DashboardPermissions.WIDGET_DATA_COLLECTION,
];

export const SUBSCRIPTION_TO_PERMISSIONS_MAPPING: Record<
  SubscriptionPlan,
  AppPermission[]
> = {
  [PaidSubscriptionTier.CORPORATE]: [
    ...COMMON_SUBSCRIPTIONS_PERMISSIONS,
    CompanyProfilePermissions.ADD_SUPPLIERS,
    CompanyProfilePermissions.ADD_SUBORGANIZATIONS,
    CompanyProfilePermissions.ADD_SUSTAINABILITY_APPROVER_TO_USER_GROUP,
    CompanyProfilePermissions.ADD_PROGRAM_OWNER_TO_USER_GROUP,
    CompanyProfilePermissions.ADD_DATA_MANAGEMENT_COORDINATOR_TO_USER_GROUP,
    CompanyProfilePermissions.ADD_REPORT_OWNER_TO_USER_GROUP,
    CompanyProfilePermissions.ADD_DISCLOSURE_OWNER_TO_USER_GROUP,
    CompanyProfilePermissions.ADD_MATERIALITY_ASSESSMENT_OWNER_TO_USER_GROUP,
    CompanyProfilePermissions.ADD_STRATEGY_OWNER_TO_USER_GROUP,
    CompanyProfilePermissions.EDIT_KYC_INFORMATION,
    CompanyProfilePermissions.REVIEW_INVITATION,
    CompanyProfilePermissions.ADD_EXISTING_SUBORGANIZATIONS,
    CompanyProfilePermissions.ADD_EXISTING_SUPPLIERS,
    ProgramPermissions.CREATE,
    ProgramPermissions.READ,
    ProgramPermissions.UPDATE,
    ProgramPermissions.TERMINATE,
    DataCollectionPermissions.CREATE,
    DataCollectionPermissions.ASSIGN_DATA_PROVIDER,
    DataCollectionPermissions.SEND_REQUEST,
    DataCollectionPermissions.READ,
    DataCollectionPermissions.CANCEL_REQUEST,
    DataCollectionPermissions.SUBMIT_PREPARATION,
    DataCollectionPermissions.SUBMIT,
    DataCollectionPermissions.CREATE_DIS,
    DataCollectionPermissions.READ_DIS,
    DataCollectionPermissions.SEND_REQUEST_TO_DISCLOSURE_OWNER,
    DataCollectionPermissions.APPROVE_REJECT_DATA,
    DataCollectionPermissions.READ_ADJ_AGR_DATA,
    DataCollectionPermissions.SUBMIT_ADJUSTED_DATA,
    ReportPermissions.CREATE,
    ReportPermissions.READ,
    ReportPermissions.ADD_DISCLOSURES,
    ReportPermissions.SEND_REQUEST_TO_DISCLOSURE_OWNER,
    ReportPermissions.SUBMIT,
    ReportPermissions.EDIT_IXBRL,
    ReportPermissions.REPORT_CONSUMPTION,
    MaterialityAssessmentPermissions.CREATE,
    MaterialityAssessmentPermissions.FILL_ASSESSMENT,
    MaterialityAssessmentPermissions.READ,
    MaterialityAssessmentPermissions.FINALIZE,
    MaterialityAssessmentPermissions.READ_AO,
    StrategyPermissions.CREATE_VISION,
    StrategyPermissions.READ_VISION,
    StrategyPermissions.CREATE,
    StrategyPermissions.READ,
    StrategyPermissions.PUBLISH,
    StrategyPermissions.ARCHIVE,
    StrategyPermissions.LINK_DISCLOSURES,
    AnalyticsPermissions.READ_USER_ANALYTICS,
    AnalyticsPermissions.READ_COMPANY_ANALYTICS,
    EcosystemKYCPermissions.ADD_TO_ORGANIZATION,
    EcosystemKYCPermissions.ADD_TO_VALUE_CHAIN,
    DashboardPermissions.WIDGET_DATA_COLLECTION,

    DashboardPermissions.WIDGET_SUB_ORGANIZATION_COUNTER,
    DashboardPermissions.WIDGET_OWN_CONNECTED_COMPANY,
    DashboardPermissions.WIDGET_OWN_CONNECTED_ASSESSMENT,
    DashboardPermissions.WIDGET_OWN_PROGRAMS_FINISHED_COUNTER,
    DashboardPermissions.WIDGET_OWN_PROGRAMS_ONGOING_COUNTER,
    DashboardPermissions.WIDGET_COMPANY_VISION,
    DashboardPermissions.WIDGET_STRATEGIES_COUNTER,
    // Other permissions here
  ],
  [FreeSubscriptionTier.DATA_PROVIDER]: [...COMMON_SUBSCRIPTIONS_PERMISSIONS],
  // Other subscription plans here
};

export const EXPIRED_SUBSCRIPTION_TO_PERMISSIONS_MAPPING: Record<
  SubscriptionPlan,
  AppPermission[]
> = {
  [PaidSubscriptionTier.CORPORATE]: [
    ...COMMON_SUBSCRIPTIONS_PERMISSIONS,
    ProgramPermissions.READ,
    DataCollectionPermissions.READ,
    DataCollectionPermissions.READ_DIS,
    DataCollectionPermissions.READ_ADJ_AGR_DATA,
    ReportPermissions.READ,
    MaterialityAssessmentPermissions.READ,
    MaterialityAssessmentPermissions.READ_AO,
    StrategyPermissions.READ,
    StrategyPermissions.READ_VISION,

    DashboardPermissions.WIDGET_SUB_ORGANIZATION_COUNTER,
    DashboardPermissions.WIDGET_OWN_CONNECTED_COMPANY,
  ],
  [FreeSubscriptionTier.DATA_PROVIDER]: [...COMMON_SUBSCRIPTIONS_PERMISSIONS],
  // Other subscription plans here
};
