import {
  Framework,
  MaterialityTopicsToDisclosureMap,
} from '@fb-ui/shared-types';

export const MATERIALITY_TOPICS_TO_DISCLOSURES_MAP: MaterialityTopicsToDisclosureMap =
  {
    [Framework.ESRS]: {
      'env.climate-change': [
        { id: 'E1-1' },
        { id: 'E1-2' },
        { id: 'E1-3' },
        { id: 'E1-4' },
        { id: 'E1-5' },
        { id: 'E1-6' },
        { id: 'E1-7' },
        { id: 'E1-8' },
        { id: 'E1-9' },
        { id: 'E1.GOV-3' },
        { id: 'E1.IRO-1' },
        { id: 'E1.SBM-3' },
      ],
      'env.pollution': [
        { id: 'E2-1' },
        { id: 'E2-2' },
        { id: 'E2-3' },
        { id: 'E2-4' },
        { id: 'E2-5' },
        { id: 'E2-6' },
        { id: 'E2.IRO-1' },
      ],
      'env.water-marine-resources': [
        { id: 'E3-1' },
        { id: 'E3-2' },
        { id: 'E3-3' },
        { id: 'E3-4' },
        { id: 'E3-5' },
        { id: 'E3.IRO-1' },
      ],
      'env.biodiversity-ecosystems': [
        { id: 'E4-1' },
        { id: 'E4-2' },
        { id: 'E4-3' },
        { id: 'E4-4' },
        { id: 'E4-5' },
        { id: 'E4-6' },
        { id: 'E4.IRO-1' },
        { id: 'E4.SBM-3' },
      ],
      'env.circular-economy': [
        { id: 'E5-1' },
        { id: 'E5-2' },
        { id: 'E5-3' },
        { id: 'E5-4' },
        { id: 'E5-5' },
        { id: 'E5-6' },
        { id: 'E5.IRO-1' },
      ],
      'social.own-workforce': [
        { id: 'S1-1' },
        { id: 'S1-2' },
        { id: 'S1-3' },
        { id: 'S1-4' },
        { id: 'S1-5' },
        { id: 'S1-6' },
        { id: 'S1-7' },
        { id: 'S1-8' },
        { id: 'S1-9' },
        { id: 'S1-10' },
        { id: 'S1-11' },
        { id: 'S1-12' },
        { id: 'S1-13' },
        { id: 'S1-14' },
        { id: 'S1-15' },
        { id: 'S1-16' },
        { id: 'S1-17' },
        { id: 'S1.SBM-3' },
      ],
      'social.workers-value-chain': [
        { id: 'S2-1' },
        { id: 'S2-2' },
        { id: 'S2-3' },
        { id: 'S2-4' },
        { id: 'S2-5' },
        { id: 'S2.SBM-3' },
      ],
      'social.affected-communities': [
        { id: 'S3-1' },
        { id: 'S3-2' },
        { id: 'S3-3' },
        { id: 'S3-4' },
        { id: 'S3-5' },
        { id: 'S3.SBM-3' },
      ],
      'social.consumers-end-users': [
        { id: 'S4-1' },
        { id: 'S4-2' },
        { id: 'S4-3' },
        { id: 'S4-4' },
        { id: 'S4-5' },
        { id: 'S4.SBM-3' },
      ],
      'governance.business-conduct': [
        { id: 'G1-1' },
        { id: 'G1-2' },
        { id: 'G1-3' },
        { id: 'G1-4' },
        { id: 'G1-5' },
        { id: 'G1-6' },
        { id: 'G1.GOV-1' },
      ],
      'general.business-process': [{ id: 'ESRS2.BP-1' }, { id: 'ESRS2.BP-2' }],
      'general.governance': [
        { id: 'ESRS2.GOV-1' },
        { id: 'ESRS2.GOV-2' },
        { id: 'ESRS2.GOV-3' },
        { id: 'ESRS2.GOV-4' },
        { id: 'ESRS2.GOV-5' },
      ],
      'general.strategy-and-business-model': [
        { id: 'ESRS2.SBM-1' },
        { id: 'ESRS2.SBM-2' },
        { id: 'ESRS2.SBM-3' },
      ],
      'general.impact-risks-and-opportunities': [
        { id: 'ESRS2.IRO-1' },
        { id: 'ESRS2.IRO-2' },
      ],
      'general.disclosures': [
        { id: 'ESRS2.DC-A.1' },
        { id: 'ESRS2.DC-A.2' },
        { id: 'ESRS2.DC-M.1' },
        { id: 'ESRS2.DC-P.1' },
        { id: 'ESRS2.DC-T.1' },
        { id: 'ESRS2.DC-T.2' },
      ],
    },
    [Framework.GRI]: {
      'env.climate-change.energy': [
        { id: 'S1-2' },
        { id: 'S1-3' },
        { id: 'S1-4' },
      ],
      'governance.business-conduct.whistle-blowers': [{ id: 'S1-1' }],
    },
  };
