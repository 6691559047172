import { DatesProvider, DatesProviderProps } from '@mantine/dates';
import 'dayjs/locale/pl';
import 'dayjs/locale/en';

export function FbsHocDatesProvider({
  children,
  settings,
}: DatesProviderProps) {
  return <DatesProvider settings={settings}>{children}</DatesProvider>;
}
