import { SVGProps } from 'react';

function StrategyIcon({
  width = 20,
  height = 20,
  viewBox = '-2 0 20 20',
  fill = '#595470',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" {...props}>
      <path
        d="M16.222 3.19318V4.94318H13.6845V7.56818H11.9929V4.94318H9.45535V3.19318H11.9929V0.568176H13.6845V3.19318H16.222ZM13.6845 15.4432H1.84285V3.19318H6.91785V1.44318H1.84285C0.912434 1.44318 0.151184 2.23068 0.151184 3.19318V15.4432C0.151184 16.4057 0.912434 17.1932 1.84285 17.1932H13.6845C14.6149 17.1932 15.3762 16.4057 15.3762 15.4432V10.1932H13.6845V15.4432ZM10.3012 10.1932V13.6932H11.9929V10.1932H10.3012ZM6.91785 13.6932H8.60952V6.69318H6.91785V13.6932ZM5.22618 13.6932V8.44318H3.53452V13.6932H5.22618Z"
        fill={fill}
      />
    </svg>
  );
}

export default StrategyIcon;
