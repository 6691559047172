/* eslint-disable no-unused-vars */

export enum UserRole {
  KYC_USER = 'KYC_USER',
  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  AUDITOR_ADMIN = 'AUDITOR_ADMIN',
  AUDITOR_USER = 'AUDITOR_USER',
  COMPANY_PROGRAM_OWNER = 'COMPANY_PROGRAM_OWNER',
  COMPANY_DATA_MANAGEMENT_COORDINATOR = 'COMPANY_DATA_MANAGEMENT_COORDINATOR',
  COMPANY_REPORT_OWNER = 'COMPANY_REPORT_OWNER',
  COMPANY_DISCLOSURE_OWNER = 'COMPANY_DISCLOSURE_OWNER',
  COMPANY_MATERIALITY_ASSESSMENT_OWNER = 'COMPANY_MATERIALITY_ASSESSMENT_OWNER',
  COMPANY_STRATEGY_OWNER = 'COMPANY_STRATEGY_OWNER',
  COMPANY_DATA_PROVIDER = 'COMPANY_DATA_PROVIDER',
  COMPANY_SUSTAINABILITY_APPROVER = 'COMPANY_SUSTAINABILITY_APPROVER',
  COMPANY_USER = 'COMPANY_USER',
  ESG_DATA_CONSUMER = 'ESG_DATA_CONSUMER',
  PLATFORM_ADMIN_READ = 'PLATFORM_ADMIN_READ',
}

export enum CompanyUserStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  INVITED = 'INVITED',
  INVITE_CANCELLED = 'INVITE_CANCELLED',
}

/**
 *  Mapping Roles which are assigned to some Entity's on Platform
 *  Some Roles are needs to be tagged to entities in DB
 *  Removing those roles from User should be prevented , to maintain Platform Principles
 */
export const Role_Entity_Mapper = new Set([
  'COMPANY_DISCLOSURE_OWNER',
  'COMPANY_MATERIALITY_ASSESSMENT_OWNER',
  'COMPANY_STRATEGY_OWNER',
  'COMPANY_DATA_PROVIDER',
]);
