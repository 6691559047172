import { DisclosureUiConfig, Framework } from '@fb-ui/shared-types';

export const ESRSS11UI: DisclosureUiConfig = {
  meta: {
    templateId: 's1-6',
    framework: Framework.ESRS,
    version: '0.0.1',
  },
  data: {
    'esrs:GenderMember;aggragate:TotalTableEmployees': {
      validators: [
        { id: 'isInRange', value: { min: 0 } },
        { id: 'isNotEmpty', value: true },
      ],
    },
    'esrs:PercentageOfEmployeesAtTopManagementLevel': {},
    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },
    'esrs:PercentageOfEmployeesUnder30YearsOld': {},
    'esrs:PercentageOfEmployeesBetween30And50YearsOld': {},
    'esrs:NumberOfEmployeesHeadCountOver50YearsOld': {
      validators: [{ id: 'isInRange', value: { min: 0 } }],
    },
    'esrs:PercentageOfEmployeesOver50YearsOld': {},

    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel;esrs:GenderMember;esrs:MaleMember':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel;esrs:GenderMember;esrs:FemaleMember':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel;esrs:GenderMember;esrs:GenderNotDisclosedMember':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },

    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel;esrs:GenderMember;aggragate:TotalTableEmployees':
      {},
    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevel;esrs:GenderMember;esrs:OtherThanFemaleAndMaleMember':
      {
        validators: [{ id: 'isInRange', value: { min: 0 } }],
      },
    'esrs:PercentageOfEmployeesAtTopManagementLevel;esrs:GenderMember;aggragate:TotalTableEmployees':
      {
        //
      },
    'aggragate:TotalTableEmployees': {
      label: 'Total',
      desc: 'Custom description: sum of employees by gender',
    },

    'esrs:PercentageOfEmployeesBetween30And50YearsOld;esrs:PercentageOfEmployeesBetween30And50YearsOld':
      {},
    'esrs:PercentageOfEmployeesUnder30YearsOld;esrs:PercentageOfEmployeesUnder30YearsOld':
      {},

    'esrs:PercentageOfEmployeesAtTopManagementLevel;esrs:PercentageOfEmployeesAtTopManagementLevel':
      {},

    'esrs:PercentageOfEmployeesOver50YearsOld;esrs:PercentageOfEmployeesOver50YearsOld':
      {},
    'aggragete:TotalNumberOfEmployeesFlat;aggragete:TotalNumberOfEmployeesFlat':
      {
        desc: 'Custom description: sum of employees by gender',
      },
    'esrs:GenderOtherMember': {
      label: 'Other gender',
      desc: 'Custom provided other gender description',
    },
  },
};
