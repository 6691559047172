// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getNestedValue(obj: any, path: string): unknown {
  const keys = path.split('.');
  for (const key of keys) {
    if (obj == null || typeof obj !== 'object') {
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    obj = obj[key];
  }
  return obj;
}

export default getNestedValue;
