import { FbTabsPanel } from '@fb/ui-kit';
import { BsDot } from 'react-icons/bs';
import { LuFileCheck } from 'react-icons/lu';

import Button from '@esg/shared/components/Button/Button';

import { collectionData } from './CollectionTab.mock';

const CollectionTab = () => {
  return (
    <FbTabsPanel
      value="collection"
      pr="sm"
      className="pr-0 notification-tab-content"
      data-cy={`tab-content-2`}
    >
      {collectionData.map((elem, index) => {
        return (
          <div
            className={` notification-card flex items-start pt-6 hover:bg-selected rounded-md pr-6
             ${elem.status == 'read' ? 'pl-10' : ''}`}
            key={index}
          >
            {elem.status == 'unread' && (
              <BsDot className="w-12 h-12 flex-none text-accent" />
            )}
            {elem.icon && (
              <span className="notification-icon w-12 h-12 flex-none rounded-full font-semibold tracking-wider bg-supportive2-light flex items-center justify-center">
                {elem.icon}
              </span>
            )}
            <div className="notification-card-body grow pl-4 border-b border-gray-100 pb-6">
              {elem.notificationInfo && (
                <p className="text-linkRegular">{elem.notificationInfo}</p>
              )}
              {elem.date && (
                <span className="time text-gray-500 text-dateIndication mt-3 block">
                  {elem.date}
                </span>
              )}
              {elem.message && (
                <span className="bg-background-200 block p-4 rounded-md mt-4">
                  {elem.message}
                </span>
              )}
              {elem.fileName && (
                <div className="mt-4 flex">
                  <LuFileCheck className="text-gray-700 w-5 h-5" />
                  <p className="text-dateIndication font-bold pl-3">
                    {elem.fileName}
                    <span className="font-normal block mt-1">
                      {elem.fileSize}
                    </span>
                  </p>
                </div>
              )}
              {elem.buttonAccept && (
                <div className="notification-button mt-5">
                  {elem.buttonAccept && (
                    <Button variant="primary">{elem.buttonAccept}</Button>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </FbTabsPanel>
  );
};

export default CollectionTab;
