/* eslint-disable no-unused-vars */

export enum GlobalPermissions {
  underConstruction = 'global.underConstruction',
}

export enum BillingPermissions {
  /**
   * Permission to read billing and usage information
   */
  read = 'billing.read',
}

export enum CompanyProfilePermissions {
  /**
   * Can Add/Invite New Users to Company
   * Can Add Roles to those User's
   */
  ADD_USERS_TO_COMPANY = 'companyProfile.addUsersToCompany',

  /**
   * Can add suppliers to company
   */
  ADD_SUPPLIERS = 'companyProfile.addSuppliers',

  /**
   * Can existing suppliers to company
   */
  ADD_EXISTING_SUPPLIERS = 'companyProfile.addExistingSuppliers',

  /**
   * Can add suborganizations to company
   */
  ADD_SUBORGANIZATIONS = 'companyProfile.addSuborganizations',

  /**
   * Can existing suborganizations to company
   */
  ADD_EXISTING_SUBORGANIZATIONS = 'companyProfile.addExistingSuborganizations',

  /**
   * Adds the Sustainability Approver role to a user group
   */
  ADD_SUSTAINABILITY_APPROVER_TO_USER_GROUP = 'companyProfile.addSustainabilityApproverToUserGroup',

  /**
   * Adds the Program Owner role to a user group
   */
  ADD_PROGRAM_OWNER_TO_USER_GROUP = 'companyProfile.addProgramOwnerToUserGroup',

  /**
   * Adds the Data Management Coordinator role to a user group
   */
  ADD_DATA_MANAGEMENT_COORDINATOR_TO_USER_GROUP = 'companyProfile.addDataManagementCoordinatorToUserGroup',

  /**
   * Adds the Report Owner role to a user group
   */
  ADD_REPORT_OWNER_TO_USER_GROUP = 'companyProfile.addReportOwnerToUserGroup',

  /**
   * Adds the Disclosure Owner role to a user group
   */
  ADD_DISCLOSURE_OWNER_TO_USER_GROUP = 'companyProfile.addDisclosureOwnerToUserGroup',

  /**
   * Adds the Materiality Assessment Owner role to a user group
   */
  ADD_MATERIALITY_ASSESSMENT_OWNER_TO_USER_GROUP = 'companyProfile.addMaterialityAssessmentOwnerToUserGroup',

  /**
   * Adds the Strategy Owner role to a user group
   */
  ADD_STRATEGY_OWNER_TO_USER_GROUP = 'companyProfile.addStrategyOwnerToUserGroup',

  /**
   * Adds the Company Admin role to a user group
   */
  ADD_COMPANY_ADMIN_TO_USER_GROUP = 'companyProfile.addCompanyAdminToUserGroup',

  /**
   * Adds the Data Provider role to a user group
   */
  ADD_DATA_PROVIDER_TO_USER_GROUP = 'companyProfile.addDataProviderToUserGroup',

  /**
   * Can read audit logs
   */
  READ_AUDIT_LOGS = 'companyProfile.seeAuditLogs',

  /**
   * Can add users to user groups
   */
  ADD_USERS_TO_USER_GROUP = 'companyProfile.addUsersToUserGroup',

  /**
   * Can remove users from user groups
   */
  REMOVE_USERS_FROM_USER_GROUP = 'companyProfile.removeUsersFromUserGroup',

  /**
   * Can edit KYC (Know Your Customer) information
   */
  EDIT_KYC_INFORMATION = 'companyProfile.editKYCInformation',

  /**
   * Can edit company profile information, such as contact details
   */
  EDIT_PROFILE = 'companyProfile.editProfile',

  /**
   * Can edit or update data sharing agreements
   */
  EDIT_DATA_SHARING_AGREEMENT = 'companyProfile.addOrUpdateDataSharingAgreement',

  /**
   * Can accept reject connection invitation of Companies
   * Can See the Invitation List from Companies
   */
  REVIEW_INVITATION = 'companyProfile.reviewInvitation',

  /**
   * External Partner List
   */
  READ_EXTERNAL_PARTNER_LIST = 'companyProfile.readExternalPartnerList',

  /**
   * External Partner List
   */
  READ_SUBORGANIZATION_LIST = 'companyProfile.readSuborganizationList',
}

export enum ProgramPermissions {
  /**
   * Can create new programs
   */
  CREATE = 'program.create',
  /**
   * Can read program information
   */
  READ = 'program.read',
  /**
   * Can update existing programs
   */
  UPDATE = 'program.update',
  /**
   * Can terminate programs
   */
  TERMINATE = 'program.terminate',
}

export enum DataCollectionPermissions {
  /**
   * can create / edit new disclosures
   * BE Permission
   */
  CREATE_DIS = 'disclosure.create',
  /**
   * can read  disclosures
   * BE Permission
   */
  READ_DIS = 'disclosure.readDisclosure',
  /**
   * can read disclosures of data provider
   * BE Permission
   */
  READ_DIS_DP = 'disclosure.readDisclosureAsProvider',
  /**
   * can read disclosures of disclosures owner
   * BE Permission
   */
  READ_DIS_DO = 'disclosure.readDisclosureAsOwner',
  /**
   * Can create new data collection requests
   */
  CREATE = 'dataCollection.create',
  /**
   * Can send requests to the disclosure owner
   */
  SEND_REQUEST_TO_DISCLOSURE_OWNER = 'dataCollection.sendRequestToDisclosureOwner',
  /**
   * Can assign a data provider to a request
   */
  ASSIGN_DATA_PROVIDER = 'dataCollection.assignDataProvider',
  /**
   * Can send data collection requests
   */
  SEND_REQUEST = 'dataCollection.sendRequest',
  /**
   * Can read data collection information
   */
  READ = 'dataCollection.read',
  /**
   * Can read data collection information
   * BE Permission
   */
  READ_DCL = 'dataCollection.readDataCollection',
  /**
   * Can read data collection package
   * BE Permission
   */
  READ_PACKAGE = 'dataCollection.readPackage',
  /**
   * Can read data structure
   * BE Permission
   */
  READ_COLL_STRUCT = 'dataCollection.readCollectionStructure',
  /**
   * Can cancel data collection requests
   */
  CANCEL_REQUEST = 'dataCollection.cancelRequest',
  /**
   * Can submit preparation for data collection
   */
  SUBMIT_PREPARATION = 'dataCollection.submitPreparation',
  /**
   * Can submit data collection requests
   */
  SUBMIT = 'dataCollection.submit',
  /**
   * Can accept or reject data collection requests
   */
  ACCEPT_REJECT_REQUEST = 'dataCollection.acceptRejectRequest',
  /**
   * Can accept or reject data collection requests
   * BE Permission
   */
  APPROVE_REJECT_DATA = 'dataCollection.approveRejectData',
  /**
   * Can fill in data for data collection
   */
  FILL_DATA = 'dataCollection.fillInData',
  /**
   * Can submit data for a request
   */
  SUBMIT_DATA = 'dataCollection.submitData',
  /**
   * Can Submit Adjusted Data for Disclosure
   * Can Fetch Data for All Provider per slot
   */
  READ_ADJ_AGR_DATA = 'dataCollection.readAdjustedAggregateData',
  /**
   * Can Submit Adjusted Data for Disclosure
   */
  SUBMIT_ADJUSTED_DATA = 'dataCollection.submitAdjustedData',
}

export enum ReportPermissions {
  /**
   * Can create new reports
   */
  CREATE = 'report.create',
  /**
   * Can read report information
   */
  READ = 'report.read',
  /**
   * Can add disclosures to reports
   */
  ADD_DISCLOSURES = 'report.addDisclosures',
  /**
   * Can send request to disclosure owner
   * Can assign Disclosure Owner
   */
  SEND_REQUEST_TO_DISCLOSURE_OWNER = 'report.sendRequestToDisclosureOwner',
  /**
   * Can submit reports
   */
  SUBMIT = 'report.submit',
  /**
   * Can edit iXBRL (Inline eXtensible Business Reporting Language) in reports
   */
  EDIT_IXBRL = 'report.editIXBRL',
  /**
   * Can report on resource consumption
   */
  REPORT_CONSUMPTION = 'report.reportConsumption',
}

export enum MaterialityAssessmentPermissions {
  /**
   * Can create new materiality assessments
   */
  CREATE = 'materialityAssessment.create',
  /**
   * Can Fill in materiality assessments
   * Can Declare the materiality assessments
   */
  FILL_ASSESSMENT = 'materialityAssessment.fillAssessment',
  /**
   * Can read materiality assessment information
   */
  READ = 'materialityAssessment.read',
  /**
   * Can read materiality assessment information
   */
  READ_AO = 'materialityAssessment.readAssessmentAsOwner',
  /**
   * Can finalize materiality assessments
   */
  FINALIZE = 'materialityAssessment.finalize',
}

export enum StrategyPermissions {
  /**
   * Can create a new vision within a strategy
   */
  CREATE_VISION = 'strategy.createVision',
  /**
   * Read Vision
   */
  READ_VISION = 'strategy.readVision',
  /**
   * Can create new strategies , objectives and goals
   * Can Edit existing strategies , objectives and goals
   */
  CREATE = 'strategy.create',
  /**
   * Can read strategy , objective and goals details
   */
  READ = 'strategy.read',
  /**
   * Can publish the strategy
   */
  PUBLISH = 'strategy.publish',
  /**
   * Can archive the strategy
   */
  ARCHIVE = 'strategy.archive',
  /**
   * Can link disclosures to the strategy
   * Can link - unlink disclosures to the Goal
   */
  LINK_DISCLOSURES = 'strategy.linkDisclosures',
}

export enum AnalyticsPermissions {
  /**
   * Can read analytics for platform administrators
   */
  READ_ADMIN_ANALYTICS = 'analytics.readAdminAnalytics',
  /**
   * Can read analytics for regular users
   */
  READ_USER_ANALYTICS = 'analytics.readUserAnalytics',
  /**
   * Can read analytics for company administrators
   */
  READ_COMPANY_ANALYTICS = 'analytics.readCompanyAnalytics',
}

export enum UserProfilePermissions {
  /**
   * Can create user profiles
   */
  CREATE = 'userProfile.create',
  /**
   * Can read user profiles
   */
  READ = 'userProfile.read',
  /**
   * Can edit own user profile
   */
  EDIT_OWN = 'userProfile.editOwn',
  /**
   * Can accept updates to user profiles
   */
  ACCEPT_UPDATE = 'userProfile.acceptUpdate',
  /**
   * Can delete user profiles
   */
  DELETE = 'userProfile.delete',
}

export enum EcosystemKYCPermissions {
  /**
   * Can add organizations to the ecosystem KYC
   */
  ADD_TO_ORGANIZATION = 'ecosystemKYC.addToOrganization',
  /**
   * Can add entities to the value chain in ecosystem KYC
   */
  ADD_TO_VALUE_CHAIN = 'ecosystemKYC.addToValueChain',
}

export enum ChatbotPermissions {
  /**
   * Permission to use the chatbot feature
   */
  USE = 'chatbot.use',
}

export enum PlatformAdminPermissions {
  /**
   * Can read platform administration details
   */
  READ = 'platformAdmin.read',
  /**
   * Can approve requests for interest
   */
  APPROVE_REQUEST_FOR_INTEREST = 'platformAdmin.approveRequestForInterest',
  /**
   * Can approve KYC requests
   */
  APPROVE_KYC = 'platformAdmin.approveKYC',
  /**
   * Can block a company on the platform
   */
  BLOCK_COMPANY = 'platformAdmin.blockCompany',
  /**
   * Can read platform audit logs
   */
  READ_AUDIT_LOG = 'platformAdmin.readAuditLog',
}

export enum DashboardPermissions {
  /**
   * Can read information on the main dashboard
   */
  READ = 'mainDashboard.read',

  /**
   * Permission to configure the profile of the user's own company on the dashboard
   */
  WIDGET_CONFIGURE_OWN_COMPANY_PROFILE_INTRO = 'mainDashboard.widgetConfigureOwnCompanyProfileIntro',

  /**
   * Permission for the widget showing connected companies of the user's own company
   */
  WIDGET_OWN_CONNECTED_COMPANY = 'mainDashboard.widgetOwnConnectedCompany',

  /**
   * Permission for the widget related to materiality assessment connections of the user's company
   */
  WIDGET_OWN_CONNECTED_ASSESSMENT = 'mainDashboard.widgetOwnMaterialityAssessment',

  /**
   * Permission for the widget displaying team members of the user's company
   */
  WIDGET_OWN_TEAM_MEMBERS = 'mainDashboard.widgetOwnTeamMembers',

  /**
   * Permission for the widget showing the count of ongoing programs for the user's company
   */
  WIDGET_OWN_PROGRAMS_ONGOING_COUNTER = 'mainDashboard.widgetOwnProgramsOngoingCounter',

  /**
   * Permission for the widget displaying the count of finished programs for the user's company
   */
  WIDGET_OWN_PROGRAMS_FINISHED_COUNTER = 'mainDashboard.widgetOwnProgramsFinishedCounter',

  /**
   * Permission for the widget to company vision
   */
  WIDGET_COMPANY_VISION = 'mainDashboard.widgetCompanyVision',

  /**
   * Permission for the widget to strategies counter
   */
  WIDGET_STRATEGIES_COUNTER = 'mainDashboard.widgetStrategiesCounter',

  /**
   * Permission for the widget to sub organization counter
   */
  WIDGET_SUB_ORGANIZATION_COUNTER = 'mainDashboard.widgetSubOrganizationCounter',

  /**
   * Permission for the widget to list assigned disclosures
   */
  WIDGET_ASSIGNED_DISCLOSURES = 'mainDashboard.widgetAssignDisclosure',

  /**
   * Permission for the introductory widget about certifications
   */
  WIDGET_CERTIFICATION_INTRO = 'mainDashboard.widgetCertificationIntro',

  /**
   * Permission for the upcoming deadlines widget
   */
  WIDGET_UPCOMING_DEADLINES = 'mainDashboard.widgetUpcomingDeadlines',

  /**
   * Permission for the My Todo widget
   */
  WIDGET_MY_TODO = 'mainDashboard.widgetMyTodo',

  /**
   * Permission for the Data Collection widget
   */
  WIDGET_DATA_COLLECTION = 'mainDashboard.widgetDataCollection',
}

export enum NotificationPermissions {
  /**
   * Can read user notifications
   */
  READ = 'notifications.read',
}

export enum SupportedFramework {
  /**
   * Can use GRI Framework
   */
  GRI = 'supportedFramework.GRI',
  /**
   * Can use ESRS Framework
   */
  ESRS = 'supportedFramework.ESRS',
}

export type AppPermission =
  | GlobalPermissions
  | BillingPermissions
  | CompanyProfilePermissions
  | ProgramPermissions
  | DataCollectionPermissions
  | ReportPermissions
  | MaterialityAssessmentPermissions
  | StrategyPermissions
  | AnalyticsPermissions
  | UserProfilePermissions
  | EcosystemKYCPermissions
  | ChatbotPermissions
  | PlatformAdminPermissions
  | DashboardPermissions
  | SupportedFramework
  | NotificationPermissions;
