import { SVGProps } from 'react';

function EcoHandsIcon({
  width = 30,
  height = 30,
  viewBox = '0 0 30 30',
  fill = '#5037B5',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" {...props}>
      <g clipPath="url(#clip0_5254_2550)">
        <path
          d="M7.88568 21.1198L9.79443 22.1068V24.3143C9.79443 24.5408 9.97198 24.7244 10.1909 24.7244H14.3937C14.6126 24.7244 14.7901 24.5408 14.7901 24.3143V18.9597C14.7901 17.7031 13.8789 16.661 12.6733 16.5359C12.6686 16.5346 10.9961 16.2645 10.2202 15.4618L9.32979 14.5411C9.03021 14.2311 8.63198 14.0604 8.2085 14.0604V10.7791C8.2085 9.87447 7.49704 9.13849 6.62256 9.13849C5.74807 9.13849 5.03662 9.87447 5.03662 10.7791V16.3507C5.03662 17.3415 5.30282 18.3109 5.8064 19.1538C6.30993 19.9967 7.02896 20.6765 7.88568 21.1198ZM5.82959 10.7791C5.82959 10.3268 6.18532 9.9588 6.62256 9.9588C7.0598 9.9588 7.41553 10.3268 7.41553 10.7791V14.2802C6.93198 14.5695 6.62256 15.1088 6.62256 15.701C6.62256 16.139 6.78758 16.551 7.0872 16.8609L9.1176 18.9617C9.27234 19.1219 9.52344 19.122 9.6783 18.9618C9.83313 18.8016 9.83317 18.5419 9.67834 18.3817L7.64795 16.2809C7.49808 16.1259 7.41553 15.9199 7.41553 15.701C7.41553 15.3538 7.62792 15.0429 7.94412 14.9274C7.94424 14.9274 7.94436 14.9273 7.94448 14.9272H7.94452C8.21793 14.827 8.5423 14.8865 8.76916 15.1212L9.65959 16.0419C10.5789 16.9929 12.5873 17.3507 12.5914 17.3519C13.3928 17.435 13.9972 18.1262 13.9972 18.9597V23.9041H10.5874V21.8533C10.5874 21.698 10.5025 21.5559 10.3682 21.4865L8.24033 20.386C6.75336 19.6168 5.82959 18.0705 5.82959 16.3507V10.7791Z"
          fill={fill}
        />
        <path
          d="M14.7901 14.4705C14.7901 14.697 14.9677 14.8807 15.1866 14.8807C15.4056 14.8807 15.5831 14.697 15.5831 14.4705V13.24H17.1008C19.3247 13.24 21.1339 11.3685 21.1339 9.06794V7.0877C21.1339 6.92183 21.0373 6.77225 20.8891 6.70876C20.741 6.64527 20.5704 6.68037 20.457 6.79768C20.0206 7.24922 19.4403 7.49786 18.8232 7.49786C17.4971 7.49786 16.3186 8.16342 15.5831 9.18848V5.44708C15.5831 5.22055 15.4056 5.03692 15.1866 5.03692C14.9677 5.03692 14.7901 5.22055 14.7901 5.44708V7.5479C14.0546 6.52283 12.8761 5.85723 11.5501 5.85723C10.9329 5.85723 10.3526 5.6086 9.91619 5.15705C9.80283 5.03975 9.63227 5.0046 9.4841 5.06813C9.33597 5.13167 9.23935 5.28121 9.23935 5.44708V7.42731C9.23935 9.72784 11.0485 11.5994 13.2724 11.5994H14.7901V14.4705ZM18.8232 8.31817C19.3634 8.31817 19.8826 8.17634 20.3409 7.9097V9.06794C20.3409 10.9161 18.8874 12.4197 17.1008 12.4197H16.3761L18.5964 10.6971C18.7715 10.5612 18.807 10.3041 18.6757 10.1229C18.5443 9.94165 18.2958 9.90486 18.1206 10.0408L15.5831 12.0096V11.67C15.5831 9.8218 17.0366 8.31817 18.8232 8.31817ZM10.0323 7.42731V6.26907C10.4907 6.53571 11.0099 6.67754 11.5501 6.67754C13.3367 6.67754 14.7901 8.18118 14.7901 10.0293V10.369L12.2526 8.4002C12.0775 8.26432 11.8289 8.30103 11.6976 8.48223C11.5662 8.66344 11.6017 8.92057 11.7768 9.05645L13.9972 10.7791H13.2724C11.4858 10.7791 10.0323 9.27552 10.0323 7.42731Z"
          fill={fill}
        />
        <path
          d="M23.2353 20.5408C23.4542 20.5408 23.6317 20.3572 23.6317 20.1307C23.6317 19.9042 23.4542 19.7205 23.2353 19.7205C23.0163 19.7205 22.8388 19.9042 22.8388 20.1307C22.8388 20.3572 23.0163 20.5408 23.2353 20.5408Z"
          fill={fill}
        />
        <path
          d="M23.7507 9.13849C22.8762 9.13849 22.1647 9.87447 22.1647 10.7791V14.0604C21.7413 14.0604 21.343 14.2311 21.0435 14.5409L20.1529 15.4618C19.3942 16.2468 17.7068 16.5345 17.7028 16.5357C16.4943 16.661 15.5831 17.7031 15.5831 18.9597V24.3143C15.5831 24.5408 15.7606 24.7244 15.9796 24.7244H20.1823C20.4013 24.7244 20.5788 24.5408 20.5788 24.3143V22.1068L21.8765 21.4358C22.0723 21.3344 22.1517 21.0881 22.0538 20.8854C21.956 20.6829 21.7179 20.6007 21.5219 20.702L20.005 21.4864C19.8707 21.556 19.7858 21.698 19.7858 21.8533V23.9041H16.3761V18.9597C16.3761 18.1262 16.9804 17.435 17.7847 17.3515C17.7887 17.3504 19.7444 17.0445 20.7136 16.0419L21.6042 15.121C21.8311 14.8863 22.1558 14.8271 22.4287 14.9272H22.4288C22.4289 14.9272 22.429 14.9273 22.4291 14.9274C22.7453 15.043 22.9577 15.3539 22.9577 15.701C22.9577 15.9199 22.8752 16.1259 22.7253 16.281L20.6949 18.3818C20.5401 18.542 20.5401 18.8016 20.695 18.9618C20.8498 19.1219 21.1009 19.122 21.2557 18.9618L23.2861 16.861C23.5857 16.551 23.7507 16.139 23.7507 15.701C23.7507 15.1088 23.4413 14.5695 22.9577 14.2802V10.7791C22.9577 10.3268 23.3134 9.9588 23.7507 9.9588C24.1879 9.9588 24.5436 10.3268 24.5436 10.7791V16.3507C24.5436 17.1011 24.3606 17.8461 24.0142 18.5051C23.9096 18.7042 23.9808 18.9532 24.1732 19.0614C24.3655 19.1696 24.6063 19.096 24.7109 18.897C25.1203 18.1181 25.3366 17.2376 25.3366 16.3507V10.7791C25.3366 9.87447 24.6252 9.13849 23.7507 9.13849Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5254_2550">
          <rect
            width="20.3"
            height="21"
            fill="white"
            transform="translate(5.03662 4.38068)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EcoHandsIcon;
