import { DisclosureUiConfig, Framework } from '@fb-ui/shared-types';

export const FAKEUI: DisclosureUiConfig = {
  meta: {
    templateId: 'FAKE',
    framework: Framework.ESRS,
    version: '0.0.1',
  },
  data: {
    'esrs:DiversityMetricsTable##esrs:PercentageOfEmployeesAtTopManagementLevel':
      {
        formula: {
          operation: 'MULTIPLE_FORMULAS',
          options: {
            multipleSelectors: {
              hasMultipleSelectors: true,
            },
          },
          operands: [
            {
              operation: 'PERCENT',
              options: {
                multipleSelectors: {
                  hasMultipleSelectors: true,
                  domainMembers: [
                    {
                      dimensionId: 'esrs:ReportingScopeAxis',
                      domainMemberId: 'esrs:MilestonesAndTargetYearsMember',
                    },
                  ],
                  excludedDomainMembers: [
                    {
                      dimensionId: 'esrs:ReportingScopeAxis',
                      domainMemberId: 'esrs:RestatedCorrectedOrRevisedMember',
                    },
                  ],
                },
              },
              operands: [
                {
                  tableId: 'esrs:DiversityMetricsTable',
                  measureId:
                    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
                  dimensionIds: [
                    {
                      dimensionId: 'esrs:ReportingScopeAxis',
                      domainMemberIds: ['esrs:MilestonesAndTargetYearsMember'],
                    },
                  ],
                },
                {
                  operation: 'SUM',
                  options: {
                    isArrayOperation: true,
                  },
                  operands: [
                    {
                      tableId: 'esrs:DiversityMetricsTable',
                      measureId:
                        'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
                      dimensionIds: [
                        {
                          dimensionId: 'esrs:ReportingScopeAxis',
                          domainMemberIds: [
                            'esrs:MilestonesAndTargetYearsMember',
                          ],
                        },
                      ],
                    },
                    {
                      tableId: 'esrs:DiversityMetricsTable',
                      measureId:
                        'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod',
                      dimensionIds: [
                        {
                          dimensionId: 'esrs:ReportingScopeAxis',
                          domainMemberIds: [
                            'esrs:MilestonesAndTargetYearsMember',
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              operation: 'PERCENT',
              options: {
                multipleSelectors: {
                  hasMultipleSelectors: true,
                  domainMembers: [
                    {
                      dimensionId: 'esrs:ReportingScopeAxis',
                      domainMemberId: 'esrs:RestatedCorrectedOrRevisedMember',
                    },
                  ],
                },
              },
              operands: [
                {
                  tableId: 'esrs:DiversityMetricsTable',
                  measureId:
                    'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
                  dimensionIds: [
                    {
                      dimensionId: 'esrs:ReportingScopeAxis',
                      domainMemberIds: [
                        'esrs:RestatedCorrectedOrRevisedMember',
                      ],
                    },
                  ],
                },
                {
                  operation: 'SUM',
                  options: {
                    isArrayOperation: true,
                  },
                  operands: [
                    {
                      tableId: 'esrs:DiversityMetricsTable',
                      measureId:
                        'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod',
                      dimensionIds: [
                        {
                          dimensionId: 'esrs:ReportingScopeAxis',
                          domainMemberIds: [
                            'esrs:RestatedCorrectedOrRevisedMember',
                          ],
                        },
                      ],
                    },
                    {
                      tableId: 'esrs:DiversityMetricsTable',
                      measureId:
                        'esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod',
                      dimensionIds: [
                        {
                          dimensionId: 'esrs:ReportingScopeAxis',
                          domainMemberIds: [
                            'esrs:RestatedCorrectedOrRevisedMember',
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
  },
};
